import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../redux";
import {
  closeCreateNewPasswordDialog,
} from "../redux/features/Dialog/CreateNewPasswordDialogSlice";
import KeyIcon from "./../assets/images/key.svg";
import { confirmPasswordReset } from "firebase/auth";
import { firebase_auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { showPasswordResetLinkExpireDialog } from "../redux/features/Dialog/ForgotPasswordDialogSlice";
import { showAlertCommonDialog } from "../redux/features/Dialog/AlertCommonDialogSlice";
import { isPasswordValid } from "../helper/PasswordValidator";
export const CreateNewPasswordDialog = ({ setLoginDialog }: any) => {
  const [Password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isCreateNewPasswordDialog, oobCode } = useAppSelector(
    (s) => s.createNewPassword
  );
  const onChangePassword = (e: any) => {
    const { value } = e.target;
    const newObj = { ...Password };
    if (e.target.id == "newpassword") {
      setPassword(() => {
        newObj.newPassword = value;
        return newObj;
      });
    } else if (e.target.id == "confirmpassword") {
      setPassword(() => {
        newObj.confirmPassword = value;
        return newObj;
      });
    }
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    if(isPasswordValid(Password.newPassword)){
      if (Password.newPassword == Password.confirmPassword) {
        try {
          await confirmPasswordReset(
            firebase_auth,
            oobCode,
            Password.newPassword
          );
          dispatch(closeCreateNewPasswordDialog());
          setLoginDialog(true);
          navigate("/");
        } catch (error: any) {
          if (error.code == "auth/invalid-action-code") {
            dispatch(closeCreateNewPasswordDialog());
            dispatch(showPasswordResetLinkExpireDialog());
          }
        }
      } else {
        dispatch(showAlertCommonDialog({title:"Password Change failed",paragraph:"New password not match with Confirm password. Please recheck your entered password",buttonText:"ok"}))
      }
    }
    else{
      dispatch(showAlertCommonDialog({title:"",paragraph:"password not match with regex pattern Password should contains numbers, letters (small and capital), and special characters",buttonText:"ok"}))
    }
   
  };
  const onClose = () => {};

  return (
    <Modal
      show={isCreateNewPasswordDialog}
      onHide={onClose}
      keyboard={false}
      id="loginModal"
    >
      <div className="modal-body">
        <div className="text-center">
          <h1 className="model-header-title">Create new password</h1>
          <p className="model-header-text">
            Insert a new password for your account.
          </p>
        </div>
        <form id="newpasswordsubmit" onSubmit={(e) => onSubmit(e)}>
          <div className="form">
            <label htmlFor="newpassword">
              <p>New password</p>
            </label>
            <input
              type="password"
              className="form-control mb-4"
              id="newpassword"
              value={Password.newPassword}
              minLength={6}
              onChange={onChangePassword}
              placeholder=""
              required
            />
            <label htmlFor="confirmpassword">
              <p>Confirm new password</p>
            </label>
            <input
              type="password"
              className="form-control mb-4"
              id="confirmpassword"
              value={Password.confirmPassword}
              minLength={6}
              onChange={onChangePassword}
              placeholder=""
              required
            />
            <button type="submit" className="submit-btn">
              <img
                src={KeyIcon}
                style={{ width: "30px", height: "30px" }}
                alt="login-img"
              />
              <p>SAVE</p>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
