import React, { useEffect, useRef, useState } from "react";

interface IProp {
  data: {
    service: "coub";
    source: string;
    embed: string;
    width: 580;
    height: 320;
    caption: string;
  };
}
export const CustomIFrame = ({ data }: IProp) => {
  return (
    <div className="pic-sec">
      <div className="image-video-card mx-auto">
        <iframe
          height={240}
          src={data.embed}
          title={`${data.service} video player`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
        <div className="card-body">
          <p className="mb-0">{data.caption}</p>
        </div>
      </div>
    </div>
  );
};

export const CustomAudio = ({ data }: any) => {
  // console.log("custom ellllll",customAudio.play)
  

  return (
    <div className="pic-sec w-100 custom-audio-container">
      <div className="image-card mx-auto">
        <div className="player p-0 ">
          <audio
            controls
            src={data.file.url}
            style={{
              width: "100%",
              borderRadius: "inherit",
              backgroundColor: "#f8f9fa",
            }}
          >
            <a href={data.file.url}>download it</a>
          </audio>
        </div>
        <div className="card-body">
          <p className="mb-0">{data.caption}</p>
        </div>
      </div>
    </div>
  );
};

export const CustomVideoPlayer = ({ data }: any) => {
  return (
    <div className="pic-sec">
      <div className="image-video-card mx-auto">
        <video
          controls
          src={data.file.url}
          // poster={data.file.url}
          width={620}
        >
          Sorry, your browser doesn't support embedded videos, but don't worry,
          you can <a href={data.file.url}>download it</a> and watch it with your
          favorite video player!{" "}
        </video>
        <div className="card-body">
          <p className="mb-0">{data.caption}</p>
        </div>
      </div>
    </div>
  );
};



interface IAudioProp {
  data?: {
    file: {
      url: string;
    };
  };
}
export const CustomAudioTest = (
  { data }: IAudioProp 
) => {
  if(!data){
    data={
      file:{
        url:"https://firebasestorage.googleapis.com/v0/b/experiences-dev-827d9.appspot.com/o/experience%2FVIDEO-1681801981.mp3?alt=media&token=66f8c3f4-28d6-4761-8990-92b8e4e221f9"
      }
    }
  }
  
  let customAudio = document.getElementById("custom-audio") as HTMLAudioElement;
  const [isPlay, setisPlay] = useState(false);
  const [isMuted, setisMuted] = useState(false);
  const [currentTime, setcurrentTime] = useState(0);
  let timerRef: any;
  const currentTimeInterval = () => {
    timerRef = setInterval(() => {
      setcurrentTime(customAudio?.currentTime);
      if (customAudio?.currentTime == customAudio?.duration) {
        stopcurrentTimeInterval();
      }
    }, 400);
  };
  const stopcurrentTimeInterval = () => clearInterval(timerRef);
  useEffect(() => {
    if (isPlay) {
      currentTimeInterval();
      customAudio?.play();
    }
    return () => {
      customAudio?.pause();
      stopcurrentTimeInterval();
    };
  }, [isPlay]);

  return (
    <div className="pic-sec">
      <div className="image-card mx-auto" style={{ width: "fit-content" }}>
        <audio id="custom-audio" src={data?.file.url}></audio>
        <div className="player">
          <div
            className="toggle-play play "
            onClick={() => {
              setisPlay(!isPlay);
            }}
          />
          <div className="time">
            <p className="current p-0">
              {currentTime
                ? new Date(currentTime * 1000).toISOString().substring(14, 19)
                : "00:00"}
            </p>
            <p className="divider p-0">/</p>
            <p className="length p-0">
              {customAudio?.duration
                ? new Date(customAudio.duration * 1000)
                    .toISOString()
                    .substring(14, 19)
                : "00:00"}
            </p>
          </div>

          <input
            className="my-auto audio-player-progress mx-2"
            min={0}
            max={customAudio?.duration}
            onChange={(e: any) => {
              customAudio.currentTime = e.target.value;
              setcurrentTime(e.target.value);
            }}
            value={currentTime}
            type="range"
          />

          <div
            className="volume-button volume"
            onClick={() => {
              customAudio.muted = !customAudio.muted;
              setisMuted(!isMuted);
            }}
          >
            <div
              className="audio-mute-line"
              style={{ display: isMuted ? "block" : "none" }}
            />
          </div>
        </div>
        <div className="card-body">
          <p className="mb-0">Please review it</p>
        </div>
      </div>
      {/* //////custom end //// */}
    </div>
  );
};
