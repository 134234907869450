import React, { useCallback, useMemo, useState } from "react";
import { ExperienceHeader } from "./ExperienceHeader";

import { BackgroundSection } from "./sections/BackgroundSection";
import { GoalSection } from "./sections/GoalSection";
import {
  IAddExperience,
  IAuthor,
  IExperience,
  firebase_service,
} from "../../../firebase";
import { InitialAddExperienceState } from "./InitialExperienceState";
import "./../Experience.css";
import { useAppDispatch, useAppSelector } from "../../../redux";
import {
  addNewExperienceThunk,
  experienceList,
  updateExperienceThunk,
} from "../../../redux/reducers/ExperienceReducer";
import {
  Navigate,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuthProvider } from "../../../providers";

export const AddNewExperience: React.FC = () => {
  // console.log("mermaid contentLoaded run on add page ::::");
  // mermaid.contentLoaded();
  // mermaid.init()
  const [editableMode, setEditableMode] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  const [isExpFetch, setisExpFetch] = useState(false);
  const { userProfile } = useAuthProvider();

  let { id: expId } = useParams();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const mode = query.get("mode");

  const experiencesList = useAppSelector(experienceList);

  const [initialExpState, setInitialExpState] = React.useState<IExperience>({
    id: "",
    ...InitialAddExperienceState,
  });
 
  React.useEffect(() => {
    if (!mode) {
    } else if (mode && userProfile != null) {
      setEditableMode(mode === "edit" ? true : false);
    }
  }, [mode]);
  React.useEffect(() => {
    if (expId) {
      firebase_service["doc"]
        .getDocument("experience", expId)
        .then((experience:any) => {
          if(userProfile==null&& !experience.isPublished){
            navigate("/")
          }
          setInitialExpState(experience as IExperience);
          setisExpFetch(true);
      
        })
        .catch((error) => {
          console.log("Error while getting experience, ", error);
        });
    }
  }, [expId, experiencesList]);

  const onInputsChange =
    <T extends IExperience>(key: keyof T) =>
    (value: T[keyof T]) => {
      setInitialExpState((state) => {
        return { ...state, [key]: value };
      });
    };

  const onAddNewClick = async () => {
    if (!editableMode) {
      const newExp = { ...InitialAddExperienceState } as IExperience;
      newExp.author = userProfile
        ? userProfile
        : InitialAddExperienceState.author;
      const rep: any = await dispatch(
        addNewExperienceThunk(newExp as IAddExperience)
      );
      const newExpId = rep.payload.id.data.id;
      setInitialExpState(rep.payload.id.data);
      setEditableMode(true);
      navigate(`/experience/${newExpId}?mode=edit`);
    }
  };
  const onEditClick = () => {
    if (editableMode) {
      dispatch(
        updateExperienceThunk(initialExpState as IExperience | any)
      ).then((res) => {
        setEditableMode(false);
      });
    }
    setEditableMode(!editableMode);
  };

  return (
    <div id="homepage">
      <ExperienceHeader
        id={expId ? expId : ""}
        title={initialExpState.title}
        authorName={`${initialExpState.author.firstName} ${initialExpState.author.lastName}`}
        cognitive={initialExpState.cognitive}
        experience={initialExpState.experience}
        subject={initialExpState.subject}
        grade={initialExpState.grade}
        editableMode={editableMode}
        onChange={onInputsChange}
        onAddClick={onAddNewClick}
        onEditClick={onEditClick}
      />
      <section className="container main-sec">
        {isExpFetch ? (
          <>
            <BackgroundSection
              value={initialExpState.background}
              editableMode={editableMode}
              onChange={onInputsChange}
            />
            <GoalSection
              value={initialExpState.goal}
              editableMode={editableMode}
              onChange={onInputsChange}
            />
          </>
        ) : (
          ""
        )}
      </section>
    </div>
  );
};
