import { RequestResponse } from "../../constants";
import { typesenseClient } from "../Config";
import { COLLECTION_EXPERIENCE_TYPE, ISubject } from "../Interfaces";
import { CollectionSchema } from "../Schemas";

export const createExperienceTypeDoc = async (name: string) => {
  try {
    if ( !(await typesenseClient.collections(COLLECTION_EXPERIENCE_TYPE).exists()) )
      await typesenseClient
        .collections()
        .create(CollectionSchema[COLLECTION_EXPERIENCE_TYPE]);

    const docResult = await typesenseClient.collections(COLLECTION_EXPERIENCE_TYPE).documents().create({ title: name  });
    return { ...RequestResponse["201"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const deleteExperienceTypeeDoc = async (gradeId: number) => {
  try {
    const docResult = await typesenseClient
      .collections(COLLECTION_EXPERIENCE_TYPE)
      .documents(gradeId.toString())
      .delete();
    return { ...RequestResponse["200"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const updatedExperienceTypeDoc = (id: string, title: string) => typesenseClient.collections(COLLECTION_EXPERIENCE_TYPE).documents(id).update({title});

export const experience_type_service = {
  createExperienceTypeDoc,
  updatedExperienceTypeDoc,
  deleteExperienceTypeeDoc,
};
