import { HomePage } from "./pages/home";
import Experience from "./pages/experience/Experience";
import { Console } from "./pages/console";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { AppFooter, AppNavbar } from "./components";
import { Routes, Route, useMatch, Navigate } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import { useAuthProvider } from "./providers";
import { useEffect, useState } from "react";
import { RootState, fetchCongnitiveTypeListThunk,  fetchGradeListThunk, useAppDispatch } from "./redux";
import { AddNewExperience } from "./pages/experience/create_new/AddNewExperience";
import { fetchSubjectsListThunk } from "./redux/reducers/SubjectReducer";
import './assets/css/style.css'
import { fetchExperienceTypeListThunk } from "./redux/reducers/ExperienceTypeReducer";

import { fetchExperienceListThunk } from "./redux/reducers/ExperienceReducer";
import { useSelector } from "react-redux";
import { AuthActions } from "./pages/profile/CreateNewPassword";
import { showAlertCommonDialog } from "./redux/features/Dialog/AlertCommonDialogSlice";


const App = () => {
  const dispatch = useAppDispatch();
  const [isNavbarVisible, setIsNavbarVisible] = useState(true)
  let urlLocation = window.location;
  let isExperience = useMatch('/experience')
const FilterSearchExperiences=useSelector((s:RootState)=>s.filterSearchExperiences)
  useEffect(() => {
    dispatch(fetchExperienceTypeListThunk())
    dispatch(fetchSubjectsListThunk())
    dispatch(fetchGradeListThunk())
    dispatch(fetchCongnitiveTypeListThunk())

  }, [])
  // console.log("mermaid startOnLoad run ::::");
  // mermaid.initialize({ startOnLoad: true });

  useEffect(() => {
      if(urlLocation.pathname.includes('experience')) return setIsNavbarVisible(false)
      setIsNavbarVisible(true)
  }, [urlLocation.href]);

 ///////call Fetch method ///////////
 useEffect(() => {
  dispatch(fetchExperienceListThunk(FilterSearchExperiences));
  console.log("Filter Search slice", FilterSearchExperiences);

}, [FilterSearchExperiences]);

  return (
    <>
      {isNavbarVisible && <AppNavbar />}
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/auth_actions" element={<AuthActions/>} />
        <Route path="/experiences" element={<Experience />} />
        <Route path="/experience/:id" element={<AddNewExperience />} />
        <Route path="/add_new_experience" element={<AddNewExperience />} />
        <Route path="/console" element={<PrivateRoute> <Console /> </PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" />} />
        {/* <Route path="dev" element={<DeveloperMode/>} /> */}
      </Routes>
      <AppFooter />
    </>
  );
};

export default App;