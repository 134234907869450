import Add from "../../assets/images/add.svg";
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  addNewCognitiveThunk,
  addNewGradeThunk,
  congnitiveTypeSlice,
  fetchGradeListThunk,
  gardeSlice,
  useAppDispatch,
} from "../../redux";
import {
  addNewExperienceThunk,
  experienceSlice,
} from "../../redux/reducers/ExperienceReducer";
import {
  addNewExperienceTypeThunk,
  experienceTypeSlice,
} from "../../redux/reducers/ExperienceTypeReducer";

interface IAddDialog {
  open: boolean;
  onClose: () => void;
  type: "grades" | "experience" | "cognitive";
  gradeArray?: string[] | any;
  setGradeArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
  experienceArray?: string[] | any;
  setexperienceArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
  cognitiveArray?: string[] | any;
  setcognitiveArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
}

const AddDialog = ({
  onClose,
  open,
  type,
  setGradeArray,
  gradeArray,
  setexperienceArray,
  experienceArray,
  setcognitiveArray,
  cognitiveArray,
}: IAddDialog) => {
  const dispatch = useAppDispatch();

  const [addText, setaddText] = useState("");

  const handleAddData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const timestamp = new Date().getTime(); // get current timestamp
    const newId = timestamp.toString();
    switch (type) {
      case "grades":
        const gradeExists = gradeArray.some((s: any) => s.title.toLowerCase() === addText.toLowerCase());

        if (gradeExists) {
          alert("Grade with same name already exists!");
          return;
        }
        dispatch(addNewGradeThunk(addText));
        const updatedGradeArray = [
          ...gradeArray,
          { newId, title: addText, timestamp },
        ];
        setGradeArray(updatedGradeArray);
        dispatch(gardeSlice.actions.gradeArray(updatedGradeArray));
        break;
      case "experience":
        const expExists = experienceArray.some((s: any) => s.title.toLowerCase() === addText.toLowerCase());

        if (expExists) {
          alert("Experience with same name already exists!");
          return;
        }

        dispatch(addNewExperienceTypeThunk(addText));
        const updatesExperienceArray = [
          ...experienceArray,
          { newId, title: addText, timestamp },
        ];
        setexperienceArray(updatesExperienceArray);
        dispatch(
          experienceTypeSlice.actions.experienceArray(updatesExperienceArray)
        );
        break;
      case "cognitive":
        const cognitiveExists = cognitiveArray.some(
          (s: any) => s.title.toLowerCase() === addText.toLowerCase()
        );

        if (cognitiveExists) {
          alert("Cognitive with same name already exists!");
          return;
        }
        dispatch(addNewCognitiveThunk(addText));
        const updatedCognitiveArray = [
          ...cognitiveArray,
          { newId, title: addText, timestamp },
        ];
        setcognitiveArray(updatedCognitiveArray);
        dispatch(congnitiveTypeSlice.actions.cognitiveArray(updatedCognitiveArray));
        break;
      default:
        break;
    }
    onClose();
  };

  return (
    <Modal
      className="grade-add-modal"
      show={open}
      onHide={onClose}
      onSubmit={handleAddData}
      keyboard={false}
      id="loginModal"
    >
      <div className="modal-body" >
        <div className="text-center">
          <h1 className="model-header-title">Add It !</h1>
        </div>
        <form id="loginsubmit">
          <div className="form">
            {type === "grades" && (
              <>
                <label htmlFor="loginemail">
                  <p>Add Grades Data</p>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="textField"
                  placeholder="Enter Add Text"
                  required
                  value={addText}
                  onChange={(e) => setaddText(e.target.value)}
                />
              </>
            )}

            {type === "cognitive" && (
              <>
                <label htmlFor="loginemail">
                  <p>Add Cognitive Data</p>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="textField"
                  placeholder="Enter Add Text"
                  required
                  value={addText}
                  onChange={(e) => setaddText(e.target.value)}
                />
              </>
            )}

            {type === "experience" && (
              <>
                <label htmlFor="loginemail">
                  <p>Add Experience Data</p>
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="textField"
                  placeholder="Enter Add Text"
                  required
                  value={addText}
                  onChange={(e) => setaddText(e.target.value)}
                />
              </>
            )}

            <button
              type="submit"
              className="submit-btn"
              style={{
                margin: "auto",
                marginBlock: 20,
                width: "130px",
                height: "40px",
                alignSelf: "center",
              }}
            >
              <img src={Add} alt="add-img" height={"30px"} width={"30px"} />
              <p >Add</p>
            </button>
          </div>
        </form>
        <hr />
      </div>
    </Modal>
  );
};

export default AddDialog;
