import React, { useState } from "react";
import ReactDOMServer from "react-dom/server";
import True from "../../../assets/images/true.svg";
import Message from "../../../assets/images/massage.svg";
import Danger from "../../../assets/images/danger.svg";
import Close from "../../../assets/images/close.svg";

////////custom component for edit mode //////////
const CustomInput = () => {
  return (
    <div className="" id="custom-box">
      <div className="d-flex align-items-center">
        <p className="m-0 p-0 w-100">
          <textarea
            className="w-100 border-0"
            placeholder="enter text"
            value=""
            id="custom-box-text"
            style={{
              backgroundColor: "inherit",
              wordBreak: "break-word",
              height: "fit-content",
            }}
          />
        </p>
        <div>
          <img alt="" id="custom-box-icon" style={{marginRight:"0px"}}  />
        </div>
      </div>
    </div>
  );
};

////////////custom component end //////////
interface ISuccessProps {
  config?: {
    type: "success" | "danger" | "warning" | "Quote";
  };
  data?: {
    text: string;
    type: "success" | "danger" | "warning" | "Quote";
  };
}

export class CustomQuoteBox {
  config: ISuccessProps["config"];
  data: ISuccessProps["data"];
  wrapper: any;

  constructor({ data, config }: ISuccessProps) {
    this.config = config;
    this.data = data || {
      text: "",
      type: "success",
    };
  }
  findCurrentClass() {
    if (this.data?.type) {
      const currentClass =
        this.data.type == "danger"
          ? "citactions-text-sec do-not"
          : this.data.type == "warning"
          ? "citactions-text-sec caution"
          : this.data.type == "success"
          ? "citactions-text-sec do"
          : this.data.type == "Quote"
          ? "citactions-text-sec"
          : "citactions-sec";
      return currentClass;
    } else {
      const currentClass =
        this.config?.type == "danger"
          ? "citactions-text-sec do-not"
          : this.config?.type == "warning"
          ? "citactions-text-sec caution"
          : this.config?.type == "success"
          ? "citactions-text-sec do"
          : this.config?.type == "Quote"
          ? "citactions-text-sec"
          : "citactions-sec";
      return currentClass;
    }
  }
  findCurrentIcon() {
    if (this.data) {
      const currentIcon =
        this.data.type == "danger"
          ? Close
          : this.data.type == "warning"
          ? Danger
          : this.data.type == "success"
          ? True
          : this.data.type == "Quote"
          ? Message
          : True;
      return currentIcon;
    }
  }
  ////////////for toolbox add///////////
  static get toolbox() {
    return {
      title: "Quote",
      icon: `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
      viewBox="0 0 58.707 58.707" xml:space="preserve">
   <g>
     <g>
       <polygon style="fill:#EFEBDE;" points="46.072,14 32.072,0 1.072,0 1.072,58 46.072,58 		"/>
       <g>
         <path style="fill:#D5D0BB;" d="M11.072,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S10.52,23,11.072,23z"/>
         <path style="fill:#D5D0BB;" d="M11.072,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S10.52,15,11.072,15z"/>
         <path style="fill:#D5D0BB;" d="M36.072,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S36.624,29,36.072,29z"/>
         <path style="fill:#D5D0BB;" d="M36.072,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S36.624,37,36.072,37z"/>
         <path style="fill:#D5D0BB;" d="M36.072,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S36.624,45,36.072,45z"/>
       </g>
       <polygon style="fill:#D5D0BB;" points="32.072,0 32.072,14 46.072,14 		"/>
     </g>
     <g>
       <polygon style="fill:#EDDCC7;" points="36.201,49.214 36.194,49.222 34.205,56.511 38.852,51.865 		"/>
       <path style="fill:#D75A4A;" d="M55.451,35.266l-1.247-1.247c-0.775-0.775-2.032-0.775-2.807,0L47.815,37.6l2.651,2.651
         L55.451,35.266z"/>
       
         <rect x="41.459" y="36.521" transform="matrix(0.7071 0.7071 -0.7071 0.7071 44.3228 -17.5395)" style="fill:#F29C21;" width="3.749" height="16.424"/>
       <polygon style="fill:#D6C4B1;" points="41.85,54.879 41.858,54.871 38.852,51.865 34.205,56.511 34.072,57 		"/>
       <path style="fill:#A34740;" d="M53.472,43.257l3.582-3.582c0.775-0.775,0.775-2.032,0-2.807l-1.602-1.602l-4.985,4.985
         L53.472,43.257z"/>
       
         <rect x="44.036" y="39.349" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 45.1717 113.8333)" style="fill:#E18C25;" width="4.251" height="16.424"/>
       <path style="fill:#5E5E5E;" d="M33.365,58.707c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l2.207-2.207
         c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-2.207,2.207C33.877,58.609,33.621,58.707,33.365,58.707z"/>
     </g>
   </g>
   </svg>`,
    };
  }

  ///////////render in editmode /////////////
  renderSettings() {
    return [
      {
        icon: `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.58 122.88"><title>message</title><path d="M25.8,111.27,44.08,94.69a3.46,3.46,0,0,1,2.41-1h66.18a2,2,0,0,0,2-1.95V8.9a2,2,0,0,0-2-1.95H8.9A1.95,1.95,0,0,0,7,8.9V91.76a1.95,1.95,0,0,0,2,1.95H22.33a3.48,3.48,0,0,1,3.47,3.48v14.08Zm1.17-45a3.48,3.48,0,0,0,0,7H68a3.48,3.48,0,0,0,0-7Zm0-39.86a3.48,3.48,0,0,0,0,7H94.69a3.48,3.48,0,1,0,0-6.95Zm0,19.93a3.48,3.48,0,0,0,0,6.95H87.66a3.48,3.48,0,0,0,0-6.95Zm20.9,54.32-23,21.07a3.48,3.48,0,0,1-6.06-2.32V100.66H8.9A8.91,8.91,0,0,1,0,91.76V8.9A8.91,8.91,0,0,1,8.9,0H112.67a8.93,8.93,0,0,1,8.91,8.9V91.76a8.93,8.93,0,0,1-8.91,8.9Z"/></svg>`,
        label: "Quote",
        onActivate: (e: any) => {
          if (this.data) {
            this.data.type = e.title;
            this.wrapper.querySelector("#custom-box").classList =
              this.findCurrentClass();
            this.wrapper.querySelector("#custom-box-icon").src =
              this.findCurrentIcon();
          }
        },
      },
      {
        icon: `<svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><style type="text/css">
        .st0{fill:#2BB673;}
        .st1{fill:none;stroke:#FFFFFF;stroke-width:30;stroke-miterlimit:10;}
      </style><path class="st0" d="M489,255.9c0-0.2,0-0.5,0-0.7c0-1.6,0-3.2-0.1-4.7c0-0.9-0.1-1.8-0.1-2.8c0-0.9-0.1-1.8-0.1-2.7  c-0.1-1.1-0.1-2.2-0.2-3.3c0-0.7-0.1-1.4-0.1-2.1c-0.1-1.2-0.2-2.4-0.3-3.6c0-0.5-0.1-1.1-0.1-1.6c-0.1-1.3-0.3-2.6-0.4-4  c0-0.3-0.1-0.7-0.1-1C474.3,113.2,375.7,22.9,256,22.9S37.7,113.2,24.5,229.5c0,0.3-0.1,0.7-0.1,1c-0.1,1.3-0.3,2.6-0.4,4  c-0.1,0.5-0.1,1.1-0.1,1.6c-0.1,1.2-0.2,2.4-0.3,3.6c0,0.7-0.1,1.4-0.1,2.1c-0.1,1.1-0.1,2.2-0.2,3.3c0,0.9-0.1,1.8-0.1,2.7  c0,0.9-0.1,1.8-0.1,2.8c0,1.6-0.1,3.2-0.1,4.7c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0.1s0,0,0,0.1c0,0.2,0,0.5,0,0.7c0,1.6,0,3.2,0.1,4.7  c0,0.9,0.1,1.8,0.1,2.8c0,0.9,0.1,1.8,0.1,2.7c0.1,1.1,0.1,2.2,0.2,3.3c0,0.7,0.1,1.4,0.1,2.1c0.1,1.2,0.2,2.4,0.3,3.6  c0,0.5,0.1,1.1,0.1,1.6c0.1,1.3,0.3,2.6,0.4,4c0,0.3,0.1,0.7,0.1,1C37.7,398.8,136.3,489.1,256,489.1s218.3-90.3,231.5-206.5  c0-0.3,0.1-0.7,0.1-1c0.1-1.3,0.3-2.6,0.4-4c0.1-0.5,0.1-1.1,0.1-1.6c0.1-1.2,0.2-2.4,0.3-3.6c0-0.7,0.1-1.4,0.1-2.1  c0.1-1.1,0.1-2.2,0.2-3.3c0-0.9,0.1-1.8,0.1-2.7c0-0.9,0.1-1.8,0.1-2.8c0-1.6,0.1-3.2,0.1-4.7c0-0.2,0-0.5,0-0.7  C489,256,489,256,489,255.9C489,256,489,256,489,255.9z" id="XMLID_3_"/><g id="XMLID_1_"><line class="st1" id="XMLID_2_" x1="213.6" x2="369.7" y1="344.2" y2="188.2"/><line class="st1" id="XMLID_4_" x1="233.8" x2="154.7" y1="345.2" y2="266.1"/></g></svg>`,
        label: "success",
        onActivate: (e: any) => {
          if (this.data) {
            this.data.type = e.title;
            this.wrapper.querySelector("#custom-box").classList =
              this.findCurrentClass();
            this.wrapper.querySelector("#custom-box-icon").src =
              this.findCurrentIcon();
          }
        },
      },
      {
        icon: `<svg data-name="Layer 1" id="Layer_1" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg"><defs><style>.cls-1{fill:#efcc00;}.cls-2{fill:#353535;}</style></defs><title/><path class="cls-1" d="M30.16,11.51,6.84,51.9a2.13,2.13,0,0,0,1.84,3.19H55.32a2.13,2.13,0,0,0,1.84-3.19L33.84,11.51A2.13,2.13,0,0,0,30.16,11.51Z"/><path class="cls-2" d="M29,46a3,3,0,1,1,3,3A2.88,2.88,0,0,1,29,46Zm1.09-4.66-.76-15h5.26l-.73,15Z"/></svg>`,
        label: "warning",
        onActivate: (e: any) => {
          if (this.data) {
            this.data.type = e.title;
            this.wrapper.querySelector("#custom-box").classList =
              this.findCurrentClass();
            this.wrapper.querySelector("#custom-box-icon").src =
              this.findCurrentIcon();
          }
        },
      },

      {
        icon: `<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 48 48" width="48px" height="48px"><path fill="#F44336" d="M21.5 4.5H26.501V43.5H21.5z" transform="rotate(45.001 24 24)"/><path fill="#F44336" d="M21.5 4.5H26.5V43.501H21.5z" transform="rotate(135.008 24 24)"/></svg>`,
        label: "danger",
        onActivate: (e: any) => {
          if (this.data) {
            this.data.type = e.title;
            this.wrapper.querySelector("#custom-box").classList =
              this.findCurrentClass();
            this.wrapper.querySelector("#custom-box-icon").src =
              this.findCurrentIcon();
          }
        },
      },
    ];
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(CustomInput());
    this.wrapper.querySelector("#custom-box").classList =
      this.findCurrentClass();
    console.log("current class ::", this.findCurrentClass(), this.config);
    this.wrapper.querySelector("#custom-box-icon").src = this.findCurrentIcon();
    const { text } = this.data ? this.data : { text: "" };
    this.wrapper.querySelector("#custom-box-text").value = text ? text : "";
    return this.wrapper;
  }
  ///////save data after edit ////////////
  save(blockContent: any) {
    const textVal = blockContent.querySelector("#custom-box-text")?.value;
    return {
      text: textVal,
      type: this.data?.type ? this.data.type : this.config?.type,
    };
  }
}
