import { UserPermissionsAndRoles } from "./components/UserPermissionsAndRoles";
import SubjectTable from "./components/SubjectTable";
import GradeTable from "./components/GradeTable";
import CognitiveTable from "./components/CognitiveTable";
import ExperienceTable from "./components/ExperienceTable";
import DeleteDialog from "../../dialogs/consolePageDialogs/SubjectDialog/DeleteSubjectDialog";
import { CommonDialog } from "../../dialogs/CommonDialog";


export const Console = () => {


 
  return (
    <section className="main-section container">

      {/* /////////usersPermissions//////////// */}
      <UserPermissionsAndRoles/>  
      <CommonDialog/>
        <br />
        <SubjectTable/>
        <br />
        <br />
        <div className="position-relative overflow-scroll d-flex">
          <GradeTable/>
          <CognitiveTable/>
          <ExperienceTable/>    
        </div>
      
    </section>
  );
};