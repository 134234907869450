import React, { useRef } from "react";
import { Button } from "react-bootstrap";
const parse = require("html-react-parser");
export const CustomCard = () => {
  return (
    <>
      <div className="card-sec my-3">
        <div className="flip-card mx-auto custom-card-tool">
          <div className="flip-card-front my-auto" style={{paddingTop:"35px "}}>
            <h3 className="text-center mb-3">
              <input
                type="text"
                id="tool-card-title"
                style={{
                  border: "none",
                  backgroundColor: "inherit",
                  fontWeight: 700,
                  
                }}
                placeholder="Enter title here"
                maxLength={20}
              ></input>
            </h3>
            <p
              className="mx-auto my-0 px-1 py-1 pb-0"
              id="tool-card-front-text"
              style={{ minHeight: "100%", width: "100%", overflow: "scroll" }}
              contentEditable={true}
            ></p>
          </div>
        </div>
      </div>
      <div className="card-sec">
        <div className="flip-card mx-auto my-auto custom-card-tool">
          <div className="flip-card-front">
            <p
              className="mx-auto my-0 px-1 py-1 pb-0"
              id="tool-card-back-text"
              style={{ minHeight: "100%", width: "100%", overflow: "scroll" }}
              contentEditable={true}
            ></p>
          </div>
        </div>
      </div>
    </>
  );
};

interface Iprops {
  data: {
    cardTitle: string;
    cardFrontText: string;
    cardBackText: string;
  };
}
export const CustomCardView = ({ data }: Iprops) => {
  return (
    <>
      <div className="card-sec" style={{marginBottom:"0px !important",marginTop:"36px"}}>
        <div className="flip-card mx-auto">
          <div className="flip-card-inner" >
            <div className="flip-card-front" style={{paddingTop:"35px "}}>
              <h3 className="text-center" >{data.cardTitle}</h3>
              <p
                className="mb-0 px-1"
                style={{
                  textAlign: "justify",
                  minHeight: "100%",
                  width: "100%",
                  overflow:"scroll"
                }}
              >
                {parse(data.cardFrontText)}
              </p>
            </div>
            <div className="flip-card-back">
              <div className="image-block h-100">
                <p
                  className="px-1 py-1"
                  style={{
                    textAlign: "justify",
                    maxHeight: "100%",
                    width: "100%",
                    overflow: "scroll",
                  }}
                >
                  {parse(data.cardBackText)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
