import { useAppSelector } from "../../../redux";
import {
  experienceList,
  experienceListStatus,
} from "../../../redux/reducers/ExperienceReducer";

import notFoundLogo from "../../../assets/images/notfound.svg";

import { ExperienceCard } from "./ExperienceCard";
import { useAuthProvider } from "../../../providers";
import { CommonDialog } from "../../../dialogs/CommonDialog";

export const ExperienceSection = (props: any) => {
  const subjectsList = useAppSelector((state) => state.subjects.data);
  const experiencesList = useAppSelector(experienceList);
  const experiencesListStatus = useAppSelector(experienceListStatus);
  const {  dialog } = useAppSelector((s) => s);
  const { userProfile } = useAuthProvider();
  const renderExperiencesCards = () => {
    return experiencesList.map((exp, idx) => (
      <ExperienceCard
        exp={exp}
        key={idx}
        subjectsList={subjectsList}
        currentUser={userProfile ? userProfile : undefined}
      />
    ));
  };

  /////////////no data found/////////
  const renderNoDataFound = () => {
    return (
      <div className="col-12 notfound">
        <div className="text-center d-flex" style={{flexDirection:"column",justifyContent:"center"}}>
          <img
            src={notFoundLogo}
            className="img-fluid"
            alt="notfound"
            style={{ maxWidth: 45 ,margin:"auto"}}
          />
          <h1>Uh-oh..</h1>
          <p>Nothing found for these settings, try again!</p>
        </div>
      </div>
    );
  };

  ///////////data returned//////////
  return (
    <>
      <div  className="w-100" style={{zIndex:5}}>
        {dialog.isDialog? <CommonDialog /> : ""}
        <div className="card-container-title pb-0" style={{paddingLeft:5}}>
          <h2 className="mb-0">Experiences</h2>
        </div>
        <div className="row" id="card-container">
          {experiencesListStatus == "fulfilled"
            ? experiencesList.length > 0
              ? renderExperiencesCards()
              :renderNoDataFound()
            : ""}
        </div>
        
      </div>
    </>
  );
};

export default ExperienceSection;
