import React, { useState } from "react";
import { SignInDialog } from "../dialogs/SignInDialog";
import SiteLogo from "../assets/images/logo-experience.svg";
import HomeIcon from "../assets/images/home.svg";
import { SignUpDialog } from "../dialogs/SignUpDialog";
import { useAuthProvider } from "../providers";
import { Link, useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAuthorInExperience } from "../redux/features/filterAndSearchData/FilterSearchExperienceSlice";
import { ForgotPasswordDialog } from "../dialogs/ForgotPasswordDialog";
import { Root } from "react-dom/client";
import { useAppSelector } from "../redux";
import { RecoverPasswordLinkSentDialog } from "../dialogs/RecoverPasswordLinkSentDialog";
import { AccountVerificationDialog } from "../dialogs/AccountVerificationDialog";
import { CreateNewPasswordDialog } from "../dialogs/CreateNewPasswordDialog";
import { PasswordResetLinkExpireDialog } from "../dialogs/PasswordResetLinkExpireDialog";
import { VerifyAccountDialog } from "../dialogs/VerifyEmailConfirmDialog";
import { AccountVerificationLinkExpireDialog } from "../dialogs/EmailVerificationLinkExpireDialog";
import { AccountVerificationLinkSentConfirmationDialog } from "../dialogs/AccountVerificationLinkSentConfirmationDialog";
import { AlertCommonDialog } from "../dialogs/AlertCommonDialog";

/**
 * The Header component for home page
 *
 * @returns {JSX.Element} returns the Header for home page
 */
export const AppNavbar = () => {
  const navigate = useNavigate();
  const { isAlertDialog } = useAppSelector((s) => s.alertCommonDialog);
  const {
    isAccountVerificationLinkSent,
    isAccountVerifiedSuccessDialog,
    isAccountVerificationLinkExpire,
    isAccountVerificationLinkSentConfirmationDialog,
  } = useAppSelector((s) => s.accountVerificationDialog);
  const {
    isForgotPasswordDialog,
    isRecoverPasswordLinkSentDialog,
    isPasswordResetLinkExpireDialog,
  } = useAppSelector((s) => s.forgotPasswordDialog);
  const [loginDialog, setLoginDialog] = React.useState(false);
  const [signupDialog, setSignupDialog] = React.useState(false);
  const { isAuthenticated, logoutAsync, userProfile } = useAuthProvider();
  const dispatch = useDispatch();
  let isConsole = useMatch("/console");
  const { isCreateNewPasswordDialog } = useAppSelector(
    (s) => s.createNewPassword
  );
  console.log("user data", userProfile);
  let userMenuText = userProfile
    ? `${userProfile?.firstName[0]}${userProfile?.lastName[0]}`
    : "";
  const onSignupClick = () => {
    setLoginDialog(false);
    setSignupDialog(true);
  };

  const onLogout = () => {
    logoutAsync();
    dispatch(resetAuthorInExperience());
    navigate("/");
  };
  return (
    <>
      <header className="container-fluid">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="logo-sec ">
            <Link to="/">
              <img className="logo-image img-fluid" src={SiteLogo} alt="logo" />
            </Link>
            <h1 className="mb-0">{isConsole ? "Console" : "Catalog"}</h1>
          </div>
          <div className="dropdown-center">
            <button
              className="account-btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img className="img-fluid" src={HomeIcon} alt="home" />

              <h3 className="account-name mb-0">
                {isAuthenticated ? userMenuText : "Menu"}
              </h3>
            </button>
            <ul className="dropdown-menu menu-option-btn py-0">
              {/* Next cycle<li><a className="dropdown-item program" href="javascript:void(0)"> Program</a></li>  */}

              {isAuthenticated && userProfile?.adminUser ? (
                <li
                  className="dropdown-item "
                  onClick={() => {
                    navigate("/console");
                  }}
                >
                  Console
                </li>
              ) : (
                ""
              )}

              <li
                className="dropdown-item login-btn"
                onClick={() =>
                  isAuthenticated ? onLogout() : setLoginDialog(true)
                }
              >
                {isAuthenticated ? "Logout" : "Login"}
              </li>
            </ul>
          </div>
        </div>
      </header>
      {loginDialog && (
        <SignInDialog
          open={loginDialog}
          onClose={() => setLoginDialog(false)}
          onSignupClick={onSignupClick}
        />
      )}
      {signupDialog && (
        <SignUpDialog
          open={!isForgotPasswordDialog && signupDialog}
          onClose={() => setSignupDialog(false)}
        />
      )}
      {isForgotPasswordDialog && (
        <ForgotPasswordDialog open={isForgotPasswordDialog} />
      )}
      {isRecoverPasswordLinkSentDialog && (
        <RecoverPasswordLinkSentDialog open={isRecoverPasswordLinkSentDialog} />
      )}
      {isAccountVerificationLinkSent && <AccountVerificationDialog />}
      {isCreateNewPasswordDialog && (
        <CreateNewPasswordDialog setLoginDialog={setLoginDialog} />
      )}
      {isPasswordResetLinkExpireDialog && <PasswordResetLinkExpireDialog />}
      {isAccountVerifiedSuccessDialog && (
        <VerifyAccountDialog setLoginDialog={setLoginDialog} />
      )}
      {isAccountVerificationLinkExpire && (
        <AccountVerificationLinkExpireDialog />
      )}
      {isAccountVerificationLinkSentConfirmationDialog && (
        <AccountVerificationLinkSentConfirmationDialog />
      )}
      {isAlertDialog && <AlertCommonDialog />}
    </>
  );
};
