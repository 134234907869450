import React, { useMemo, useState } from "react";

import serachIcon from "../../../assets/images/search.svg";
import { IAuthor, IExperienceType } from "../../../firebase";
import { getAuthorDoc, updatedAuthorDoc } from "../../../firebase/services";
import { pagination } from "../../../helper/Pagination";
import { UserPermissionRow } from "./UserPermissionRow";
import { getAuth } from "firebase/auth";
export const UserPermissionsAndRoles = () => {
  const [UsersData, setUsersData] = useState([]) as any;
  const [Search, setSearch] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);

  /////////////getdata from backend//////////////
  const getUserData = async () => {
    const data = await getAuthorDoc(Search);
    return data.hits && data.hits.length > 0
      ? (data.hits?.map((doc) => doc.document) as IExperienceType[])
      : [];
  };
  useMemo(async () => {
    const data: Array<any> = await getUserData();
    const finalData=data.filter(user=>user.active==true)
    setUsersData(finalData);
  }, [Search]);


  /////////pagination/////////
  const { sliceIndexs } = pagination(UsersData, 4);
  const renderPagesList = sliceIndexs.map((list) => {
    return (
      <a
        className={CurrentPage == list.page_no ? "active" : ""}
        onClick={() => setCurrentPage(list.page_no)}
        key={list.page_no}
      >
        {list.page_no}
      </a>
    );
  });
  /////////rendered paginated rows/////////////
  let renderRows = () =>
    UsersData.slice(
      sliceIndexs[CurrentPage - 1].initialIndex,
      sliceIndexs[CurrentPage - 1].lastIndex
    ).map((user: IAuthor) => {
      return (
        <UserPermissionRow User={user} UsersData={UsersData} setUsersData={setUsersData} key={user.id}/>
      );
    });

  ///////////return tsx//////////////
  return (
    <div style={{ overflow: "scroll" }}>
      {" "}
      {/* <!-- search bar input start  --> */}
      <div className="search-bar mb-3">
        <input
          type="text"
          className="form-control search-input"
          placeholder="Search users by email and names"
          name="search"
          autoComplete="off"
          value={Search}
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
        />
        <img
          className="img-fluid"
          src={serachIcon}
          alt="search"
          style={{ maxWidth: 22 }}
        />
      </div>
      {/* <!-- search bar input End  --> */}
      <br />
      <br />
      <table className="table-sec table mb-1">
        {/* ///table hearder/// */}
        <thead>
          <tr>
            <th>
              <h3>
                User
                <br />
                ID
              </h3>
            </th>
            <th>
              <h3>
                Last
                <br />
                Name
              </h3>
            </th>
            <th>
              <h3>
                First
                <br />
                Name
              </h3>
            </th>
            <th>
              <h3>e-mail</h3>
            </th>
            <th>
              <h3>
                Simple
                <br />
                User
              </h3>
            </th>
            <th>
              <h3>
                Admin
                <br />
                User
              </h3>
            </th>

            <th>
              <h3>
                Sign
                <br />
                In
              </h3>
            </th>
          </tr>
        </thead>
        <tbody style={{ height: "250px" }}>
          {/* ///table rows /// */}
          {UsersData.length > 0 ? renderRows() : <tr></tr>}
        </tbody>
      </table>
      {/* /////pagination////// */}
      <div className="pagination">
        {UsersData.length > 0 ? renderPagesList : ""}
      </div>
      <p></p>
    </div>
  );
};
