import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HandIcon from "../assets/images/hand.svg";
import KeyIcon from "../assets/images/key.svg";
import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch } from "../redux";
import { closeForgotPasswordDailog, closeRecoverPasswordLinkSentDialog } from "../redux/features/Dialog/ForgotPasswordDialogSlice";
import { ForgotPassword } from "../firebase/services";
import { sendEmailVerification } from "firebase/auth";
interface Iprops {
  open: boolean;
}
export const RecoverPasswordLinkSentDialog = ({ open }: any) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeRecoverPasswordLinkSentDialog());
  };
  setTimeout(() => {
    dispatch(closeRecoverPasswordLinkSentDialog());
  }, 3000);

  return (
    <Modal show={open} onHide={onClose} keyboard={false} id="loginModal">
      <div className="modal-forgotpassword">
      <div className="modal-body">
                    <div className="text-center">
                        <h1 className="model-header-title">Password forgotten?</h1>
                        <p className="model-header-text">No worries, it happens to all of us and we’re here to help!
                            <img className="d-block m-auto" src={HandIcon} alt="head"
                            style={{maxWidth:"25px"}}
                            />
                        </p>
                    </div>
                    <hr/>
                    <p className="model-header-text text-center mb-0">Nice! Check your email, the link to reset your
                        password is on its way!
                    </p>
                </div>
      </div>
    </Modal>
  );
};
