import React, { useEffect, useState } from "react";
import Message from "../../assets/images/massage.svg";
import BookIcon from "../../assets/images/book.svg";
import Danger from "../../assets/images/danger.svg";
import EditorJS, { OutputData } from "@editorjs/editorjs";

import "./CodeEditor.css";

// @ts-expect-error
import mathTex from "editorjs-math";
// @ts-expect-error
import editorListTool from "@editorjs/nested-list";
const parse = require('html-react-parser');

// @ts-expect-error
import TextVariantTune from "@editorjs/text-variant-tune";

// @ts-expect-error
import editorCodeTool from "@calumk/editorjs-codeflask";
// @ts-expect-error
import editorTableTool from "@editorjs/table";

// @ts-expect-error
import Paragraph from "@editorjs/paragraph";

// @ts-expect-error
import Embed from "@editorjs/embed";
import { StyleInlineTool } from "editorjs-style";
const Marker = require("@editorjs/marker");
const RawTool = require('@editorjs/raw');
const AlignmentTuneTool = require("editorjs-text-alignment-blocktune");
// @ts-expect-error
import Hyperlink from "editorjs-hyperlink";
// @ts-expect-error
import MermaidTool from "editorjs-mermaid";
// @ts-ignorenpm i
import katex from "katex";
// @ts-ignore
import edjsHTML from "editorjs-html";
import { firebase_service } from "../../firebase";
import { TableWithHead } from "../../pages/experience/components/codeEditorhtmlComponents/TableWithHead";
const edjsParser = edjsHTML();
import ReactDOMServer from "react-dom/server";
import { uploadVideoToCloud } from "../../firebase/services";

import {
  CustomAudio,
  CustomIFrame,
  CustomVideoPlayer,
} from "../../pages/experience/components/codeEditorhtmlComponents/CustomMediaPerview";
import { CustomQuoteWithCaption } from "./CustomTools/CustomBox";
import { CustomBoxViewComp } from "../../pages/experience/components/codeEditorhtmlComponents/CustomBoxes";

import { CustomCardView } from "../../pages/experience/components/codeEditorhtmlComponents/CustomCard";
import { CustomCardTool } from "./CustomTools/CustomCardTool";
import { MermaidViewer } from "../../pages/experience/components/codeEditorhtmlComponents/MermaidViewer";

import { CutomImageComponentView } from "../../pages/experience/components/codeEditorhtmlComponents/ImageComponent";
import { CustomVideoAudioTool } from "./CustomTools/CutomVideoAudioTool";
import { CustomImage } from "./CustomTools/CustomImage";
import { CustomQuoteBox } from "./CustomTools/CustomQuoteBox";
import { CustomQuoteBoxView } from "../../pages/experience/components/codeEditorhtmlComponents/CustomQuoteBox";

import {
  CustomHeadingView,
  CustomSubtitleView,
  CustomTitleView,
} from "../../pages/experience/components/codeEditorhtmlComponents/CustomHeaderComponent";
import { CustomInlineImageTool } from "./CustomTools/CustomInlineImageTool";
import { AssignmentModel1Tool } from "./CustomTools/AssignmentModel1Tool";
import {
  AssignmentModel1View,
} from "../../pages/experience/components/codeEditorhtmlComponents/AssignmentModel1Component";
import { AssignmentModel2Tool } from "./CustomTools/AssignmentModel2Tool";
import { AssignmentModel2View } from "../../pages/experience/components/codeEditorhtmlComponents/AssignmentModel2Component";
import { AudioTool } from "./CustomTools/CustomAudioTool";
import { CustomAudioView } from "../../pages/experience/components/codeEditorhtmlComponents/CustomAudioComponent";
import { CustomSubTitleTool } from "./CustomTools/CutomSubTitleTool";
import { CustomHeadingTool } from "./CustomTools/CustomHeadingTool";
import { CustomTitleTool } from "./CustomTools/CustomTitleTool";
import { CustomInlineLogoTool } from "./CustomTools/CustomInlineLogo";
import { Parser } from "webpack";

const initial_editor_data: OutputData = {
  time: new Date().getTime(),
  blocks: [],
};
interface ICodeEditor {
  element: string;
  editableMode: boolean;
  initialData: string;
  onChange: (outputData: string) => void;
  dataClass?: string;
}

export const CodeEditor: React.FC<ICodeEditor> = ({
  element,
  editableMode,
  initialData,
  onChange,
  dataClass,
}) => {
  const ejInstanceRef = React.useRef<any>();

  React.useEffect(() => {
    if (ejInstanceRef.current === null && editableMode) {
      initTextEditor(initialData);
    }
    return () => {
      ejInstanceRef?.current?.destroy();
      ejInstanceRef.current = null;
    };
  }, [editableMode]);

  const initTextEditor = (initialData: string) => {
    const editor = new EditorJS({
      placeholder: "Enter Your Text here...",
      holder: element,
      data: initialData !== "" ? JSON.parse(initialData) : initial_editor_data,
      readOnly: !editableMode,
      onReady: () => {
        ejInstanceRef.current = editor;
        MermaidTool.config({ theme: "default" });
      },
      onChange: async () => {
        const outputData = await editor.save();
        onChange(JSON.stringify(outputData));
      },
      inlineToolbar: true,
      tools: {
        /////////inline tools//////
        style: {
          class: StyleInlineTool,
        },
        Marker: {
          class: Marker,
          shortcut: "CMD+SHIFT+M",
        },
        inlineImage:{
          class:CustomInlineImageTool,
        
        },
        inlineBeginLogo:{
          class:CustomInlineLogoTool
        },
        ////tone Tools////////
        textVariant: TextVariantTune,
        AlignmentTool: {
          class: AlignmentTuneTool,
          config: {
            default: "left",
            blocks: {
              header: "left",
              list: "left",
            },
          },
        },
        ////////tone tools end//////
        // textVariant: TextVariantTune,
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          tunes: ["textVariant"],
        },
        heading: {
          class: CustomHeadingTool as any,
          tunes: ["AlignmentTool"],
        },
        title: {
          class: CustomTitleTool as any,
          tunes: ["AlignmentTool"],
          inlineToolbar:true,
          config: {
            uploader: {
              uploadByFile(file: File) {
                return firebase_service["cloud"]
                  .uploadFileToCloud("experience", file)
                  .then((imgUrl) => {
                    return {
                      success: 1,
                      file: {
                        url: imgUrl,
                      },
                    };
                  });
              },
            },
          },
        },
        subtitle: {
          class: CustomSubTitleTool as any,
          tunes: ["AlignmentTool"],
        },
        CustomQuoteBox: {
          class: CustomQuoteBox,
          config: {
            type: "success",
          },
        },
        CustomBox: {
          class: CustomQuoteWithCaption,
          config: {
            type: "success",
          },
        },
        list: {
          class: editorListTool,
          inlineToolbar: true,
        },
        CustomCard: {
          class: CustomCardTool,
          inlineToolbar: true,
        },
        CustomImage: {
          class: CustomImage,
          config: {
            uploader: {
              uploadByFile(file: File) {
                return firebase_service["cloud"]
                  .uploadFileToCloud("experience", file)
                  .then((imgUrl) => {
                    return {
                      success: 1,
                      file: {
                        url: imgUrl,
                      },
                    };
                  });
              },
            },
          },
        },
        mermaid: MermaidTool,
        hyperlink: {
          class: Hyperlink,
          config: {
            shortcut: "CMD+L",
            target: "_blank",
            rel: "nofollow",
            availableTargets: ["_blank", "_self"],
            availableRels: [],
            validate: false,
          },
        },
        Math: mathTex,
        code: editorCodeTool,
        audio: {
          class: AudioTool,
          config: {
            uploader: {
              uploadByFile(file: File) {
                return firebase_service["cloud"]
                  .uploadFileToCloud("experience", file)
                  .then((url) => {
                    return {
                      success: 1,
                      file: {
                        url,
                      },
                    };
                  });
              },
            },
          },
        },
        video: {
          class: CustomVideoAudioTool as any,
          config: {
            uploader: {
              uploadByFile(file: File) {
                return uploadVideoToCloud("experience", file).then(
                  ({ url, metadata }: any) => {
                    return {
                      success: 1,
                      file: {
                        url,
                        metadata,
                      },
                    };
                  }
                );
              },
              uploadByUrl(url: string) {
                return new Promise((resolved, rejected) => {
                  resolved({
                    success: 1,
                    file: {
                      url,
                    },
                  });
                });
              },
            },
          },
        },

        table: {
          class: editorTableTool,
          inlineToolbar: true,
          config: {
            rows: 2,
            cols: 3,
            withHeadings: true,
          },
        },
        AssignmentModel1: {
          class: AssignmentModel1Tool,
        },
        AssignmentModel2: {
          class: AssignmentModel2Tool,
        },
        embed: {
          class: Embed,
        },
        RawTool:RawTool
      },
    });
  };
  if (!editableMode)
    return (
      <ConvertJsonToHtml editorjs_string={initialData} dataClass={dataClass} />
    );
  return <div id={element} className="code-editor-style" />;
};

export const ConvertJsonToHtml = ({
  editorjs_string,
  dataClass,
}: {
  editorjs_string: string;
  dataClass?: string;
}) => {
  if (editorjs_string === "") return <div></div>;
  const editorjs_content: OutputData = JSON.parse(editorjs_string);
  let convertedHTML = "";

  console.log("output data: ", editorjs_content.blocks);

  editorjs_content["blocks"].forEach((block, index) => {
    switch (block["type"]) {
      case "paragraph":
        if (block?.tunes?.textVariant == "citation") {
          convertedHTML += `<p><em>${edjsParser.parseBlock(block)}</em></p>`;
        } else {
          convertedHTML += `<div">${edjsParser.parseBlock(block)}</div>`
        }
        break;
      case "paragraphAlign":
        convertedHTML += `<p style="text-align: ${block["data"]["alignment"]}" >${block["data"]["text"]}</p>`;
        break;
      case "quote":
        convertedHTML += `<div class="citactions-border-sec my-4">
        <div class="card">
          <div class="card-head ">
            <div>
              <img src=${Message} alt="" />
            </div>
            <h3 class="mb-0">${block.data.caption}</h3>
          </div>
          <div class="card-section">
            <p>
              ${block.data.text}
            </p>
          </div>
        </div>
      </div>`;
        break;
      case "list":
        convertedHTML += edjsParser.parseBlock(block);
        break;
      case "FileList":
        let lsType = block["data"]["style"] == "ordered" ? "ol" : "ul";
        convertedHTML += "<" + lsType + ">";
        block["data"]["items"].forEach((item: any, index: number) => {
          convertedHTML += "<li>" + item + "</li>";
        });
        convertedHTML += "</" + lsType + ">";
        break;
      case "nestedList":
        let nsLsType = block["data"]["style"] == "ordered" ? "ol" : "ul";
        convertedHTML += "<" + nsLsType + ">";
        block["data"]["items"].forEach((item: any, index: number) => {
          convertedHTML += "<li>" + item.content + "</li>";
        });
        convertedHTML += "</" + nsLsType + ">";
        break;

      case "Math": {
        // var html = katex.renderToString("c = \\pm\\sqrt{a^2 + b^2}", );
        // const rawString=
        try {
          var html = katex.renderToString(`${block["data"]["text"]}`);
          convertedHTML += `<div class="katex my-4 d-flex justify-content-center">${html}</div>`;
          break;
        } catch (error) {
          convertedHTML += `<div>${error}</div>`;
          break;
        }
        // var html = katex.renderToString(`\begin  f(x) &= x^2\\   g(x) &= \frac{1}{x}\\   F(x) &= \int^a_b \frac{1}{3}x^3 \end`);
      }
      case "code":
        convertedHTML +=
          '<br/><pre class="bg-white rounded-4 p-3"><code class="language-' +
          block["data"]["lang"] +
          '">' +
          block["data"]["code"] +
          "</code></pre>";
        break;
      case "table":
        convertedHTML += ReactDOMServer.renderToString(
          <TableWithHead data={block.data.content} />
        );
        break;
      case "TextVariant":
        convertedHTML += "";
        break;
      case "embed":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomIFrame data={block.data} />
        );
        break;
      case "image":
        convertedHTML += `<div class="pic-sec">
        <div class="image-card mx-auto">
          <img src="${block["data"]["file"]["url"]}" class="img-fluid" alt="Frog" />
          <div class="card-body">
            <p class="mb-0">${block["data"]["caption"]}</p>
          </div>
        </div>
      </div>`;
        break;
      case "mermaid":
        convertedHTML += ReactDOMServer.renderToString(
          <MermaidViewer data={block.data} />
        );
        useEffect(() => {
          convertedHTML += ReactDOMServer.renderToString(
            <MermaidViewer data={block.data} />
          );
        }, []);

        break;
      case "audio":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomAudioView data={block.data} />
        );
        break;
      case "video":
        if (block.data.file.metadata?.contentType == "audio/mpeg") {
          const reactAudioPlayerComponent = <CustomAudio data={block.data} />;
          convertedHTML += ReactDOMServer.renderToString(
            reactAudioPlayerComponent
          );
        } else {
          convertedHTML += ReactDOMServer.renderToString(
            <CustomVideoPlayer data={block.data} />
          );
        }
        break;
      /////////////////custom//////////////
      case "CustomBox":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomBoxViewComp data={block.data} />
        );
        break;
      case "CustomQuoteBox":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomQuoteBoxView data={block.data} />
        );
        break;
      case "CustomCard":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomCardView data={block.data}></CustomCardView>
        );
        break;
      case "CustomImage":
        convertedHTML += ReactDOMServer.renderToString(
          <CutomImageComponentView data={block.data} />
        );
        break;

      case "AssignmentModel1":
        convertedHTML += ReactDOMServer.renderToString(
          <AssignmentModel1View data={block.data} />
        );
        break;

      case "AssignmentModel2":
        convertedHTML += ReactDOMServer.renderToString(
          <AssignmentModel2View data={block.data} />
        );
        break;
      case "subtitle":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomSubtitleView
            data={block.data}
            alignment={block?.tunes?.AlignmentTool.alignment}
          />
        );
        break;
      case "heading":
        convertedHTML += ReactDOMServer.renderToString(
          <CustomHeadingView
            data={block.data}
            alignment={block?.tunes?.AlignmentTool.alignment}
          />
        );
        break;
        case "title":
          convertedHTML += ReactDOMServer.renderToString(
            <CustomTitleView
              data={block.data}
              alignment={block?.tunes?.AlignmentTool.alignment}
            />
          );
          break;        
          case "RawTool":
            convertedHTML +=`<div style="margin-top:20px"> ${ block.data.html}</div>`
          break;
      default:
        break;
    }
  });

  return (
    <div>
      {/* {dataClass=="bg-section-data"?<h2>
        <img src={BookIcon} alt="" />
        Background
      </h2>:""} */}
      <div
        className={dataClass}
        dangerouslySetInnerHTML={{
          __html: `${
            dataClass == "exp-background"
              ? convertedHTML.slice(0, 130)
              : convertedHTML
          } ${
            dataClass == "exp-background" && convertedHTML.length > 130
              ? " ..."
              : ""
          }`,
        }}
      ></div>
    </div>
  );
};
