import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HomeIcon from "../assets/images/home.svg";
import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch, useAppSelector } from "../redux";

import { useNavigate } from "react-router-dom";
import {
  closeAccountVerificationLinkSentConfirmationDialog,
  showAccountVerificationLinkSentDailog,
} from "../redux/features/Dialog/AccountVerificationDialogSlice";
import { sendEmailVerification, signOut } from "firebase/auth";
import { firebase_auth } from "../firebase";
import { showAlertCommonDialog } from "../redux/features/Dialog/AlertCommonDialogSlice";
import { resetAuthorInExperience } from "../redux/features/filterAndSearchData/FilterSearchExperienceSlice";
interface Iprops {
  open: boolean;
}
export const AccountVerificationLinkSentConfirmationDialog = ({
  open,
}: any) => {
  const dispatch = useAppDispatch();
  const { isAccountVerificationLinkSentConfirmationDialog } = useAppSelector(
    (s) => s.accountVerificationDialog
  );
  const navigate = useNavigate();
  const onClose = () => {
    dispatch(closeAccountVerificationLinkSentConfirmationDialog());
    signOut(firebase_auth);
    dispatch(resetAuthorInExperience());
  };

  return (
    <Modal
      show={isAccountVerificationLinkSentConfirmationDialog}
      onHide={onClose}
      keyboard={false}
      id="loginModal"
    >
      <div className="modal-forgotpassword">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Account Verification</h1>
            <p className="model-header-text">
              We're sorry, but your account is not verified. Please verify your
              account before login.
            </p>
          </div>
          <form id="forgotemailsubmit">
            <div className="form mt-4">
              <button
                className="submit-btn"
                onClick={async () => {
                  dispatch(
                    closeAccountVerificationLinkSentConfirmationDialog()
                  );
                  try {
                    firebase_auth.currentUser &&
                      (await sendEmailVerification(firebase_auth.currentUser));
                    await signOut(firebase_auth);
                    dispatch(resetAuthorInExperience());
                    dispatch(
                      closeAccountVerificationLinkSentConfirmationDialog()
                    );
                    dispatch(showAccountVerificationLinkSentDailog());
                  } catch (error) {
                    await signOut(firebase_auth);
                    dispatch(showAlertCommonDialog({title:"link sent failed",paragraph:"To many attempts try later",buttonText:"ok"}))    
                  }
                }}
              >
                <p>Send verification link</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
