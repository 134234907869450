import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firebase_service, ICongnitiveType } from "../../firebase";
import { RootState } from "../AppStore";

export const fetchCongnitiveTypeListThunk = createAsyncThunk<ICongnitiveType[],void>(
  "congnitive_type/fetchCongnitiveTypeListThunk",
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await firebase_service["doc"].getAllDocuments(
        "congnitive_type"
      );
      return data.hits?.reverse() && data.hits.length > 0 ? (data.hits?.map((doc) => doc.document) as ICongnitiveType[])
        : [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewCognitiveThunk = createAsyncThunk<any, any>(
  "cognitive/addNewCognitiveThunk",
  async (detail, { rejectWithValue }) => {
    try {
      const data = await firebase_service["congnitive_type"].createCognitiveDoc(detail);
      return { ...(detail as any), id: data as any } as any;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteCognitiveThunk = createAsyncThunk<any, string>(
  "cognitive/deleteCognitiveThunk",
  async (id: any, { rejectWithValue }) => {
    try {
      await firebase_service["congnitive_type"].deleteCognitiveDoc(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCognitiveThunk = createAsyncThunk<
  string,
  { id: string; updatedText: string }
>(
  "cognitive/updateCognitiveThunk",
  async ({ id, updatedText }, { rejectWithValue }) => {
    try {
      await firebase_service["congnitive_type"].updateCognitiveDoc(id,updatedText);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
  

export interface ICongnitiveTypeReducer {
  data: ICongnitiveType[];
  status: "pending" | "fulfilled" | "rejected";
  error: any;
}

const initialState: ICongnitiveTypeReducer = {
  data: [],
  status: "pending",
  error: null,
};

export const congnitiveTypeSlice = createSlice({
  name: "congnitiveType",
  initialState,
  reducers: {
    cognitiveArray: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCongnitiveTypeListThunk.pending, (state) => {
      return { ...state, status: "pending", data: [], error: null };
    });
    builder.addCase(
      fetchCongnitiveTypeListThunk.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          status: "fulfilled",
          data: action.payload,
          error: null,
        };
      }
    );
    builder.addCase(fetchCongnitiveTypeListThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", data: [], error: action.error };
    });
    builder.addCase(addNewCognitiveThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });
    builder.addCase(addNewCognitiveThunk.fulfilled, (state, action) => {
      state.data.push(action.payload);
      return { ...state, status: "fulfilled", error: null };
    });
    builder.addCase(addNewCognitiveThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.error };
    });
    builder.addCase(deleteCognitiveThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });

    builder.addCase(deleteCognitiveThunk.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.data = state.data.filter((item) => item.id !== action.payload);
    });

    builder.addCase(deleteCognitiveThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.payload };
    });
  },
});

export const congnitiveTypeList = (state: RootState) => state.congnitiveType.data;

export default congnitiveTypeSlice.reducer;
