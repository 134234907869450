import React from "react";
import { CustomCardIcon } from "./SvgStrings";
import { CustomCard } from "../../../pages/experience/components/codeEditorhtmlComponents/CustomCard";
import ReactDOMServer from "react-dom/server";

interface ICustomCardTool {
  data?: {
    cardTitle: string;
    cardFrontText: string;
    cardBackText: string;
  };
  wrapper?: any;
  config?: any;
  api?: any;
}
export class CustomCardTool {
  wrapper: ICustomCardTool["wrapper"];
  data: ICustomCardTool["data"];
  api: any;
  constructor({ data, api }: ICustomCardTool) {
    this.data = data;
    this.api = api;
  }
  static get toolbox() {
    return {
      title: "Custom Card",
      icon: CustomCardIcon,
    };
  }
  render() {
    // function limitMessage(e: any) {
    //   const currentHtmlData=e.target.innerHTML.toString()
    //  console.log("key press",currentHtmlData)
    // }

    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(<CustomCard />);
    if (this.data?.cardTitle) {
      this.wrapper.querySelector("#tool-card-title").value =
        this.data.cardTitle;
      this.wrapper.querySelector("#tool-card-front-text").innerHTML =
        this.data.cardFrontText;
      this.wrapper.querySelector("#tool-card-back-text").innerHTML =
        this.data.cardBackText;
    }
    // this.wrapper
    //   .querySelector("#tool-card-front-text")
    //   .addEventListener("keypress", limitMessage);
    console.log("data on render ", this.wrapper.innerHTML);

    return this.wrapper;
  }

  save(blockContent: any) {
    const cardTitle = blockContent.querySelector("#tool-card-title")?.value;
    const cardFrontText = blockContent.querySelector(
      "#tool-card-front-text"
    )?.innerHTML;
    const cardBackText = blockContent.querySelector(
      "#tool-card-back-text"
    )?.innerHTML;

    return {
      cardTitle,
      cardFrontText,
      cardBackText,
    };
  }
}
