import { createSlice } from "@reduxjs/toolkit";

export const ForgotPasswordDialogSlice = createSlice({
  name: "ForgotDialog",
  initialState: {
    isForgotPasswordDialog: false,
    isRecoverPasswordLinkSentDialog:false,
    isPasswordResetLinkExpireDialog:false,
    currentId: "",
    actionState: "",
  },
  reducers: {
    showForgotPasswordDailog: (s) => {
      s.isForgotPasswordDialog = true;
      return s;
    },
    closeForgotPasswordDailog: (s) => {
      s.isForgotPasswordDialog = false;
      return s;
    },
    ///recovery link /////
    showRecoverPasswordLinkSentDialog: (s) => {
        s.isRecoverPasswordLinkSentDialog = true;
        return s;
      },
      closeRecoverPasswordLinkSentDialog: (s) => {
        s.isRecoverPasswordLinkSentDialog = false;
        return s;
      },

      //////recovery link expire ///////
      showPasswordResetLinkExpireDialog:(s)=>{
        s.isPasswordResetLinkExpireDialog=true
      },
      closePasswordResetLinkExpireDialog:(s)=>{
        s.isPasswordResetLinkExpireDialog=false
      }
  },
});

export const { showForgotPasswordDailog, closeForgotPasswordDailog,showRecoverPasswordLinkSentDialog,closeRecoverPasswordLinkSentDialog,showPasswordResetLinkExpireDialog,closePasswordResetLinkExpireDialog } = ForgotPasswordDialogSlice.actions;

export const ForgotPasswordDialogReducer = ForgotPasswordDialogSlice.reducer;
