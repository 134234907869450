import Pen from "../../../assets/images/pen.svg";
import Add from "../../../assets/images/add.svg";
import Edit from "../../../assets/images/edit.svg";
import Delete from "../../../assets/images/delete.svg";
import { deleteExperienceTypeThunk } from "../../../redux/reducers/ExperienceTypeReducer";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useEffect, useState } from "react";
import AddDialog from "../../../dialogs/consolePageDialogs/AddDialog";
import EditDialog from "../../../dialogs/consolePageDialogs/EditDialog";
import DeleteDialog from "../../../dialogs/consolePageDialogs/DeleteDialog";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";
import { CommonDialog } from "../../../dialogs/CommonDialog";

const ExperienceTable = () => {
  const experienceData = useAppSelector(
    (state: any) => state.experienceType.data
  );

  const [addExperienceDialog, setaddExperienceDialog] = useState(false);
  const [editExperienceDialog, seteditExperienceDialog] = useState(false);
  const [deleteExperienceDialog, setdeleteExperienceDialog] = useState(false);

  const [selectedId, setselectedId] = useState();

  const [experienceArray, setexperienceArray] = useState<any>([]);
  const [selectedText, setSelectedText] = useState("");

  const dispatch = useAppDispatch();

  const handleDeleteExpArr = (id: number | any) => {
    setselectedId(id);
    setdeleteExperienceDialog(true);
    dispatch(showDailog({title:"Delete Experience Domain!",id}))
  };

  const handleAddExperience = () => {
    setaddExperienceDialog(true);
  };

  const onClose = () => {
    setaddExperienceDialog(false);
  };

  const handleEditExperienceArr = (id: number | any) => {
    seteditExperienceDialog(true);
    setselectedId(id);
    console.log("id", selectedId);
    const selectedExperience = experienceArray.find(
      (exp: any) => exp.id === id
    );

    if (selectedExperience) {
      setSelectedText(selectedExperience.title);
      console.log(selectedText);
    }

    seteditExperienceDialog(true);
    setselectedId(id);
  };

  useEffect(() => {
    setexperienceArray(experienceData);
  }, [experienceData]);

  return (
    <>
      {addExperienceDialog && (
        <AddDialog
          open={true}
          type="experience"
          experienceArray={experienceArray}
          setexperienceArray={setexperienceArray}
          onClose={() => setaddExperienceDialog(false)}
        />
      )}

      {editExperienceDialog && (
        <EditDialog
          open={true}
          type="experience"
          selectedID={selectedId}
          selectedText={selectedText}
          experienceArray={experienceArray}
          setexperienceArray={setexperienceArray}
          onClose={() => seteditExperienceDialog(false)}
        />
      )}

      
      <div className="section">
        <div className="sec mb-3">
          <h2>
            <img src={Pen} alt="" />
            Experience
            <img
              src={Add}
              onClick={handleAddExperience}
              style={{ marginLeft: "1%", cursor: "pointer" }}
              alt=""
            />
          </h2>
        </div>
        <table className="table-sec table mb-1" style={{ width: "33%" }}>
          <thead>
            <tr>
              <th>
                <h3>Experience</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {experienceArray.map(
              (experience: { id: number | any; title: any }, index: number) => {
                return (
                  <tr key={index}>
                    <td style={{ display: "flex",width:"220px" }}>
                      <p className="mb-0" style={{ width: "90%" }}>
                        {experience.title}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          cursor: "pointer",
                          width:"20%"
                        }}
                      >
                        <img
                          src={Edit}
                          style={{ transform: "rotate(90deg)" }}
                          onClick={() => handleEditExperienceArr(experience.id)}
                        />
                        <img
                          src={Delete}
                          onClick={() => handleDeleteExpArr(experience.id)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ExperienceTable;
