import { useDispatch } from "react-redux";
import { setExperiencesFilter } from "../../redux/features/filterAndSearchData/FilterSearchExperienceSlice";
import { AddExperienceButton, AppCategories, SearchBar } from "./components";
import ExperienceSection from "./components/ExperienceSection";
import FilterSection from "./components/FilterSection";
import "./components/Styles.css";
export const HomePage = () => {
  return (
    <>
      <div>
        <section className="main-section container-fluid ">
          <SearchBar />
          <AddExperienceButton />
          <h2 className="my-3 mx-1 mt-5">Categories</h2>
          <AppCategories />

          <section className="d-flex pt-sm-3 gap-3">
            <FilterSection />
            <ExperienceSection />
          </section>
        </section>

        <footer className="container py-2 start-50 translate-middle-x">
          <div className="text-center">
            <h3 className="mb-0">
            Graphic Design &amp; illustrations by <a href="mailto:elenamolinari.mail@gmail.com">Elena Molinari</a>
            </h3>
          </div>
        </footer>
      </div>
    </>
  );
};
