import { CollectionCreateSchema } from "typesense/lib/Typesense/Collections";
import {
  COLLECTION_AUTHOR,
  COLLECTION_CONGNITIVE_TYPE,
  COLLECTION_EXPERIENCE,
  COLLECTION_EXPERIENCE_TYPE,
  COLLECTION_GRADE,
  COLLECTION_SUBJECT,
} from "./Interfaces";

const AuthorSchema: CollectionCreateSchema = {
  name: COLLECTION_AUTHOR,
  fields: [
    { name: "firstName", type: "string", sort: true },
    { name: "lastName", type: "string" },
    { name: "email", type: "string", index: true },
    { name: "country", type: "string" },
    { name: "singInProvider", type: "string" },
    { name: "simpleUser", type: "bool" },
    { name: "adminUser", type: "bool" },
    { name: "active", type: "bool" },
  ],
  default_sorting_field: "firstName",
};

export const SubjectSchema: CollectionCreateSchema = {
  name: COLLECTION_SUBJECT,
  fields: [
    { name: "name", type: "string", index: true, sort: true },
    { name: "image", type: "string" },
    { name: "logo", type: "string" },
    { name: "instances", type: "string[]" },
  ],
  default_sorting_field: "name",
};

export const GradeSchema: CollectionCreateSchema = {
  name: COLLECTION_GRADE,
  fields: [{ name: "title", type: "string", index: true, sort: true }],
};

export const CognitiveTypeSchema: CollectionCreateSchema = {
  name: COLLECTION_CONGNITIVE_TYPE,
  fields: [{ name: "title", type: "string", index: true, sort: true }],
};

export const ExperienceTypeSchema: CollectionCreateSchema = {
  name: COLLECTION_EXPERIENCE_TYPE,
  fields: [{ name: "title", type: "string", index: true, sort: true }],
};

export const ExperienceSchema: CollectionCreateSchema = {
  name: COLLECTION_EXPERIENCE,
  fields: [
    { name: "title", type: "string", index: true, sort: true },
    { name: "author", index: true, type: "object",facet:true },
    { name: "subject" , index: true, type: "string[]" },
    { name: "cognitive", type: "string" },
    { name: "experience", type: "string" },
    { name: "grade", type: "string" },
    { name: "background", type: "string" },
    { name: "goal", type: "string" },
    { name: "objectives", type: "string" },
    { name: "outcomes", type: "string" },
    { name: "productDeliverables", type: "string" },
    { name: "timeLine", index: true, type: "object[]" },
    { name: "mainQuestions", type: "string" },
    { name: "assessments", index: true, type: "object[]" },
    { name: "active", type: "bool" },
    { name: "isPublished", type: "bool", index: true },
    { name: "dateCreated", type: "string" },
    { name: "lastUpdate", type: "string" },
  ],
  default_sorting_field: "title",
  enable_nested_fields: true,
};
export const CollectionSchema = {
  [COLLECTION_AUTHOR]: AuthorSchema,
  [COLLECTION_SUBJECT]: SubjectSchema,
  [COLLECTION_GRADE]: GradeSchema,
  [COLLECTION_EXPERIENCE]: ExperienceSchema,
  [COLLECTION_CONGNITIVE_TYPE]: CognitiveTypeSchema,
  [COLLECTION_EXPERIENCE_TYPE]: ExperienceTypeSchema,
};
