// @ts-expect-error
import videoTool from "editorjs-video";
export class CustomVideoAudioTool extends videoTool {
    static get toolbox(){
        return {
            title:"Video",
            icon:`<!-- Svg Vector Icons : http://www.onlinewebfonts.com/icon -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4501,4989.1C2600.9,4792.4,957.3,3477.5,352.1,1669.6C16,656.1,16-424.7,352.1-1448.2C469.2-1804.3,773-2407,984.7-2700.8c844.2-1182.9,2111.8-1925,3538.7-2069.4c1805.5-181.8,3598.5,682.3,4592.1,2216.3c931.4,1436.9,1040.9,3274.7,288.9,4811.2C8923.7,3233.5,8174.1,3993,7202.9,4481.1C6346.2,4909.5,5427.4,5083.8,4501,4989.1z M5380,4767.5c1499.2-124.5,2841.4-948.8,3615.9-2223.8c500.5-824.3,742.1-1837.8,662.4-2759.2c-54.8-632.5-189.2-1135.6-450.7-1678.5c-632.5-1314.9-1803-2228.8-3257.3-2545.1c-398.4-84.7-1148-107.1-1573.9-47.3C2399.2-4200,867.6-2755.6,449.3-785.8C387-501.9,382-424.7,379.5,98.2c0,615.1,15,752.1,139.5,1227.7c393.5,1501.6,1539,2721.9,3023.2,3215C4164.8,4747.6,4747.5,4819.8,5380,4767.5z"/><path d="M3641.8,2192.6c-34.9-49.8-37.4-301.3-32.4-2189c7.5-2054.5,10-2131.7,54.8-2181.5c62.3-69.7,199.2-69.7,311.3,0C4613-1789.4,7048.5-213,7110.8-148.3c89.7,94.6,99.6,164.4,34.9,271.5c-32.4,52.3-505.5,366.1-1613.7,1073.3C4670.3,1746.8,3940.6,2210,3910.7,2224.9C3813.6,2274.7,3684.1,2259.8,3641.8,2192.6z M5340.2,855.3L6667.5,6.1L5355.1-833.1c-724.7-460.7-1329.8-849.2-1344.8-859.2c-24.9-14.9-32.4,331.2-32.4,1688.4c0,938.8,7.5,1708.3,14.9,1708.3C4002.9,1704.5,4608,1323.5,5340.2,855.3z"/></g></g>
            </svg>`
        }
    }
}