//@ts-ignore
import Shortcut from "@codexteam/shortcuts";
import { CustomInlineImageComponent } from "../../../pages/experience/components/codeEditorhtmlComponents/CustomInlineImageComponent";
import ReactDOMServer from "react-dom/server";

export class CustomInlineImageTool {
  button: any;
  state: boolean;
  ImageContainer?: HTMLDivElement;
  api: any;
  selectedText: any;
  image: HTMLImageElement | undefined;
  static get isInline() {
    return true;
  }
  constructor({ api }: any) {
    this.button = null;
    this.state = false;
    this.api = api;
  }

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.style.border = "none";
    this.button.innerHTML = `<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"></path></svg>`;
    this.button.classList.add(this.api?.styles?.inlineToolButton);
    return this.button;
  }

  surround(range: any) {
    if (this.state) {
      // if image is already added
      return;
    }
    this.selectedText = range.extractContents();
    console.log("range object:", range);
    // Create image element
    this.image = document.createElement("img");
    this.image.src = this.selectedText.textContent; 
    this.image.className = "custom-inline-image-view img-fluid";
    // Insert new element
    range.insertNode(this.image);
  }

  checkState(selection: any) {
    // const text = selection.anchorNode;
    // if (!text) {
    //   return;
    // }
    // const anchorElement = text instanceof Element ? text : text.parentElement;
    // // anchorElement.style.border="2px solid red"
    // this.state = !!anchorElement.closest("img");
    // console.log("state",this.state,anchorElement)
  }

  ////////////action part //////////////
  // renderActions() {
  //  this.ImageContainer
  // }
  static get sanitize() {
    return {
      img:{
        class:true,
        src:true
      }
    }
  }
}
