import { createSlice } from "@reduxjs/toolkit";

export const DialogSlice = createSlice({
  name: "Dialog",
  initialState: {
    isDialog: false,
    currentData: {
      type: "none",
      title: "no title",
      description: "no description",
    },
    currentId:"",
    actionState:"",
    data: [
      {
        type: "delete",
        title: "Delete experience!",
        description:
          "You're about to delete this experience, are you sure about that? You won't be able to undo this action",
      },
      {
        type: "delete",
        title: "Delete Cognitive Domain!",
        description: `You're about to delete a Cognitive ; are you sure about
          that? Experiences associated with this Cognitive domain will
          not be deleted and won't be searchable`,
      },
      {
        type: "delete",
        title: "Delete Grade Domain!",
        description: `You’re about to delete a Grade ; are you sure about that? Experiences associated with this Grade domain will not be deleted and won’t be searchable.`,
      },  {
        type: "delete",
        title: "Delete Experience Domain!",
        description: `You’re about to delete a Experience ; are you sure about that? Experiences associated with this Experience domain will not be deleted and won’t be searchable.`,
      },  {
        type: "delete",
        title: "Delete Subject Domain!",
        description: `You’re about to delete a subject domain; are you sure about that? Experiences associated with this subject domain will not be deleted and won’t be searchable.`,
      },
      {
        type: "publish",
        title: "Publish experience!",
        description:
          "You are about to publish this experience page. You can set the experience back to private in case you need to. Click Publish to continue. ",
      },
      {
        type: "unpublish",
        title: "UnPublish experience!",
        description:
          "You are about to unpublish this experience page. You can set the experience back to the public in case you need to. Click UnPublish to continue.",
      },
    ],
  },
  reducers: {
    showDailog: (s, titleAndId:{payload:{title:string,id:any}}) => {
      const {title,id}=titleAndId.payload
      const data = s.data.find((item) => item.title == title);
      if (data != undefined) {
        s.currentData = data;
        s.currentId=id
      } else {
        s.currentData = {
          type: "none",
          title: "no title",
          description: "no description",
        };
        s.currentId =""
      }
      s.isDialog = true;
      return s;
    },
    closeDailog: (s) => {
      s.currentData={
        type: "none",
        title: "no title",
        description: "no description",
      }
      s.currentId =""
      s.isDialog = false;
    },
  },
});

export const { showDailog, closeDailog } = DialogSlice.actions;

export const DialogReducer = DialogSlice.reducer;
