import React, { useState } from "react";
import ArtsIcon from "../../../assets/images/arts.svg";
import BookIcon from "../../../assets/images/book.svg";
import EditIcon from "../../../assets/images/edit.svg";
import HomeIcon from "../../../assets/images/home.svg";
import PlusIcon from "../../../assets/images/plus.svg";
import ArtLogoIcon from "../../../assets/images/art-logo.svg";
import { IAuthor, IExperience, SubjectTuple } from "../../../firebase";
import { Link, useMatch } from "react-router-dom";
import { RootState, useAppSelector } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";
import { CommonDialog } from "../../../dialogs/CommonDialog";
import { useAuthProvider } from "../../../providers";

interface IExperienceHeader {
  id: string;
  title: string;
  authorName: string;
  cognitive: string;
  experience: string;
  subject: SubjectTuple;
  grade: string;
  editableMode: boolean;
  onChange: <T extends IExperience>(
    key: keyof T
  ) => (value: T[keyof T]) => void;
  onAddClick: () => void;
  onEditClick: () => void;
}

export const ExperienceHeader: React.FC<IExperienceHeader> = ({
  id,
  title,
  authorName,
  experience,
  subject,
  grade,
  cognitive,
  editableMode,
  onChange,
  onAddClick,
  onEditClick,
}) => {
  const isAddNewExp = useMatch("/add_new_experience");
  const subjectsList = useAppSelector((state) => state.subjects.data);
  const gradesList = useAppSelector((state) => state.grades.data);
  const congnitiveTypeList = useAppSelector(
    (state) => state.congnitiveType.data
  );

  const experienceTypeList = useAppSelector(
    (state) => state.experienceType.data
  );
  const dispatch = useDispatch();
  const [showControls, setshowControls] = React.useState(true);
  const { dialog } = useSelector((s: RootState) => s);

  const current_sub = subjectsList.find((Subject) => {
    return Subject.name == subject[0];
  });

  const findDefaultSubSubject = (selectedSubject: string) => {
    const current_sub = subjectsList.find((subject) => {
      return subject.name == selectedSubject;
    });
    return current_sub ? current_sub.instances[0] : "";
  };

  const { userProfile } = useAuthProvider();
  const Exps = useAppSelector((s) => s.experiences.data);
  const currentExp = Exps.find((exp) => exp.id == id);
  const onClickBookBtn = async () => {
    dispatch(
      showDailog({
        title: `${
          currentExp
            ? currentExp.isPublished
              ? "UnPublish experience!"
              : "Publish experience!"
            : ""
        }`,
        id,
      })
    );
  };
  const renderMutationButton = () => {
 
    return (
      <>
        <div
          className="plus-btn"
          style={{ display: showControls && !editableMode ? "flex" : "none" }}
          onClick={onAddClick}
        >
          <Link to={""}>
            <img
              className="img-fluid"
              src={PlusIcon}
              style={{ margin: "auto" }}
              alt=""
            />
          </Link>
        </div>
        <div
          className="book-btn"
          style={{
            display: showControls ? "flex" : "none",
            backgroundColor: `${
              currentExp ? (currentExp.isPublished ? "white" : "#C9C9C9") : ""
            }`,
          }}
          onClick={onClickBookBtn}
        >
          <img
            className="img-fluid"
            src={BookIcon}
            style={{ margin: "auto" }}
            alt=""
          />
        </div>
        <div
          className="pen-btn"
          style={{
            display: showControls ? "flex" : "none",
            backgroundColor: `${!editableMode ? "white" : "#C9C9C9"}`,
          }}
          onClick={onEditClick}
        >
          <img
            className="img-fluid"
            src={EditIcon}
            style={{ margin: "auto" }}
            alt=""
          />
        </div>
      </>
    );
  };
  return (
    <header className="container-fluid" style={{ position: "relative" }}>
      {dialog.isDialog ? <CommonDialog /> : ""}
      <div className="exp_header">
        <div className="d-flex align-items-center justify-content-center">
          {/* ////////Action button//////// */}
          <div className="all-btn text-center">
            <div
              className="main-btn d-flex"
              onClick={() => setshowControls(!showControls)}
            >
              <img
                className="img-fluid m-auto"
                src={current_sub?.image}
                style={{ maxWidth: "100%" }}
                alt=""
              />
            </div>
            {userProfile!=null && userProfile?.email == currentExp?.author.email
              ? renderMutationButton()
              : userProfile?.adminUser
              ? renderMutationButton()
              : ""}
            <div
              className="home-btn"
              style={{ display: showControls ? "flex" : "none" }}
            >
              <Link to="/">
                {" "}
                <img
                  className="img-fluid"
                  src={HomeIcon}
                  style={{ margin: "auto" }}
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
        {/* /////////action button end /////////// */}
        <div className="header-title">
          <div className="">
            {editableMode ? (
              <h1>
                <textarea
                  className="experienc_titleField w-100"
                  value={title}
                  placeholder="Enter title here"
                  onChange={(e) => onChange("title")(e.target.value)}
                />
              </h1>
            ) : (
              <h1 style={{ maxWidth: "60vw" }}>{title}</h1>
            )}
          </div>
          <div>
            <img src={current_sub?.logo} className="main-img" alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-lg-3 mb-4 mb-lg-0">
            <div className="text-center">
              <h3>author</h3>
              <Link to="/profile">{authorName}</Link>
            </div>
          </div>
          <div className="col-6 col-lg-3 mb-4 mb-lg-0">
            <div className="text-center">
              <h3>EXPERIENCE</h3>

              {editableMode ? (
                <div className="experienc_subject_field_wrapper d-flex flex-wrap">
                  <select
                    className="experienc_congnitive_Field"
                    value={cognitive}
                    onChange={(e) => onChange("cognitive")(e.target.value)}
                  >
                    {congnitiveTypeList.map((cong, index) => {
                      return (
                        <option
                          aria-selected="true"
                          key={index}
                          value={cong.title}
                        >
                          {cong.title}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="experienc_congnitive_Field"
                    value={experience}
                    onChange={(e) => onChange("experience")(e.target.value)}
                  >
                    {experienceTypeList.map((exp, index) => {
                      return (
                        <option
                          aria-selected="true"
                          key={index}
                          value={exp.title}
                        >
                          {exp.title}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <p>{`${cognitive}:${experience}`}</p>
              )}
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="text-center">
              <h3>Subject</h3>
              {editableMode ? (
                <div className="experienc_subject_field_wrapper d-flex flex-wrap">
                  <select
                    className="experienc_congnitive_Field"
                    value={subject[0]}
                    onChange={(e) =>
                      onChange("subject")([
                        e.target.value,
                        findDefaultSubSubject(e.target.value),
                      ])
                    }
                  >
                    {subjectsList.map((data, index) => {
                      return (
                        <option
                          aria-selected="true"
                          key={index}
                          value={data.name}
                        >
                          {data.name}
                        </option>
                      );
                    })}
                  </select>
                  <select
                    className="experienc_congnitive_Field"
                    disabled={subject[0].length === 0}
                    value={subject[1]}
                    onChange={(e) =>
                      onChange("subject")([subject[0], e.target.value])
                    }
                  >
                    {subjectsList
                      .find((item) => item.name === subject[0])
                      ?.instances.map((instance, index) => {
                        return (
                          <option
                            aria-selected="true"
                            key={index}
                            value={instance}
                          >
                            {instance}
                          </option>
                        );
                      })}
                  </select>
                </div>
              ) : (
                <p>{`${subject[0]}:${subject[1]}`}</p>
              )}
            </div>
          </div>
          <div className="col-6 col-lg-3">
            <div className="text-center ">
              <h3>grade</h3>

              {editableMode ? (
                <select
                  className="experienc_congnitive_Field"
                  value={grade}
                  onChange={(e) => onChange("grade")(e.target.value)}
                >
                  {gradesList.map((grade, index) => {
                    return (
                      <option key={`${index}-${grade}`} value={grade.title}>
                        {grade.title}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <p>{grade}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
