import ReactDOMServer from "react-dom/server";
import { CustomAudioComponent } from "../../../pages/experience/components/codeEditorhtmlComponents/CustomAudioComponent";
interface IProps {
  data?: {
    file: {
      url: string;
    };
    caption: string;
  };
  api: any;
  config?: any;
}
export class AudioTool {
  wrapper: HTMLDivElement | undefined;
  data: IProps["data"];
  config: any;
  constructor({ data, config }: IProps) {
    this.data = data;
    this.config = config;
  }
  static get toolbox() {
    return {
      title: "Audio",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="79.458" height="105.79" viewBox="0 0 79.458 105.79">
        <path id="Music-Icon-SVG-psllmvfd" d="M21.507,27.126V78.782a14.7,14.7,0,0,0-7.526-1.437A14.965,14.965,0,1,0,29.922,92.26V45.051a2.733,2.733,0,0,1,1.71-2.463L68.716,28.357a1.624,1.624,0,0,1,2.258,1.574V60.446a14.567,14.567,0,0,0-7.526-1.437,14.968,14.968,0,0,0,.958,29.9A15.2,15.2,0,0,0,79.457,73.582V7.079a5.6,5.6,0,0,0-7.526-5.268L26.912,19.258a8.467,8.467,0,0,0-5.405,7.868" transform="translate(0 -1.454)"/>
      </svg>`,
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(
      <CustomAudioComponent data={this.data} />
    );
    const audioPlayer = this.wrapper.querySelector(
      "#custom-audio-tool-player"
    ) as HTMLAudioElement;

    const inputUrl = this.wrapper.querySelector(
      "#input-audio-url"
    ) as HTMLInputElement;
    const inputFile = this.wrapper.querySelector(
      "#input-audio-file"
    ) as HTMLInputElement;
    const uploadMessage = this.wrapper.querySelector(
      "#custom-audio-upload-message"
    ) as HTMLDivElement;
    ///apply event////
    inputUrl?.addEventListener("paste", (event: any) => {
      console.log("onPaste ", event.target.value);
      audioPlayer.src = event.clipboardData.getData("text");
      inputFile.value = "";
    });
    ////////upload file on firebase and get url//////
    inputFile.addEventListener("change", async (event: any) => {
      const ImageFile = event.target.files[0];
      if (ImageFile) {
        audioPlayer.src = "";
        uploadMessage.style.display = "flex";
        const uploadedFile = await this.config.uploader.uploadByFile(ImageFile);
        inputUrl.value = uploadedFile.file.url;
        audioPlayer.src = uploadedFile.file.url;
        uploadMessage.style.display = "none";
      }
    });
    return this.wrapper;
  }

  save(blockContent: HTMLDivElement) {
    const url = blockContent.querySelector(
      "#input-audio-url"
    ) as HTMLInputElement;
    const caption = blockContent.querySelector(
      "#custom-audio-caption"
    ) as HTMLInputElement;
    return {
      file: {
        url: url.value,
      },
      caption: caption.value,
    };
  }
}
