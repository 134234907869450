import React from 'react'

const PicSection = () => {
  return (
    <>
        <div className="pic-sec">
            <div className="image-video-card mx-auto" >
              <video
                controls
                src="https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"
                poster="https://peach.blender.org/wp-content/uploads/title_anouncement.jpg?x11217"
                width={620}
              >
                Sorry, your browser doesn't support embedded videos, but don't
                worry, you can{" "}
                <a href="https://archive.org/details/BigBuckBunny_124">
                  download it
                </a>{" "}
                and watch it with your favorite video player!{" "}
              </video>
              <div className="card-body">
                <p className="mb-0">Video Caption.</p>
              </div>
            </div>
          </div>
          <div className="pic-sec">
            <div className="image-video-card mx-auto">
              <iframe
                height={240}
                src="https://www.youtube.com/embed/O_qNesMOf-Y"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              />
              <div className="card-body">
                <p className="mb-0">Video Caption.</p>
              </div>
            </div>
          </div>
          <div className="pic-sec">
            <div className="image-card mx-auto">
              <div className="player ">
                <div className="toggle-play play" />
                <div className="time">
                  <p className="current">00:00</p>
                  <p className="divider">/</p>
                  <p className="length" />
                </div>
                <div className="timeline">
                  <div className="progress" />
                </div>
                <div className="volume-button volume"> </div>
              </div>
              <div className="card-body">
                <p className="mb-0">Audio Caption.</p>
              </div>
            </div>
          </div>
    </>
  )
}

export default PicSection
