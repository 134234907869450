import React, { useMemo } from "react";
import { useAppDispatch } from "../../redux";

import {
  setobbCodeForNewPassword,
  showCreateNewPasswordDialog,
} from "../../redux/features/Dialog/CreateNewPasswordDialogSlice";
import { useLocation } from "react-router-dom";
import { applyActionCode, verifyPasswordResetCode } from "firebase/auth";
import { firebase_auth } from "../../firebase";
import { showPasswordResetLinkExpireDialog } from "../../redux/features/Dialog/ForgotPasswordDialogSlice";
import { setobbCodeForAccountVerification, showAccountVerificationLinkExpireDialog, showAccountVerifiedSuccessDialog } from "../../redux/features/Dialog/AccountVerificationDialogSlice";

export const AuthActions = () => {
  const dispatch = useAppDispatch();
  //received query parameter from firbase
  const location = useLocation();
  const searchObj = new URLSearchParams(location.search) as any;
  const obbCode = searchObj.get("oobCode");
    /////code checking //////
    const CheckoobCode = async () => {
      if (obbCode && obbCode.length > 0) {
        try {
          await verifyPasswordResetCode(firebase_auth, obbCode);
          return true 
         
        } catch (error) {
  
          return false
         
        }
      }
    };
    const performAction=async()=>{
      const isoobCodeValid= await CheckoobCode()
      switch (searchObj.get("mode")) {
        case "resetPassword":
          if(isoobCodeValid){
            dispatch(setobbCodeForNewPassword(obbCode));
            dispatch(showCreateNewPasswordDialog());
          }
          else{
            dispatch(showPasswordResetLinkExpireDialog());
          }
          break;
       case "verifyEmail":
        if(isoobCodeValid){
          await applyActionCode(firebase_auth,obbCode)
          dispatch(setobbCodeForAccountVerification(obbCode));

          dispatch(showAccountVerifiedSuccessDialog())
        }
        else{
          dispatch(showAccountVerificationLinkExpireDialog());
        }
          
          break;
        default:
          break;
      }
    }

  useMemo(() => performAction(), [obbCode]);


  return <div>
  </div>;
};
