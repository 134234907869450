import React from "react";

export const CustomAudioComponent = ({ data }: any) => {
  return (
    <div className="custom-audio-tool-container p-3">
      {/* ////audio player///// */}
      <div className="pic-sec w-100 custom-audio-container">
        <div className="image-card mx-auto mb-2">
          <div className="player p-0 ">
            <audio
              controls
              src={data.file?.url}
              style={{
                width: "100%",
                borderRadius: "inherit",
                backgroundColor: "#f8f9fa",
              }}
              id="custom-audio-tool-player"
            >
              <a href={data.file?.url}>download it</a>
            </audio>
          </div>
          <div className="card-body">
            <p className="mb-0 p-0 ">
              <input
                type="text"
                className="w-100"
                id="custom-audio-caption"
                value={data.caption}
                style={{ border: "none" }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center">
        <h3 id="custom-audio-upload-message" style={{ color: "orange",display:"none" }}>
          Uploading . . .
        </h3>
      </div>

      {/* //////add audio///// */}
      <input
        type="text"
        className=" d-flex rounded-3 mx-auto p-1"
        id="input-audio-url"
        placeholder="Enter audio url"
        value={data.file?.url}
      />
      <div>
        <input
          type="file"
          className="p-1 rounded-3 d-flex mx-auto mt-2"
          id="input-audio-file"
          style={{ border: "1px solid black", maxWidth: "210px" }}
        />
      </div>
    </div>
  );
};

export const CustomAudioView = ({ data }: any) => {
  return (
    <div className="pic-sec w-100 custom-audio-container">
      <div className="image-card mx-auto">
        <div className="player p-0 ">
          <audio
            controls
            src={data.file.url}
            style={{
              width: "100%",
              borderRadius: "inherit",
              backgroundColor: "#f8f9fa",
            }}
          >
            <a href={data.file.url}>download it</a>
          </audio>
        </div>
        <div className="card-body">
          <p className="mb-0">{data.caption}</p>
        </div>
      </div>
    </div>
  );
};
