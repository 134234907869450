import React, { useState } from "react";
import { useSelector } from "react-redux";
import WarningIcon from "../assets/images/experience.svg";
import DeleteIcon from "../assets/images/delete.svg";
import {
  deleteCognitiveThunk,
  deleteGradeThunk,
  RootState,
  useAppDispatch,
} from "../redux";
import { closeDailog } from "../redux/features/Dialog/DialogSlice";
import {
  deleteExperienceThunk,
  updateExperienceDocumentThunk,
} from "../redux/reducers/ExperienceReducer";
import { deleteExperienceTypeThunk } from "../redux/reducers/ExperienceTypeReducer";
import { deleteSubjectThunk } from "../redux/reducers/SubjectReducer";

export const CommonDialog = () => {
  const dispatch = useAppDispatch();
  const { filterSearchExperiences, dialog } = useSelector((s: RootState) => s);

  const { title, description, type } = dialog.currentData;
  const id = dialog.currentId;
  const onCancle = () => {
    dispatch(closeDailog());
  };
  const onConfirm = async () => {
    let res;
    if (title == "Delete experience!") {
      res = await dispatch(deleteExperienceThunk(id));
      dispatch(closeDailog());
      console.log("deleted", res);
    } else if (title == "Publish experience!") {
      const res=await dispatch(updateExperienceDocumentThunk({id,updatedData:{isPublished:true}}))
      console.log("published successfully",res)
      dispatch(closeDailog());

    } else if (title == "UnPublish experience!") {
      const res=await dispatch(updateExperienceDocumentThunk({id,updatedData:{isPublished:false}}))
      console.log("unpublished successfully",res)
      dispatch(closeDailog());
    }

    //////console page ///////
    else if (title == "Delete Grade Domain!") {
      res = await dispatch(deleteGradeThunk(id));
      console.log("deleted", res);
      dispatch(closeDailog());
    } else if (title == "Delete Cognitive Domain!") {
      res = await dispatch(deleteCognitiveThunk(id));
      console.log("deleted", res);
      dispatch(closeDailog());
    } else if (title == "Delete Experience Domain!") {
      res = await dispatch(deleteExperienceTypeThunk(id));
      console.log("deleted", res);
      dispatch(closeDailog());
    } else if (title == "Delete Subject Domain!") {
      res = await dispatch(deleteSubjectThunk(id));
      console.log("deleted", res);
      dispatch(closeDailog());
    } else {
      console.log("title is not matched")
    }
  };

  return (
    <div
      className="modal fade show"
      id="experienceModal"
      tabIndex={-1}
      aria-modal="true"
      role="dialog"
      style={{ display: `${dialog.isDialog ? "block" : "none"}`}}
    >
      <div className="modal-dialog modal-dialog-centered ">
        <div className="modal-content">
          <div className="modal-body">
            <div className="text-center">
              <h1 className="model-header-title">{title}</h1>
              <p className="model-header-text">
                <img
                  src={WarningIcon}
                  alt=""
                  className="d-block m-auto"
                  style={{ maxWidth: "25px" }}
                />
                {description}
              </p>
            </div>
            <div className="row">
              <div className="col-6">
                <button
                  id="signupsubmit"
                  type="submit"
                  className="cancel-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onCancle}
                >
                  <p>Cancel</p>
                </button>
              </div>
              <div className="col-6">
                <button
                  id="signupsubmit"
                  type="submit"
                  className="delete-btn"
                  onClick={onConfirm}
                >
                  <img
                    src={type == "delete" ? DeleteIcon : ""}
                    alt=""
                    style={{ maxWidth: "25px", marginLeft: "20px" }}
                  />
                  <p style={{ marginRight: "20px" }}>{type}</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
