import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firebase_service, ISubject } from "../../firebase";
import { RootState } from "../AppStore";

export const fetchSubjectsListThunk = createAsyncThunk<ISubject[] | any, void>(
  "subject/fetchSubjectsListThunk",
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await firebase_service["subject"].getAllSubjects("subjet");
      return data.hits && data.hits.length > 0
        ? (data.hits?.map((doc) => doc.document) as ISubject[])
        : [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addSubjectThunk = createAsyncThunk<ISubject | any, ISubject>(
  "subject/addSubjectThunk",
  async (subject: ISubject, { rejectWithValue }) => {
    try {
      const addedSubject = await firebase_service["subject"].createSubjetDoc(
        subject
      );
      return addedSubject as any;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const deleteSubjectThunk = createAsyncThunk<any, string>(
  "subject/deleteSubjectThunk",
  async (id: any, { rejectWithValue }) => {
    try {
      await firebase_service["subject"].deleteSubjectDoc(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateSubjectThunk = createAsyncThunk<
  string,
  { id: string; updatedSubject: {} | any }
>(
  "subject/updateSubjectThunk",
  async ({ id, updatedSubject }, { rejectWithValue }) => {
    try {
      await firebase_service["subject"].updatedSubjetDoc(id, updatedSubject);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export interface ICongnitiveTypeReducer {
  data: ISubject[];
  status: "pending" | "fulfilled" | "rejected";
  error: any;
}

const initialState: ICongnitiveTypeReducer  = {
  data: [],
  status: "pending",
  error: null,
};


export const setDataPresent = createAction<boolean>("subject/setDataPresent");

export const subjectSlice = createSlice({
  name: "subject",
  initialState,
  reducers: {
    subjectArray: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubjectsListThunk.pending, (state) => {
      return { ...state, status: "pending", data: [], error: null };
    });
    builder.addCase(fetchSubjectsListThunk.fulfilled, (state, action: any) => {
      return {
        ...state,
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    });
    builder.addCase(fetchSubjectsListThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", data: [], error: action.error };
    });
  
    builder.addCase(deleteSubjectThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });

    builder.addCase(deleteSubjectThunk.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.data = state.data.filter((item) => item.id !== action.payload);
    });

    builder.addCase(deleteSubjectThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.payload };
    });
    builder.addCase(addSubjectThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });
    builder.addCase(addSubjectThunk.fulfilled, (state, action) => {
      state.data.push(action.payload);
      return { ...state, status: "fulfilled", error: null };
    });
    builder.addCase(addSubjectThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.error };
    });
  },
});

export const subjectsList = (state: RootState) => state.subjects.data;

export default subjectSlice.reducer;
