import React from 'react'
import Arts from "../../../assets/images/arts.svg"
import Book from "../../../assets/images/book.svg"
import Edit from "../../../assets/images/edit.svg"
import Plus from "../../../assets/images/plus.svg"



const ExperienceHeader = () => {
  return (
    <>
        <header className="container-fluid">
          <div className="d-flex align-items-center justify-content-center">
            <div className="all-btn text-center">
              <div className="main-btn">
                <img className="img-fluid" src={Arts} alt="" />
              </div>
              <div className="book-btn">
                <img className="img-fluid" src={Book} alt="" />
              </div>
              <div className="pen-btn">
                <img className="img-fluid" src={Edit} alt="" />
              </div>
              <div className="home-btn">
                <a href="index.html"> <img className="img-fluid" src="assets/images/home.svg" alt="" /></a>
              </div>
              <div className="plus-btn">
                <img className="img-fluid" src={Plus} alt="" />
              </div>
            </div>
          </div>
          <div className=" header-title">
            <div>
              <h1>Title</h1>
            </div>
            <div>
              <img src="assets/images/art-logo.svg" className="main-img" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
              <div className="text-center">
                <h3>author</h3>
                <a href="javascript:void(0)">firstName lastName</a>
              </div>
            </div>
            <div className="col-6 col-lg-3 mb-4 mb-lg-0">
              <div className="text-center">
                <h3>EXPERIENCE</h3>
                <p>cognitive : experience</p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center">
                <h3>Subject</h3>
                <p>subject : subjectSubGroup </p>
              </div>
            </div>
            <div className="col-6 col-lg-3">
              <div className="text-center ">
                <h3>grade</h3>
                <p>grade</p>
              </div>
            </div>
          </div>
        </header>
    </>
  )
}

export default ExperienceHeader
