import React from "react";
import RemoveIcon from "../../../../assets/images/close.svg";
import AddIcon from "../../../../assets/images/addIcon2.svg";
export const NewTableRowModel = ({ model }: any) => {
  if (model == 1) {
    var newInitialData = ["", "", "", "", "", ""];
  } else if (model == 2) {
    var newInitialData = ["", "", "", "", "", "", ""];
  }

  const newRowData = () =>
    newInitialData.map((data, dataIndex) => {
      return (
        <>
          {dataIndex == 0 && model == 2 ? (
            <>
              <td key={dataIndex}>
                <p className="p-0">
                  <textarea
                    value={""}
                    className="model-table-data"
                    style={{ width: "100%", border: "none" }}
                    placeholder="Enter text"
                  />
                </p>
                <div className="model-table-data-help-cover">
                  <textarea
                    value={""}
                    className="model-table-data-help"
                    style={{ width: "100%", height: "150px" }}
                    placeholder="Enter help text"
                  />
                </div>
              </td>
            </>
          ) : (
            <>
              {" "}
              <td key={dataIndex}>
                <p className="p-0">
                  <textarea
                    value={data}
                    className="model-table-data"
                    style={{ width: "100%", border: "none" }}
                    placeholder="Enter text"
                  />
                </p>
              </td>
            </>
          )}
        </>
      );
    });
  return (
    <>
      <td>
        <div>
          <img
            src={RemoveIcon}
            className="model-add-remove-button"
            id="model-table-remove-row-button"
          ></img>
          <img
            src={AddIcon}
            id="model-table-add-row-button"
            className="model-add-remove-button"
          ></img>
        </div>
      </td>

      {newRowData()}
    </>
  );
};
