import React, { useState } from "react";
import { Modal } from "react-bootstrap";

import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch, useAppSelector } from "../redux";

import { useNavigate } from "react-router-dom";

import { closeAlertCommonDialog } from "../redux/features/Dialog/AlertCommonDialogSlice";
interface Iprops {
  open: boolean;
}
export const AlertCommonDialog = ({
  open,
}: any) => {
  const dispatch = useAppDispatch();
  const { isAlertDialog,title,paragraph,buttonText  } = useAppSelector(
    (s) => s.alertCommonDialog
  );
  const navigate = useNavigate();
  const onClose = () => {
    dispatch(closeAlertCommonDialog())
  };
const onOk=(e:any)=>{
    e.preventDefault()
    onClose()
}
  return (
    <Modal
      show={isAlertDialog}
      onHide={onClose}
      keyboard={false}
      id="loginModal"
      style={{top:"-19vh"}}
    >
      <div className="modal-forgotpassword ">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">{title}</h1>
            <p className="model-header-text">
             {paragraph}
            </p>
          </div>
          <form id="forgotemailsubmit">
            <div className="form mt-4">
              <button
                className="submit-btn"
                onClick={onOk}
              >
                <p>{buttonText}</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
