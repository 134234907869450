import mermaid from "mermaid";
import React, { useEffect } from "react";
// @ts-expect-error
import Mermaid from "react-mermaid";
export const MermaidViewer = ({ data }: any) => {
  // console.log("mermaid contentLoaded run in mermaid viewer ::::");
  // mermaid.contentLoaded();

  // // @ts-expect-error
  // mermaid.init(undefined, $("#mermaid"));
  mermaid.init();
  return (
    <div
      className=" d-flex justify-content-center my-3 pic-sec"
      style={{ flexDirection: "column" }}
    >
      <div className="image-card" style={{width:"100% !important "}}>
        <div
          id="mermaid"
          className={`mermaid d-flex justify-content-center mx-auto`}
          style={{ width: "100%" }}
        >
          {data.code}
        </div>
        <div className="card-body">
          <p className="mb-0">{data.caption}</p>
        </div>
      </div>
    </div>
  );
};
