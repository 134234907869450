import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import DeleteIcon from "../../../assets/images/delete.svg";
import Edit from "../../../assets/images/edit.svg";
import Add from "../../../assets/images/add.svg";
import { uploadFileToCloud } from "../../../firebase/services";
import { useAppDispatch } from "../../../redux";
import {
  subjectSlice,
  updateSubjectThunk,
} from "../../../redux/reducers/SubjectReducer";

interface IeditSubjects {
  open: any;
  onClose: any;
  SelectedID: number | any;
  subjectArray: [] | any;
  setsubjectArray: [] | any;
  selectedSubject: any;
}

const EditeditSubjectDialog = ({
  open,
  onClose,
  selectedID,
  subjectArray,
  selectedSubject,
  setsubjectArray,
}: IeditSubjects | any) => {
  const dispatch = useAppDispatch();

  const [currentInstance, setCurrentInstance] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [imgPreview, setimgPreview] = useState(false);
  const [logoPreview, setlogoPreview] = useState(false);

  const [editSubject, seteditSubject] = useState<any>({
    name: selectedSubject.name,
    image: selectedSubject.image,
    logo: selectedSubject.logo,
    instances: selectedSubject.instances,
  });

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    dispatch(
      updateSubjectThunk({ id: selectedID, updatedSubject: editSubject })
    );
    const updatedSubjectArray = subjectArray.map((subject: any) => {
      if (subject.id === selectedID) {
        return {
          ...subject,
          name: editSubject.name,
          image: editSubject.image,
          logo: editSubject.logo,
          instances: editSubject.instances,
        };
      } else {
        return subject;
      }
    });

    const index = updatedSubjectArray.findIndex(
      (subject: any) => subject.id === selectedID
    );
    const updatedSubjectArrayWithNew = [...updatedSubjectArray];
    updatedSubjectArrayWithNew[index] = {
      id: selectedID,
      name: editSubject.name,
      image: editSubject.image,
      logo: editSubject.logo,
      instances: editSubject.instances,
    };

    setsubjectArray(updatedSubjectArrayWithNew);
    dispatch(subjectSlice.actions.subjectArray(updatedSubjectArrayWithNew));
    onClose();
  };

  const handleInputChange = (event: {
    target: { files: any; name?: any; value?: any };
  }) => {
    const { name, value, files } = event.target;

    if (name === "image" || name === "logo") {
      // handle file inputs
      const file = event.target.files[0];
      uploadFileToCloud("images", file)
        .then((downloadURL) => {
          seteditSubject((prevState: any) => ({
            ...prevState,
            [name]: downloadURL,
          }));
        })
        .catch((error) => {
          console.error(error);
          // handle error
        });
    } else if (name === "instances") {
      // handle array inputs
      const arr =
        typeof value === "string"
          ? value.split(",").map((item) => item.trim())
          : [];
      seteditSubject((prevState: any) => ({
        ...prevState,
        instances: arr,
      }));
    } else {
      // handle text inputs
      seteditSubject((prevState: any) => ({
        ...prevState,
        [name]: value === null ? "" : value, // set value to empty string if it's null
      }));
    }
  };

  // Handle adding instances to the array
  const handleAddInstance = () => {
    if (currentInstance.trim() !== "") {
      seteditSubject((prevState: { instances: any }) => ({
        ...prevState,
        instances: [...prevState.instances, currentInstance.trim()],
      }));
      setCurrentInstance("");
    }
  };

  useEffect(() => {
    // console.log("image", editSubject);
    if (editSubject.image) {
      setimgPreview(true);
    } else {
      setimgPreview(false);
    }
  }, [handleInputChange]);

  useEffect(() => {
    // console.log("image", editSubject);
    if (editSubject.logo) {
      setlogoPreview(true);
    } else {
      setlogoPreview(false);
    }
  }, [handleInputChange]);

  const handleDeleteInstance = (id: any) => {
    const updatedInstances = [...editSubject.instances];
    updatedInstances.splice(id, 1);
    seteditSubject({ ...editSubject, instances: updatedInstances });
  };

  function handleEditClick(index: number) {
    setCurrentInstance(editSubject.instances[index]);
    setEditIndex(index);
  }

  function handleEdit(index: number) {
    if (currentInstance && editIndex !== -1) {
      const updatedInstances = [...editSubject.instances];
      updatedInstances[editIndex] = currentInstance;
      const updatededitSubject = {
        ...editSubject,
        instances: updatedInstances,
      };
      seteditSubject(updatededitSubject);
      setCurrentInstance("");
      setEditIndex(-1);
    }
  }

  return (
    <>
      <Modal
        show={open}
        onHide={onClose}
        keyboard={false}
        id="loginModal"
        style={{ marginTop: "-2%" }}
      >
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Edit Subject </h1>
          </div>
          <form onSubmit={handleSubmit} id="loginsubmit">
            <div className="form">
              <label
                htmlFor="loginemail"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "21px",
                }}
              >
                <div style={{ width: "40%" }}>Subject Name</div>
                <input
                  className="form-control"
                  id="textField"
                  type="text"
                  name="name"
                  value={editSubject.name}
                  onChange={handleInputChange}
                  style={{ width: "70%", height: "40px" }}
                />
              </label>

              <label
                htmlFor="loginemail"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "21px",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>Subject image:</div>
                <div className="d-flex">
                  <input
                    id="imageUpload"
                    type="file"
                    name="image"
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                  />

                  <button
                    type="button"
                    className="submit-btn upload-btn"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "15px",
                      border: "1px solid silver",
                      borderRadius: "10px",
                      width: "275px",
                      height: "40px",
                    }}
                    onClick={() => {
                      document.getElementsByName("image")[0].click();
                    }}
                  >
                    Upload Image
                  </button>
                </div>
              </label>

              {imgPreview ? (
                <img
                  src={editSubject.image}
                  style={{
                    width: "200px",
                    height: "60px",
                    marginLeft: "45%",
                    marginTop: "5%",
                  }}
                ></img>
              ) : (
                ""
              )}

              <label
                htmlFor="loginemail"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  fontSize: "21px",
                  marginTop: "3%",
                }}
              >
                <div style={{ width: "40%" }}>Subject logo:</div>
                <div className="d-flex">
                  <input
                    id="imageUpload"
                    type="file"
                    name="logo"
                    onChange={handleInputChange}
                    style={{ display: "none" }}
                  />
                  <button
                    type="button"
                    className="submit-btn upload-btn"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "15px",
                      border: "1px solid silver",
                      borderRadius: "10px",
                      width: "275px",
                      height: "40px",
                    }}
                    onClick={() => {
                      document.getElementsByName("logo")[0].click();
                    }}
                  >
                    Upload Logo
                  </button>
                </div>
              </label>
              {logoPreview ? (
                <img
                  src={editSubject.logo}
                  style={{
                    width: "200px",
                    height: "60px",
                    marginLeft: "45%",
                    marginTop: "5%",
                  }}
                ></img>
              ) : (
                ""
              )}

              <div
                style={{
                  border: "1px solid silver",
                  borderRadius: "10px",
                  padding: "1rem 0rem",
                  marginTop: "5%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}
              >
                <div></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "2%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      alignItems: "center",
                      gap: "10rem",
                    }}
                  >
                    <label
                      htmlFor="instances"
                      style={{
                        textAlign: "center",
                        fontSize: "21px",
                        width: "420px",
                      }}
                    >
                      <div
                        className="titleInstances"
                        style={{
                          borderBottom: "2px solid silver",
                          width: "100%",
                          transform: "translateY(-65%)",
                        }}
                      >
                        Instances
                      </div>
                    </label>
                  </div>
                </div>

                <ul
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  {editSubject.instances.map((instance: any, index: any) => (
                    <div key={index}>
                      {editIndex === index ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            padding: "0rem 1rem",
                            alignItems: "center",
                            width: "420px",
                          }}
                        >
                          <input
                            type="text"
                            value={currentInstance}
                            className="form-control"
                            style={{ width: "65%" }}
                            onChange={(e) => setCurrentInstance(e.target.value)}
                          />
                          <button
                            className="submit-btn"
                            style={{
                              backgroundColor: "white",
                              color: "black",
                              fontSize: "15px",
                              transform: "translateX(-15%)",
                              border: "1px solid silver",
                              borderRadius: "10px",
                              width: "20%",
                              height: "30px",
                            }}
                            onClick={() => handleEdit(index)}
                          >
                            Update
                          </button>
                        </div>
                      ) : (
                        // Render the instance text and edit and delete icons
                        <div
                          style={{
                            display: "flex",
                            gap: "7rem",
                            width: "420px",
                            marginTop: "0.435rem",
                          }}
                        >
                          <li
                            className="listItems"
                            style={{ listStyle: "none", width: "50%" }}
                          >
                            {instance}
                          </li>
                          <div
                            className="listItemsIcons"
                            style={{
                              width: "100px",
                              display: "flex",
                              justifyContent: "center",
                              padding: "0rem 2rem",
                              marginRight: "20px",
                              boxSizing: "border-box",
                              gap: "1rem",
                            }}
                          >
                            <img
                              src={Edit}
                              alt="edit"
                              style={{ transform: "rotate(90deg)" }}
                              onClick={() => handleEditClick(index)}
                            />
                            <img
                              src={DeleteIcon}
                              alt="delete"
                              onClick={() => handleDeleteInstance(index)}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </ul>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <input
                    className="form-control"
                    style={{ width: "100%", marginLeft: "2%" }}
                    type="text"
                    id="instances"
                    name="instances"
                    value={currentInstance}
                    onChange={(e) => setCurrentInstance(e.target.value)}
                  />

                  <img
                    src={Add}
                    alt="add-img"
                    style={{ cursor: "pointer", width: "50px", height: "40px" }}
                    onClick={handleAddInstance}
                  />
                </div>
              </div>

              <hr
                className="hRule"
                style={{
                  color: "red",
                  border: "none",
                  borderTop: "2px solid black",
                }}
              />
              <div className="d-f justify-content-center small m-4">
                <button
                  className="submit-btn m-auto"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "fit-content",
                    height: "60px",
                  }}
                  type="submit"
                >
                  <img
                    src={Edit}
                    style={{ transform: "rotate(90deg)" }}
                    alt="add-img"
                    height={"30px"}
                    width={"30px"}
                  />
                  <p>SUBMIT</p>
                </button>
              </div>
            </div>
          </form>
          <hr />
        </div>
      </Modal>
    </>
  );
};

export default EditeditSubjectDialog;
