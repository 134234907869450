import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HomeIcon from "../assets/images/home.svg";
import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch, useAppSelector } from "../redux";

import { useNavigate } from "react-router-dom";
import { closePasswordResetLinkExpireDialog } from "../redux/features/Dialog/ForgotPasswordDialogSlice";
interface Iprops {
  open: boolean;
}
export const PasswordResetLinkExpireDialog = ({ open }: any) => {
  const dispatch = useAppDispatch();
  const { isPasswordResetLinkExpireDialog } = useAppSelector(
    (s) => s.forgotPasswordDialog
  );
  const navigate = useNavigate();
  const onClose = () => {};

  return (
    <Modal
      show={isPasswordResetLinkExpireDialog}
      onHide={onClose}
      keyboard={false}
      id="loginModal"
    >
      <div className="modal-forgotpassword">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Uh-oh..</h1>
            <p className="model-header-text">
              We're sorry, but the password recovery link you have provided is
              no longer valid. Please request a new password recovery link and
              try again.
            </p>
          </div>
          <form id="forgotemailsubmit">
            <div className="form mt-4">
              <button
                className="submit-btn"
                onClick={() => {
                  dispatch(closePasswordResetLinkExpireDialog());
                  navigate("/");
                }}
              >
                <img
                  src={HomeIcon}
                  alt="login-img"
                  style={{ maxWidth: "25px", maxHeight: "25px" }}
                />
                <p>Home</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
