import { type } from "os";

export const ObjectToTypesenseFilterByString = (
  filterBy: any,
  logicOrFields:Array<string>=[],
  logic: "and" | "or"="and"
) => {
  let filterByString = "";
  let isFirstSet = false;
  let logicSymbol;


  const checkOrField=(field:any)=>{
    return logicOrFields.find((item)=>{
        return item==field
    })
  }
  if(logic=="and"){
    logicSymbol="&&"
  }
  else if(logic=="or"){
    logicSymbol="||"
  }

  for (let field in filterBy) {
    if (filterBy[field].length == 0) {
      continue;
    }
    if (!isFirstSet) {
      filterByString = filterByString + `${field}:[${filterBy[field]}]`;
      isFirstSet = true;
    }
    else {
      filterByString = filterByString + ` ${checkOrField(field)?"||": logicSymbol} ${field}:[${filterBy[field]}]`;
    }
  }
  return filterByString;
};
