import React from "react";
interface IProps {
  data?: {
    url: string;
    caption: string;
    width: number;
    height: number;
    isPixel: boolean;
  };
}
import noImageFound from "../../../../assets/images/no-image-found.jpg";

export const CutomImageComponent = ({ data }: any) => {
  return (
    <div className="my-3 image-custom-tool-comp">
      <div
        className="d-flex gap-2 justify-content-center"
        style={{ fontSize: "18px", fontWeight: "500" }}
      >
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="pixel"
            id="isByPixel"
            checked={data.isPixel ? true : false}
          />
          <label className=" mx-2">By pixel</label>
        </div>
        <div>
          <input
            className="form-check-input"
            type="checkbox"
            name="percentage"
            id="isByPercentage"
            checked={data.isPixel ? false : true}
          />
          <label className="mx-2 ">By percentage</label>
        </div>
      </div>
      <div className="d-flex gap-2 justify-content-center  my-2">
        <span className=" d-flex justify-content-center flex-column">
          <h5 className="mx-auto">Width</h5>
          <input
            type="number"
            className="rounded-3"
            id="input-image-width"
            placeholder="image width"
            style={{ maxWidth: "110px" }}
          />
        </span>
        <span className=" d-flex justify-content-center flex-column">
          <h5 className="mx-auto">Height</h5>
          <input
            type="number"
            className=" rounded-3"
            id="input-image-height"
            placeholder="image height"
            style={{ maxWidth: "110px" }}
          />
        </span>
      </div>
      <div className="pic-sec">
        <div
          className="image-card mx-auto image-tool-container"
          style={{ maxWidth: "100%", maxHeight: "100vh" }}
        >
          <img
            src=""
            style={{ margin: "0px", maxWidth: "100%", maxHeight: "100vh" }}
            alt=""
            id="custom-img-tool"
          />
          <div className="card-body">
            <p className="p-0 m-0">
              <input
                id="image-by-url-caption"
                type="text"
                value=""
                placeholder="Enter caption here"
                style={{ width: "100%", border: "none" }}
              />
            </p>
          </div>
        </div>
      </div>

      <input
        type="text"
        className=" d-flex rounded-3 mx-auto"
        id="input-image-url"
        placeholder="Enter image url"
      />
      <div>
        <input
          type="file"
          className="p-1 rounded-3 d-flex mx-auto mt-2"
          id="input-image-by-file"
          style={{ border: "1px solid black", maxWidth: "250px" }}
        />
      </div>
    </div>
  );
};

export const CutomImageComponentView = ({ data }: IProps) => {
  return (
    <div className="pic-sec">
      <div
        className="image-card mx-auto"
        style={{
          maxWidth: "100%",
          width: `${data?.width}${data?.isPixel ? "px" : "%"}`,
        }}
      >
        <img
          src={data && data.url.length > 0 ? data?.url : noImageFound}
          className=""
          alt={data?.caption}
          style={{
            width: `${data?.width}${data?.isPixel ? "px" : "vw"}`,
            height: `${data?.height}${data?.isPixel ? "px" : "vh"}`,
            margin: "0px",
            maxWidth: "100%",
            maxHeight: "100vh",
          }}
        />
        <div className="card-body">
          <p className="mb-0">{data?.caption}</p>
        </div>
      </div>
    </div>
  );
};
