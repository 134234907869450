import React from "react";
import True from "../../../../assets/images/true.svg";
import Message from "../../../../assets/images/massage.svg";
import Danger from "../../../../assets/images/danger.svg";
import Close from "../../../../assets/images/close.svg";

export const CustomQuoteBoxView = ({ data }: any) => {
  const findCurrentClass = () => {
    if (data?.type) {
      const currentClass =
        data.type == "danger"
          ? "citactions-text-sec do-not"
          : data.type == "warning"
          ? "citactions-text-sec caution"
          : data.type == "success"
          ? "citactions-text-sec do"
          : data.type == "Quote"
          ? "citactions-text-sec"
          : "citactions-sec";
      return currentClass;
    }
  };
  const findCurrentIcon = () => {
    if (data) {
      const currentIcon =
        data.type == "danger"
          ? Close
          : data.type == "warning"
          ? Danger
          : data.type == "success"
          ? True
          : data.type == "Quote"
          ? Message
          : True;
      return currentIcon;
    }
  };
  return (
    
      <div className={findCurrentClass()} id="custom-box" style={{marginBottom:"0px !important",marginTop:"36px"}}>
        <div className="d-flex align-items-center">
          <p className=" mb-0 px-0">{data?.text}</p>
          <div>
            <img alt="" style={{marginRight:"0px"}} src={findCurrentIcon()} />
          </div>
        </div>
      </div>
    
  );
};
