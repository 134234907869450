export const pagination = (data: Array<any>, per_page = 5) => {
  const record_count = data.length;
  const total_pages = Math.ceil(record_count / per_page);
  //indexing for slice method
  const sliceIndexs = [];
  let initialIndex = 0;
  let lastIndex = per_page;
  for (let page_no = 1; page_no <= total_pages; page_no++) {
    if (page_no == 1) {
      initialIndex = 0;
    } else {
      initialIndex = initialIndex + per_page;
    }
    lastIndex = per_page * page_no;
    sliceIndexs.push({ page_no, initialIndex, lastIndex });
  }
  return { record_count, per_page, total_pages, sliceIndexs };
};

export const paginatedDataByPage_no = (
  data: Array<any>,
  sliceIndex: Array<any>,
  page_no: number
) => {
  const { initialIndex, lastIndex } = sliceIndex.find(
    (indexes) => indexes.page_no == page_no
  );
  return data.slice(initialIndex, lastIndex);
};