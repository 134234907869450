import { Link, useNavigate } from "react-router-dom";
import { CodeEditor } from "../../../components/editor/CodeEditor";
import settingIcon from "../../../assets/images/setting.svg";
import publishIcon from "../../../assets/images/publish.svg";
import editIcon from "../../../assets/images/edit.svg";
import deleteIcon from "../../../assets/images/delete.svg";
import { useState } from "react";
import { IAuthor, IExperience, ISubject } from "../../../firebase";
import { RootState, useAppDispatch, useAppSelector } from "../../../redux";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";
import { useSelector } from "react-redux";
import { experienceList } from "../../../redux/reducers/ExperienceReducer";

interface IProps {
  exp: IExperience;
  subjectsList: ISubject[];
  currentUser?: IAuthor;
}
////////function start///////
export const ExperienceCard = ({ exp, subjectsList, currentUser }: IProps) => {
  const [isSetting, setisSetting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dialog } = useSelector((s: RootState) => s);

  const onClickSettingItem = async (item: string, id?: string) => {
    if (item == "edit") {
      navigate(`/experience/${exp.id}?mode=edit`);
    } else if (item == "delete") {
      dispatch(showDailog({ title: "Delete experience!", id }));
      setisSetting(false);
    } else if (item == "file") {
      dispatch(
        showDailog({
          title: `${
            exp.isPublished ? "UnPublish experience!" : "Publish experience!"
          }`,
          id,
        })
      );
    }
  };
  const renderSettingButton = () => {
    return (
      <div>
        <div className="setting-btn" onClick={() => setisSetting(!isSetting)}>
          <img className="img-fluid" src={settingIcon} alt="" />
        </div>
        <div
          className="file-btn"
          style={{
            display: `${isSetting ? "block" : "none"}`,
            backgroundColor: `${exp.isPublished ? "white" : "#C9C9C9"}`,
          }}
          onClick={() => onClickSettingItem("file", exp.id)}
        >
          <img className="img-fluid setting-item" src={publishIcon} alt="" />
        </div>
        <div
          className="edit-btn"
          style={{ display: `${isSetting ? "block" : "none"}` }}
          onClick={() => onClickSettingItem("edit")}
        >
          <img className="img-fluid setting-item" src={editIcon} alt="" />
        </div>
        <div
          className="delete-btn"
          style={{ display: `${isSetting ? "block" : "none"}` }}
          onClick={() => onClickSettingItem("delete", exp.id)}
        >
          <img className="img-fluid setting-item" src={deleteIcon} alt="" />
        </div>
      </div>
    );
  };
  const experiencesList = useAppSelector(experienceList);
  return (
    <>
      <div className="col-md-6 col-xl-4 p-3 exp-card-container">
        <div className="card mix-card create 1 stem experience-card">
          {currentUser?.adminUser
            ? renderSettingButton()
            : currentUser?.email == exp.author.email
            ? renderSettingButton()
            : ""}
          <div className="card-body p-0">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex mb-2 align-items-center card-subject">
                  <img
                    className="img-fluid mt-0"
                    style={{ maxWidth: 20 }}
                    src={
                      subjectsList.find((sub) => sub.name === exp.subject[0])
                        ?.image as string
                    }
                    alt=""
                  />
                  <p className="card-head mb-0 ms-2">{`${exp.subject[0]} (${exp.subject[1]})`}</p>
                </div>
                <div>
                  <Link to={`/experience/${exp.id}`}>
                    <h3 className="card-title">
                      {exp.title.slice(0, 40)}
                      {exp.title.length > 40 ? " ..." : ""}
                    </h3>
                  </Link>
                </div>
                <p className="card-text mb-0">{`By ${exp.author.firstName} ${exp.author.lastName}`}</p>
                <p className="card-text mb-3 mb-md-4">{`${exp.grade} grade`}</p>
              </div>
              <div>
                <img
                  className="card-image"
                  src={
                    subjectsList.find((sub) => sub.name === exp.subject[0])
                      ?.logo as string
                  }
                  alt=""
                  style={{ width: "100%", maxWidth: 128 }}
                />
              </div>
            </div>
            <h3 className=" mb-0">{}</h3>
          </div>

          <CodeEditor
            element="card-bg-section"
            editableMode={false}
            initialData={exp.background}
            onChange={() => {}}
            dataClass="exp-background"
          />
        </div>
      </div>
    </>
  );
};
