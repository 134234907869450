import { configureStore } from "@reduxjs/toolkit";
import GradeReducer from "./reducers/GradeReducer";
import CongnitiveTypeReducer from "./reducers/CongnitiveTypeReducer";
import ExperienceTypeReducer from "./reducers/ExperienceTypeReducer";
import SubjectReducer from "./reducers/SubjectReducer";
import ExperienceReducer from "./reducers/ExperienceReducer";
import { FilterSearchExperiencesReducer } from "./features/filterAndSearchData/FilterSearchExperienceSlice";
import { DialogReducer } from "./features/Dialog/DialogSlice";
import { ForgotPasswordDialogReducer } from "./features/Dialog/ForgotPasswordDialogSlice";
import { AccountVerificationReducer } from "./features/Dialog/AccountVerificationDialogSlice";
import { CreateNewPasswordDialogReducer } from "./features/Dialog/CreateNewPasswordDialogSlice";
import { AlertCommonDialogReducer } from "./features/Dialog/AlertCommonDialogSlice";

export const AppStore = configureStore({
  reducer: {
    experiences: ExperienceReducer,
    subjects: SubjectReducer,
    grades: GradeReducer,
    congnitiveType: CongnitiveTypeReducer,
    experienceType: ExperienceTypeReducer,
    filterSearchExperiences: FilterSearchExperiencesReducer,
    dialog:DialogReducer,
    forgotPasswordDialog:ForgotPasswordDialogReducer,
    accountVerificationDialog:AccountVerificationReducer,
    createNewPassword:CreateNewPasswordDialogReducer,
    alertCommonDialog:AlertCommonDialogReducer
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof AppStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof AppStore.dispatch;
