//@ts-ignore

import ReactDOMServer from "react-dom/server";
import { ExistingLogos } from "../../../pages/experience/components/codeEditorhtmlComponents/CustomHeaderComponent";
import { firebase_service } from "../../../firebase";

export class CustomInlineLogoTool {
  button: any;
  state: boolean;
  wrapper!: HTMLDivElement;
  api: any;
  selectedText: any;
  image: HTMLImageElement | undefined;
  config: any;
  anchorElement: Element | null | undefined;
  static get isInline() {
    return true;
  }
  constructor({ api, config }: any) {
    this.button = null;
    this.state = false;
    this.api = api;
    this.config = {
      uploader: {
        uploadByFile(file: File) {
          return firebase_service["cloud"]
            .uploadFileToCloud("experience", file)
            .then((imgUrl) => {
              return {
                success: 1,
                file: {
                  url: imgUrl,
                },
              };
            });
        },
      },
    };
  }

  render() {
    this.button = document.createElement("button");
    this.button.type = "button";
    this.button.style.border = "none";
    this.button.innerHTML = `<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"></path></svg>`;
    this.button.classList.add(this.api?.styles?.inlineToolButton);
    return this.button;
  }
  surround(range:Range) {
    console.log("render surround Range object ::", range);

    if (this.state) {
      // if image is already added
      return;
    }
    
    if(this.wrapper.hidden && !this.state){
      
      this.wrapper.hidden = false;
      const InsertButton = this.wrapper.querySelector(
        "#insert-inline-logo-button"
      ) as HTMLSelectElement;
      const customImage = this.wrapper.querySelector(
        "#inline-begin-logo"
      ) as HTMLImageElement;
      InsertButton.addEventListener("click", () => {
        this.state=true
        // this.selectedText = range.extractContents();
        // range.insertNode(customImage);

      // if(this.anchorElement?.childNodes[0]){
      //   this.anchorElement.childNodes[0]=customImage
      // }
      this.anchorElement?.prepend(customImage)
      });
    }
    else{
      this.wrapper.hidden = true
    }
  }

  checkState(selection: Selection) {
    
    const text = selection.anchorNode;
    if (!text) {
      return;
    }
    this.anchorElement = text instanceof Element ? text : text.parentElement;
    console.log("render checkState ::", this.anchorElement?.childNodes[0]);
    const firstChild=this.anchorElement?.childNodes[0] as HTMLImageElement
    if(firstChild.id=="inline-begin-logo"){
      // this.state=true
      // const CustomImage=this.wrapper.querySelector("#inline-begin-logo") as HTMLImageElement
      // CustomImage.src=firstChild.src
    }
    // // anchorElement.style.border="2px solid red"
    // this.state = !!anchorElement.closest("img");
    // console.log("state",this.state,anchorElement)
  }

  //////////action part //////////////
  renderActions() {
    console.log("render actions ::", this);
    this.wrapper = document.createElement("div");
    this.wrapper.style.fontSize="small"
    
    
    this.wrapper.innerHTML = ReactDOMServer.renderToString(<ActionMenu />);
    this.wrapper.hidden = true;
    {
      //// set dom object in variable /////
      const customImage = this.wrapper.querySelector(
        "#inline-begin-logo"
      ) as HTMLImageElement;
      const inputImageFile = this.wrapper.querySelector(
        "#header-begin-logo-file"
      ) as HTMLInputElement;
      const inputImageUrl = this.wrapper.querySelector(
        "#header-logo-url"
      ) as HTMLInputElement;
      const ChooseLogo = this.wrapper.querySelector(
        "#choose-begin-logo"
      ) as HTMLSelectElement;


      ///////initilize values//////
      const initialUrl="/static/media/book.784949f3756bfbadf2e9519844ff2ac2.svg"
      ChooseLogo.value=initialUrl
       customImage.src = initialUrl
       inputImageUrl.value =initialUrl
      //////image added on paste///////
      inputImageUrl?.addEventListener("paste", (event: any) => {
        const url = event.clipboardData.getData("text");
        customImage.src = url;
        ChooseLogo.value = "";
        inputImageFile.value = "";
      });
      ////////on change file//////////

      inputImageFile.addEventListener("change", async (event: any) => {
        const loadingGif =
          "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif";
        const ImageFile = event.target.files[0];
        if (ImageFile) {
          customImage.src = loadingGif;
          const uploadedFile = await this.config.uploader.uploadByFile(
            ImageFile
          );
          const url = uploadedFile.file.url;
          inputImageUrl.value = url;
          customImage.src = url;
          ChooseLogo.value = "";
        }
      });
      ////////choose from existing logos///////

      ChooseLogo.addEventListener("change", (event: any) => {
        const url = event.target.value;
        customImage.src = url;
        inputImageUrl.value = url;
      });
    }
    return this.wrapper;
  }
  static get sanitize() {
    return {
      img: {
        class: true,
        id: true,
        src: true,
      },
    };
  }
}

const ActionMenu = ({ data }: any) => {
  return (
    <div
      className="text-center p-2 rounded-3 d-flex flex-column gap-2 justify-content-center"
      style={{ border: "1px solid black" }}
    >
      <h3>
        Begin Logo:{" "}
        <img
          src=""
          id="inline-begin-logo"
          alt=""
        />
      </h3>
      <div>
        <select
          className=""
          aria-label="Default select example"
          defaultValue={data?.beginImageUrl}
          id="choose-begin-logo"
        >
          <option value="">Other</option>
          {ExistingLogos.map((logo) => {
            return (
              <>
                <option key={logo.name} value={logo.url}>
                  {logo.name}
                </option>
              </>
            );
          })}
        </select>
      </div>
      <input
        type="file"
        id="header-begin-logo-file"
        className="mx-auto rounded-3 p-1 "
        style={{ maxWidth: "215px" }}
      />
      <input
        type="text"
        placeholder="Enter image url"
        id="header-logo-url"
        style={{ width: "100%" }}
        value={data?.beginImageUrl}
      />
      <button
        className="mx-auto"
        id="insert-inline-logo-button"
        style={{ width: "40%", height: "50%",borderRadius:"16px",backgroundColor:"rgb(249,238,248)" }}
      >
        Insert
      </button>
    </div>
  );
};
