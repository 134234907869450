import { RequestResponse } from "../../constants"
import { typesenseClient } from "../Config"
import { COLLECTION_CONGNITIVE_TYPE } from "../Interfaces"
import { CollectionSchema } from "../Schemas"


export const createCognitiveDoc = async (title: string) => {
    try {
        if (!await typesenseClient.collections(COLLECTION_CONGNITIVE_TYPE).exists()) await typesenseClient.collections().create(CollectionSchema[COLLECTION_CONGNITIVE_TYPE])

        const docResult = await typesenseClient.collections(COLLECTION_CONGNITIVE_TYPE).documents().create({ title })

        return { ...RequestResponse['201'], data: docResult }
    } catch (error: any) {
        return { ...RequestResponse['500'], error: error.message }
    }
}

  
export const deleteCognitiveDoc = async (subjectId: number) => {
    try {
      const docResult = await typesenseClient.collections(COLLECTION_CONGNITIVE_TYPE).documents(subjectId.toString()).delete()
      return { ...RequestResponse['200'], data: docResult }
    } catch (error: any) {
      return { ...RequestResponse['500'], error: error.message }
    }
  }
  
  
  

export const updateCognitiveDoc = (id: string, title: string) => typesenseClient.collections(COLLECTION_CONGNITIVE_TYPE).documents(id).update({ title });




export const congnitive_type_service = {
    createCognitiveDoc,
    updateCognitiveDoc,
    deleteCognitiveDoc
}