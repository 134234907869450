import React from 'react'
import Message from "../../../assets/images/massage.svg";
import Danger from "../../../assets/images/danger.svg";
import Close from "../../../assets/images/close.svg";
import True from "../../../assets/images/true.svg";



const Citations = () => {
  return (
    <>
         <div className="citactions-sec ">
            <div className="d-flex align-items-center">
              <p className="mb-0">
                I am enough of an artist to draw freely upon my imagination.
                Imagination is more important than knowledge. Knowledge is
                limited. Imagination encircles the world.
              </p>
              <div>
                <img src={Message} alt="" />
              </div>
            </div>
            <h3>Albert Einstein</h3>
          </div>
          <div className="citactions-text-sec caution mb-3">
            <div className="d-flex align-items-center">
              <p className="mb-0">
                This is some caution practice text, which spans over more than
                one line
              </p>
              <div>
                <img src={Danger} alt="" />
              </div>
            </div>
            <h3>Caution Practice</h3>
          </div>
          <div className="citactions-text-sec do mb-3">
            <div className="d-flex align-items-center">
              <p className="mb-0">
                This is some good practice text, which spans over more than one
                line
              </p>
              <div>
                <img src={True} alt="" />
              </div>
            </div>
            <h3>Good Practice</h3>
          </div>
          <div className="citactions-text-sec do-not mb-3">
            <div className="d-flex align-items-center">
              <p className="mb-0">
                This is some Bad practice text, which spans over more than one
                line
              </p>
              <div>
                <img src={Close} alt="" />
              </div>
            </div>
            <h3>Bad Practice</h3>
          </div>
          <div className="citactions-border-sec do">
            <div className="card">
              <div className="card-head ">
                <div>
                  <img src={True} alt="" />
                </div>
                <h3 className="mb-0">Good Practice</h3>
              </div>
              <div className="card-section">
                <p>
                  This is some Good Practice text, which spans over more than
                  one line
                </p>
              </div>
            </div>
          </div>
          <div className="citactions-border-sec do-not">
            <div className="card">
              <div className="card-head ">
                <div>
                  <img src={Close} alt="" />
                </div>
                <h3 className="mb-0">Bad Practice</h3>
              </div>
              <div className="card-section">
                <p>
                  This is some bad Practice text, which spans over more than one
                  line
                </p>
              </div>
            </div>
          </div>
          <div className="citactions-border-sec caution">
            <div className="card">
              <div className="card-head ">
                <div>
                  <img src={Danger} alt="" />
                </div>
                <h3 className="mb-0">Caution Practice</h3>
              </div>
              <div className="card-section">
                <p>
                  This is some no recommended Practice text, which spans over
                  more than one line
                </p>
              </div>
            </div>
          </div>
          <div className="citactions-border-sec">
            <div className="card">
              <div className="card-head ">
                <div>
                  <img src={Message} alt="" />
                </div>
                <h3 className="mb-0">quote</h3>
              </div>
              <div className="card-section">
                <p>
                  I am enough of an artist to draw freely upon my imagination.
                  Imagination is more important than knowledge. Knowledge is
                  limited. Imagination encircles the world.
                </p>
              </div>
            </div>
          </div>
    </>
  )
}

export default Citations
