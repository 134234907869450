

export enum SingInProvider {
    google = "google",
    passkey = "passkey",
    email = "email"
}

export enum ExperienceType {
    Writing = "Writing",
    Reading = "Reading",
    Presentation = "Presentation",
    Standard = "Standard",
    Field = "Field",
}

export enum CongnitiveType {
    Create = "Create",
    Evaluate = "Evaluate",
    Analyze = "Analyze",
    Apply = "Apply",
    Understand = "Understand",
    Remember = "Remember"
}

export enum GradeLevel {
    "6th" = "6th",
    "7th" = "7th",
    "8th" = "8th",
    "9th" = "9th",
    "10th" = "10th",
    "11th" = "11th",
    "12th" = "12th"
}