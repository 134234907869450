import Edit from "../../assets/images/edit.svg";

import { Modal } from "react-bootstrap";
import { useState } from "react";
import {
  congnitiveTypeSlice,
  gardeSlice,
  updateCognitiveThunk,
  updateGradeThunk,
  useAppDispatch,
} from "../../redux";
import { updateExperienceThunk } from "../../redux/reducers/ExperienceReducer";
import { experienceTypeSlice, updateExoerienceTypeThunk } from "../../redux/reducers/ExperienceTypeReducer";

interface IEditDialog {
  open: boolean;
  onClose: () => void;
  type: "grades" | "experience" | "cognitive";
  gradeArray?: string[] | any;
  setGradeArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
  experienceArray?: string[] | any;
  setexperienceArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
  cognitiveArray?: string[] | any;
  setcognitiveArray?: React.Dispatch<React.SetStateAction<string[]>> | any;
  selectedID: number | any;
  selectedText: string | any
}

const EditDialog = ({
  onClose,
  open,
  gradeArray,
  setGradeArray,
  experienceArray,
  setexperienceArray,
  type,
  cognitiveArray,
  selectedText,
  setcognitiveArray,
  selectedID,
}: IEditDialog | any) => {
  const dispatch = useAppDispatch();
  const [editText, seteditText] = useState(selectedText);

  const handleEditData = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    switch (type) {
      case "grades":
        dispatch(updateGradeThunk({ id: selectedID, updatedText: editText }));
        const updatedGradeArray = gradeArray.map((item: { id: number | any }) => {
          if (item.id === selectedID) {
            return { ...item, title: editText };
          }
          return item;
        });
        
        const updatedGradeArrayWithNew = updatedGradeArray.slice(); 
        
        for (let i = 0; i < updatedGradeArrayWithNew.length; i++) {
          if (updatedGradeArrayWithNew[i].id === selectedID) {
            updatedGradeArrayWithNew[i] = { id: selectedID, title: editText };
            break; 
          }
        }
        
        setGradeArray(updatedGradeArrayWithNew);
        
        dispatch(gardeSlice.actions.gradeArray(updatedGradeArrayWithNew));
        
        break;
      case "experience":
        dispatch(updateExoerienceTypeThunk({ id: selectedID, updatedText: editText }));
        const updatedExperienceArray = experienceArray.map((item: { id: any; }) => {
          if (item.id === selectedID) {
            return { ...item, title: editText };
          }
          return item;
        });
        
        const updatedExperienceArrayWithNew = updatedExperienceArray.slice();
        
        for (let i = 0; i < updatedExperienceArrayWithNew.length; i++) {
          if (updatedExperienceArrayWithNew[i].id === selectedID) {
            updatedExperienceArrayWithNew[i] = { id: selectedID, title: editText };
            break; 
          }
        }
        
        setexperienceArray(updatedExperienceArrayWithNew);
        
        dispatch(experienceTypeSlice.actions.experienceArray(updatedExperienceArrayWithNew));
        
        
        break;
      case "cognitive":
        dispatch(updateCognitiveThunk({ id: selectedID, updatedText: editText }));
        const updatedCognitiveArray = cognitiveArray.map((item: { id: any; }) => {
          if (item.id === selectedID) {
            return { ...item, title: editText };
          }
          return item;
        });
        
        const updatedCognitiveArrayWithNew = updatedCognitiveArray.slice(); 
        
        for (let i = 0; i < updatedCognitiveArrayWithNew.length; i++) {
          if (updatedCognitiveArrayWithNew[i].id === selectedID) {
            updatedCognitiveArrayWithNew[i] = { id: selectedID, title: editText };
            break;
          }
        }
        
        setcognitiveArray(updatedCognitiveArrayWithNew);
        
        dispatch(congnitiveTypeSlice.actions.cognitiveArray(updatedCognitiveArrayWithNew));
        
        break;
      default:
        break;
    }
    onClose();
  };

  return (
    <>
      <Modal
      className="grade-add-modal"
        show={open}
        onHide={onClose}
        onSubmit={handleEditData}
        keyboard={false}
        id="loginModal"
      >
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Edit It !</h1>
          </div>
          <form id="loginsubmit">
            <div className="form">
              {type === "grades" && (
                <>
                  <label htmlFor="loginemail">
                    <p>Add Grades Data</p>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="textField"
                    placeholder="Enter Add Text"
                    required
                    value={editText}
                    onChange={(e) => seteditText(e.target.value)}
                  />
                </>
              )}

              {type === "experience" && (
                <>
                  <label htmlFor="loginemail">
                    <p>Add Experience Data</p>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="textField"
                    placeholder="Enter Add Text"
                    required
                    value={editText}
                    onChange={(e) => seteditText(e.target.value)}
                  />
                </>
              )}

              {type === "cognitive" && (
                <>
                  <label htmlFor="loginemail">
                    <p>Update Cognitive Data</p>
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="textField"
                    placeholder="Update Text"
                    required
                    value={editText}
                    onChange={(e) => seteditText(e.target.value)}
                  />
                </>
              )}

              <button
                type="submit"
                className="submit-btn"
                style={{ marginInline: "auto",
          
                marginBlock: 30,
                width: "fit-content",
                height: "40px",
                alignSelf: "center", }}
              >
                <img src={Edit} height={"20px"}    style={{  }} width={"20px"} alt="add-img" />
                <p >Update</p>
              </button>
            </div>
          </form>
          <hr />
        </div>
      </Modal>
    </>
  );
};

export default EditDialog;
