import { createSlice } from "@reduxjs/toolkit";
import { IAuthor } from "../../../firebase";
import { ObjectToTypesenseFilterByString } from "../../../helper/ObjectToTypesenseFilterByString";

const initialState={
  filterBy: "isPublished:[true]",
  search: {
    q: "",
    query_by: "title,background,goal,subject,author.firstName,author.lastName,title",
  },
  sort_by: "title:asc",
  FilterObject: {
    cognitive: [] as any,
    grade: [] as any,
    experience: [] as any,
    subject: [] as any,
    "author.email": [] as any,
  },
  currentUser:{} as IAuthor,
  customFilterString:"" as string,
  FiltersCount: 0,
}

export const FilterSearchExperiencesSlice = createSlice({
  name: "FilterSearchExperiences",
  initialState:initialState,
  reducers: {
    setExperiencesFilter: (state, onChangeFilter) => {
      const {
        checked,
        value,
        field,
      }: { checked: boolean; value: string; field: string } =
        onChangeFilter.payload;
      if (
        field == "experience" ||
        field == "grade" ||
        field == "cognitive" ||
        field == "author.email"
        
      ) {
        if (checked) {
          if (state.FilterObject[field][0] != value) {
            state.FilterObject[field].push(value);
          }
        } else {
          state.FilterObject[field] = state.FilterObject[field].filter(
            (item: any) => item !== value
          );
        }
      }
      if (field == "subject") {
        if (state.FilterObject.subject == value) {
          state.FilterObject.subject = "";
        } else {
          state.FilterObject.subject = value;
        }
      }
      const FilterString = ObjectToTypesenseFilterByString(state.FilterObject);
     
      console.log("Filter string: ", FilterString);


      /////////custom filter//////////
      
      const buildNewFilterString=()=>{
        if(state.currentUser?.simpleUser){
          state.customFilterString=`author.email:[${state.currentUser?.email}] || isPublished:[true]`
        }
        else if(state.currentUser.adminUser){
          return ""
        }
        else{
          state.customFilterString="isPublished:[true]"
        }
        return `${FilterString.length==0?state.customFilterString:` && (${state.customFilterString})`}`
      }
      
      //////////append in query//////////
      state.filterBy = `${FilterString}${buildNewFilterString()}`;

      const totalFilterCount = (FilterObject: any) => {
        let sum = 0;
        for (let key in FilterObject) {
          if (key == "subject") {
            continue;
          } else {
            sum = sum + FilterObject[key].length;
          }
        }
        return sum;
      };
      state.FiltersCount = totalFilterCount(state.FilterObject);
      return state;
    },
    setExperiencesSearch: (state, search) => {
      state.search.q = search.payload;
      return state;
    },
    setCurrentUser:(state,currentUser)=>{
      state.currentUser=currentUser.payload
      if(state.currentUser.adminUser){
        state.filterBy=""
        state.customFilterString=""
      }
      else if(state.currentUser.simpleUser){
        state.customFilterString=`author.email:[${state.currentUser?.email}] || isPublished:[true]`
        state.filterBy=state.customFilterString
      }
      return state
    },
    resetCountExperiencesFilter: (state) => {
      state.FiltersCount = 0;
    },
    resetExperienceFilterObject: (state) => {
      state.FilterObject = initialState.FilterObject;
      state.FiltersCount = 0;
      state.filterBy = state.customFilterString
      return state;
    },
    resetAuthorInExperience:( state)=>{
      state.FilterObject = initialState.FilterObject;
      state.currentUser=initialState.currentUser
      state.FiltersCount = 0;
      state.customFilterString="isPublished:[true]"
      state.filterBy = state.customFilterString
      return state;
    },
    resetSubject:(state)=>{
      if(state.FilterObject.subject.length>0)
      {
        state.FilterObject.subject=""
      }
        return state
    }
  },
});

export const {
  setExperiencesFilter,
  setExperiencesSearch,
  resetCountExperiencesFilter,
  resetExperienceFilterObject,
  resetAuthorInExperience,
  setCurrentUser,
  resetSubject
} = FilterSearchExperiencesSlice.actions;

export const FilterSearchExperiencesReducer =
  FilterSearchExperiencesSlice.reducer;
