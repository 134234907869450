import { RequestResponse } from "../../constants"
import { typesenseClient } from "../Config"
import { COLLECTION_EXPERIENCE, IAddExperience, } from "../Interfaces"
import { CollectionSchema } from "../Schemas"



export const createExperienceDoc = async (expDoc: IAddExperience) => {
  try {
      if (!await typesenseClient.collections(COLLECTION_EXPERIENCE).exists()) await typesenseClient.collections().create(CollectionSchema[COLLECTION_EXPERIENCE])

      const docResult = await typesenseClient.collections(COLLECTION_EXPERIENCE).documents().create(expDoc)

      return { ...RequestResponse['201'], data: docResult }
  } catch (error: any) {
      return { ...RequestResponse['500'], error: error.message }
  }
}
  

export const deleteExperienceDoc = async (gradeId: number) => {
    try {
      const docResult = await typesenseClient.collections(COLLECTION_EXPERIENCE).documents(gradeId.toString()).delete()
      return { ...RequestResponse['200'], data: docResult }
    } catch (error: any) {
      return { ...RequestResponse['500'], error: error.message }
    }
  }
  

  export const updatedExperienceDoc = (id: string, detail: IAddExperience) => typesenseClient.collections(COLLECTION_EXPERIENCE).documents(id).update(detail);

export const experience_service = {
    createExperienceDoc,
    updatedExperienceDoc,
    deleteExperienceDoc
}