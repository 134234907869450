import ReactDOMServer from "react-dom/server";
import { CustomTitle } from "../../../pages/experience/components/codeEditorhtmlComponents/CustomHeaderComponent";
import { API } from "@editorjs/editorjs";
import { h1_Icon, h2_Icon, h3_Icon, h_Icon } from "./SvgStrings";
import bookIcon from "../../../assets/images/book.svg";
export class CustomTitleTool {
  data: IProps["data"];
  wrapper: any;
  config: any;
  constructor({ data, wrapper, config }: IProps) {
    if (!data.text) {
      data = {
        text: ""
      };
    }
    this.wrapper = wrapper;
    this.data = data;
    this.config = config;
  }

  static get toolbox() {
    return {
      title: "Title",
      icon: h2_Icon,
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(
      <CustomTitle data={this.data} />
    );
    return this.wrapper;
  }

  save(blockContent: any) {
    const text = blockContent.querySelector("#header-tool-text").innerHTML;
    
    return {
      text,
    };
  }
}
interface IProps {
  data: {
    text: String;
  };
  api: API;
  config?: any;
  wrapper?: any;
}

