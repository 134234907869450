import React from "react";

const parse = require('html-react-parser');

export const TableWithHead = ({ data }: any) => {
 
  const renderTableData = (data: Array<any>) => {
    return data.map((row, index) => {
      if (index == 0) {
        return (
          <thead>
            <tr>
              {row.map((head: any) => {
                return (
                  <th>
                    <h3>{head}</h3>
                  </th>
                );
              })}
            </tr>
          </thead>
        );
      } else if (index > 0) {
        return (
          <tbody>
            <tr>
              {row.map((data: any) => {
                return (
                  <td>
                    <div className="d-flex">
                      <p className="p-0">{parse(data)}</p>
                    </div>
                  </td>
                );
              })}
            </tr>
          </tbody>
        );
      }
    });
  };

  return (
    <div className="overflow-scroll">
      <table className="table-sec table mb-1">{renderTableData(data)}</table>
    </div>
  );
};
