import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux";
import {
  resetExperienceFilterObject,
  resetSubject,
  setCurrentUser,
  setExperiencesFilter,
} from "../../../redux/features/filterAndSearchData/FilterSearchExperienceSlice";
import { fetchExperienceListThunk, setIsChanged } from "../../../redux/reducers/ExperienceReducer";
import filterIcon from "../../../assets/images/filters.svg";
import RightArrowIcon from "../../../assets/images/rightarrow-icon.svg";
import { useAuthProvider } from "../../../providers";
import FilterButton from "./FilterButton";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const FilterSection = () => {
  const [isHidden, setIsHidden] = useState(false);
  const ReduxState = useSelector((s: RootState) => s);
  const { isAuthenticated, userProfile } = useAuthProvider();
  //////filters type/////
  const { congnitiveType, experienceType, grades } = ReduxState;

  /////// --------filtering logic ---------------//////////
  const FilterSearchExperiences = ReduxState.filterSearchExperiences;
  const isChanged= ReduxState.experiences.isChanged
  const FilterCount = FilterSearchExperiences.FiltersCount;
  const gradeArray = useSelector((state: RootState) => state.grades.data);
  const experienceArray = useSelector(
    (state: RootState) => state.experienceType.data
  );
  const cognitiveArray = useSelector(
    (state: RootState) => state.congnitiveType.data
  );

  // ---------------- Handle Toggle -------------------

  const [showComponent, setShowComponent] = useState(false);
  const [closedByClickOutside, setClosedByClickOutside] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 991) {
        setShowComponent(true);
        setIsHidden(true);
      } else {
        setShowComponent(false);
        setIsHidden(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);



  
  
  const dialogRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (window.innerWidth <= 991) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  });

  const handleClickOutside = (event: any) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
      event.stopPropagation()
      
      if(event.target.innerHTML!="Filters" && event.target.innerHTML!="(0)"){
        setIsHidden((pre)=>true);
      }
    }
  };

  const handleButtonClick = (e:any) => {
    setIsHidden((pre)=>!pre);
  };
  const appDispatch = useAppDispatch();

  ////////////onFilterHandling/////////////
  const onFilterHandler = (e: any) => {
    const {
      checked,
      value,
      name,
    }: { checked: boolean; value: string; name: string } = e.target;
    const field = name;
    appDispatch(setExperiencesFilter({ checked, value, field }));
  };

  const onClear = () => {
    if (FilterCount > 0) {
      appDispatch(resetExperienceFilterObject());
    }
  };
/////////check new added data/////////////
const {search}=useLocation()
if(search.length>0){
  const query=new URLSearchParams(search)
  var isNewDataAdded=query.get("isNewDataAdded")
}

  ////////// Experience permissions logic////////////////
  useMemo(() => {
    if (userProfile) {
      appDispatch(setCurrentUser(userProfile));
    }
  }, [userProfile]);

  ////////////rendering data//////////////
  const renderMyExperiencesFilter = () => {
    return (
      <fieldset className="seleted py-3">
        <div className="seleted d-flex align-items-center">
          <label className="seleted container-input" htmlFor="my_experiences">
            <input
              type="checkbox"
              className="seleted checkbox"
              onClick={onFilterHandler}
              value={userProfile?.email}
              name="author.email"
              id="my_experiences"
            />
            <div className="seleted checkmark"></div>
          </label>
          <label className="seleted label" htmlFor="my_experiences">
            <p className="seleted">
              My <br /> experiences
            </p>
          </label>
        </div>
      </fieldset>
    );
  };

      useEffect(() => {
        if (window.innerWidth <= 991) {
          document.addEventListener("mousedown", handleClickOutside);
        } else {
          document.removeEventListener("mousedown", handleClickOutside);
        }
      
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);

      useEffect(() => {
        appDispatch(resetSubject())
        return () => {
          onClear()
        }
      }, [])
      
  return (
    <>
      <div
        style={{ display: isHidden ? "none" : "block"}}
        className="filter-position"
        
        ref={dialogRef}
      >
        <div className="d-none d-lg-flex align-items-center mb-3" >
          <img
            className="img-fluid pe-2"
            src={filterIcon}
            alt=""
            style={{ maxWidth: 30 }}
          />
          <h3 className="text-uppercase mb-0">
            Filters (<span className="number">{FilterCount}</span>)
          </h3>
        </div>
        {/* Filter box start  */}
        <form className="filter-box" id="Filters" onReset={onClear}>
          <label
            className="seleted clearall"
            style={{ marginLeft: 33 }}
            htmlFor="reset"
          >
            Clear all
          </label>
          <input type="reset" id="reset" />
          {/* ////////// My experience filter /////////// */}
          {isAuthenticated ? renderMyExperiencesFilter() : ""}
          <fieldset className="seleted d-block pb-3">
            <button
              className="seleted navbar-toggler d-flex align-items-center mb-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Content-1"
              aria-controls="Content-1"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <h3 className="seleted mb-0 pe-3">Cognitive</h3>
              <img
                src={RightArrowIcon}
                className="seleted right-arrow"
                alt=""
              />
            </button>

            {/*------------------------------ Mapping over Cognitive Arr ------------------------------------*/}
            {cognitiveArray.map((element, index) => {
              return (
                <div
                  className="seleted collapse navbar-collapse show"
                  id="Content-1"
                  key={element.title}
                >
                  <div className="seleted checkbox-container congnitive_checkbox">
                    <label
                      className="seleted container-input"
                      htmlFor={element.title}
                    >
                      <input
                        type="checkbox"
                        className="seleted checkbox"
                        name="cognitive"
                        defaultValue={element.title}
                        id={element.title}
                        onClick={onFilterHandler}
                      />
                      <div className="seleted checkmark" />
                    </label>
                    <label className="seleted label" htmlFor={element.title}>
                      <p className="seleted">{element.title}</p>
                    </label>
                  </div>
                </div>
              );
            })}
          </fieldset>

          <fieldset className="seleted d-block pb-0">
            <button
              className="seleted navbar-toggler d-flex align-items-center mb-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Content-3"
              aria-controls="Content-3"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <h3 className="seleted mb-0 pe-3">Experience</h3>
              <img
                src={RightArrowIcon}
                className="seleted right-arrow"
                alt=""
              />
            </button>
            <div
              className="seleted collapse navbar-collapse show"
              id="Content-3"
            >
              {/* -------------Mapping over Experiences arr-------------------- */}

              {experienceArray.map((element: any, index: any) => {
                return (
                  <div
                    className="seleted checkbox-container experience_checkbox"
                    key={element.title}
                  >
                    <label
                      className="seleted container-input"
                      htmlFor={element.title}
                    >
                      <input
                        type="checkbox"
                        className="seleted checkbox"
                        defaultValue={element.title}
                        name="experience"
                        id={element.title}
                        onClick={onFilterHandler}
                      />
                      <div className="seleted checkmark" />
                    </label>
                    <label className="seleted label" htmlFor={element.title}>
                      <p className="seleted">{element.title}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          </fieldset>
          <fieldset className="seleted d-block pb-3">
            <button
              className="seleted navbar-toggler d-flex align-items-center mb-3"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Content-2"
              aria-controls="Content-2"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <h3 className="seleted mb-0 pe-3">Grade</h3>
              <img
                src={RightArrowIcon}
                className="seleted right-arrow"
                alt=""
              />
            </button>
            <div
              className="seleted collapse navbar-collapse show"
              id="Content-2"
            >
              {/* ---------Mapping over Grade arr---------------- */}

              {gradeArray.map((element: any, index: any) => {
                return (
                  <div
                    className="seleted checkbox-container grade_checkbox"
                    key={element.title}
                  >
                    <label
                      className="seleted container-input"
                      htmlFor={element.title}
                    >
                      <input
                        type="checkbox"
                        className="seleted checkbox"
                        defaultValue={element.title}
                        name="grade"
                        id={element.title}
                        onClick={onFilterHandler}
                      />
                      <div className="seleted checkmark" />
                    </label>
                    <label className="seleted label" htmlFor={element.title}>
                      <p className="seleted">{element.title}</p>
                    </label>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </form>
        {/* Filter box end */}
      </div>
      {showComponent ? (
        <FilterButton
          handleButtonClick={handleButtonClick}
          FilterCount={FilterCount}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default FilterSection;
