import ReactDOMServer from "react-dom/server";
import {
    CustomHeadingComponent,
  CustomSubtitleComponent,
} from "../../../pages/experience/components/codeEditorhtmlComponents/CustomHeaderComponent";
import { API } from "@editorjs/editorjs";
import { h1_Icon, h2_Icon, h3_Icon, h_Icon } from "./SvgStrings";

export class CustomHeadingTool {
  data: IProps["data"];
  wrapper: any;
  config: any;
  constructor({ data, wrapper, config }: IProps) {
    this.wrapper = wrapper;
    this.data = data;
    this.config = config;
  }
  static get toolbox() {
    return {
      title: "Heading",
      icon: h1_Icon,
    };
  }
  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(
      <CustomHeadingComponent data={this.data} />
    );
    return this.wrapper;
  }
  save(blockContent: any) {
    const text = blockContent.querySelector("#subtitle-tool-text").innerHTML;
    return {
      text,
    };
  }
}
interface IProps {
  data: {
    text: String;
  };
  api: API;
  config?: any;
  wrapper?: any;
}
