import { useEffect, useState } from "react";
import Add from "../../../assets/images/add.svg";
import Pen from "../../../assets/images/pen.svg";
import Edit from "../../../assets/images/edit.svg";
import Delete from "../../../assets/images/delete.svg";
import { useAppDispatch, useAppSelector } from "../../../redux";
import SubjectDialog from "../../../dialogs/consolePageDialogs/SubjectDialog/SubjectDialog";
import EditeditSubjectDialog from "../../../dialogs/consolePageDialogs/SubjectDialog/EditSubjectDialog";
import DeleteDialog from "../../../dialogs/consolePageDialogs/SubjectDialog/DeleteSubjectDialog";
import { deleteSubjectThunk } from "../../../redux/reducers/SubjectReducer";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";
import { CommonDialog } from "../../../dialogs/CommonDialog";

const SubjectTable = () => {
  const dispatch = useAppDispatch();
  const [ subjectDeleteDialog,setsubjectDeleteDialog] = useState(false)

  const subjectsData = useAppSelector((state: any) => state.subjects.data);

  const [subjectArray, setsubjectArray] = useState<any>([]);

  const [subjectDialog, setsubjectDialog] = useState(false);
  const [editSubjectDialog, seteditsubjectdialog] = useState(false);
  const [selectedId, setselectedId] = useState();

  

  const [selectedSubject, setselectedSubject] = useState({
    name: "",
    image: "",
    logo: "",
    instances: "",
  });

  const handleEditSubjectArr = (id: number | any) => {
    seteditsubjectdialog(true);
    setselectedId(id);
    console.log("id", id);
  
    const selectedSubject = subjectArray.find(
      (subject: any) => subject.id === id
    );

    if (selectedSubject) {
      setselectedSubject({
        name: selectedSubject.name,
        image: selectedSubject.image,
        logo: selectedSubject.logo,
        instances: selectedSubject.instances,
      });
      console.log(selectedSubject, "sub");
    }

    seteditsubjectdialog(true);
    setselectedId(id);
  };

  const handleAddSubjectDomain = () => {
    setsubjectDialog(true);
  };


  const onClose = () => {
    setsubjectDialog(false);
    seteditsubjectdialog(false);
  };

  useEffect(() => {
    setsubjectArray(subjectsData);
  }, [subjectsData]);

  const handleDeleteSubjectArr = async (id: number | any) => {
    setsubjectDeleteDialog(true)
    console.log(id, "id", subjectDeleteDialog, "subject_del")
    setselectedId(id)
    dispatch(showDailog({title:"Delete Subject Domain!",id}))
};

  return (
    <>
    {
      // subjectDeleteDialog && (
      //   <DeleteDialog selectedId={selectedId} setSubjectDeleteDialog={setsubjectDeleteDialog}/>
      // )
      // <CommonDialog id={selectedId}/>
      
    }


      {subjectDialog && (
        <SubjectDialog
          open={true}
          onClose={onClose}
          subjectArray={subjectArray}
          setsubjectArray={setsubjectArray}
        />
      )}

      {editSubjectDialog && (
        <EditeditSubjectDialog
          open={true}
          selectedSubject={selectedSubject}
          subjectArray={subjectArray}
          setsubjectArray={setsubjectArray}
          type="subject"
          selectedID={selectedId}
          onClose={() => seteditsubjectdialog(false)}
        />
      )}

      <div className="sec mb-3">
        <h2>
          <img src={Pen} alt="" />
          Subject Domains
          <img
            src={Add}
            onClick={handleAddSubjectDomain}
            style={{ marginLeft: "1%", cursor: "pointer" }}
            alt=""
          />
        </h2>
      </div>
      <div className="position-relative overflow-scroll">
        <table className="table-sec table mb-1">
          <thead>
            <tr>
              <th>
                <h3>Subject</h3>
              </th>
              <th>
                <h3>Subject Image</h3>
              </th>
              <th>
                <h3>Subject Logo</h3>
              </th>
              <th>
                <h3>Subject Instances</h3>
              </th>
              <th>
                <h3></h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {subjectArray?.map((subject: any) => {
              return (
                <tr>
                  <td>
                    <p className="mb-0">{subject.name}</p>
                  </td>
                  <td>
                    <img
                      style={{ height: "40px", width: "60px" }}
                      src={subject.image}
                    />
                  </td>
                  <td>
                    <img
                      style={{ height: "190px", width: "180px" }}
                      src={subject.logo}
                    />
                  </td>
                  <td
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      minHeight: "230px",
                    }}
                  >
                    {subject.instances.map((instance: string | undefined) => {
                      return (
                        <td style={{ borderBottom: "none", minHeight: "auto" }}>
                          <p style={{ borderBottom: "none" }}>{instance}</p>
                        </td>
                      );
                    })}
                  </td>
                  <td>
                    <div style={{ display: "flex", gap: "1rem" }}>
                      <img
                        onClick={() => handleEditSubjectArr(subject.id)}
                        src={Edit}
                        style={{
                          transform: "rotate(90deg)",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <img
                        src={Delete}
                        style={{ width: "20px", height: "20px" }}
                        onClick={() => handleDeleteSubjectArr(subject.id)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SubjectTable;
