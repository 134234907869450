import { RequestResponse } from "../../constants";
import { typesenseClient } from "../Config";
import { COLLECTION_NAME_TYPE } from "../Interfaces";
import { CollectionSchema } from "../Schemas";

export const createTitleFieldDocument = async (
  collectionName: COLLECTION_NAME_TYPE,
  title: string
) => {
  try {
    if (!(await typesenseClient.collections(collectionName).exists()))
      await typesenseClient
        .collections()
        .create(CollectionSchema[collectionName]);

    const docResult = await typesenseClient
      .collections(collectionName)
      .documents()
      .create({ title });

    return { ...RequestResponse["201"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const updatedTitleFieldDoc = (
  collectionName: COLLECTION_NAME_TYPE,
  id: string,
  title: string
) =>
  typesenseClient.collections(collectionName).documents(id).update({ title });

export const updateDocument = (
  collectionName: COLLECTION_NAME_TYPE,
  id: string,
  newData: any
) =>
  typesenseClient.collections(collectionName).documents(id).update( newData );

export const getAllDocuments = (
  collectionName: COLLECTION_NAME_TYPE,
  search?: any,
  filterBy?: string,
  sort_by?: string,
  per_page?: number,
  facet_query?: string,
  facet_by?:string
) => {
  search = search ? search : ({ q: "*", query_by: "" } as any);
  return typesenseClient
    .collections(collectionName)
    .documents()
    .search({
      q: search.q.length > 0 ? search.q : "*",
      query_by: search.query_by,
      filter_by: filterBy ? filterBy : "",
      sort_by: sort_by ? sort_by : "",
      facet_query: facet_query ? facet_query :"",
      per_page: per_page!=undefined ? per_page : 10,
      facet_by: facet_by ? facet_by :""
    });
};

export const getDocument = (collectionName: COLLECTION_NAME_TYPE, id: string) =>
  typesenseClient.collections(collectionName).documents(id).retrieve();
export const deleteDocument = (
  collectionName: COLLECTION_NAME_TYPE,
  id: string
) => typesenseClient.collections(collectionName).documents(id).delete();

// export const updateDocument = (collectionName: COLLECTION_NAME_TYPE, id: string) => typesenseClient.collections(collectionName).documents(id).delete()

export const doc_service = {
  getAllDocuments,
  getDocument,
  deleteDocument,
};

export const addMultipleDocumentFields = async () => {
  const grades = ["6th", "7th", "8th", "9th", "10th", "11th", "12th"];
  for (let i = 0; i < grades.length; i++) {
    await createTitleFieldDocument("garde", grades[i]);
  }

  const CongnitiveType = [
    "Create",
    "Evaluate",
    "Analyze",
    "Apply",
    "Understand",
    "Remember",
  ];
  for (let i = 0; i < CongnitiveType.length; i++) {
    await createTitleFieldDocument("congnitive_type", CongnitiveType[i]);
  }

  const ExperienceType = [
    "Writing",
    "Reading",
    "Presentation",
    "Standard",
    "Field",
  ];
  for (let i = 0; i < ExperienceType.length; i++) {
    await createTitleFieldDocument("experience_type", ExperienceType[i]);
  }
};
