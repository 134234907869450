import Pen from "../../../assets/images/pen.svg";
import Add from "../../../assets/images/add.svg";
import Edit from "../../../assets/images/edit.svg";
import Delete from "../../../assets/images/delete.svg";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux";
import { useEffect, useState } from "react";
import EditDialog from "../../../dialogs/consolePageDialogs/EditDialog";
import AddDialog from "../../../dialogs/consolePageDialogs/AddDialog";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";

const CognitiveTable = () => {
  const cognitiveData = useAppSelector(
    (state: any) => state.congnitiveType.data
  );
  const [selectedText, setSelectedText] = useState("");
  const dispatch = useAppDispatch();

  const [cognitiveArray, setcognitivearray] = useState<any>([]);

  const [addCognitiveDialog, setaddCognitiveDialog] = useState(false);
  const [editCognitiveDialog, seteditCognitiveDialog] = useState(false);
  const [deleteCognitiveDialog, setdeleteCognitiveDialog] = useState(false);

  const [selectedId, setselectedId] = useState();

  const handleDeleteCognitiveArr = async (id: number | any) => {
    setdeleteCognitiveDialog(true);
    console.log("id ",selectedId)
    setselectedId(id);
    dispatch(showDailog({title:"Delete Cognitive Domain!",id}))
  };

  const handleEditCognitiveArr = (id: number | any) => {
    const selectedCog = cognitiveArray.find((cog: any) => cog.id === id);

    if (selectedCog) {
      setSelectedText(selectedCog.title);
      console.log(selectedText);
    }

    seteditCognitiveDialog(true);
    setselectedId(id);
  };

  const handleAddCognitiveArray = () => {
    setaddCognitiveDialog(true);
  };

  useEffect(() => {
    setcognitivearray(cognitiveData);
  }, [cognitiveData]);

  return (
    <>
      {addCognitiveDialog && (
        <AddDialog
          open={true}
          type="cognitive"
          cognitiveArray={cognitiveArray}
          setcognitiveArray={setcognitivearray}
          onClose={() => setaddCognitiveDialog(false)}
        />
      )}

      {editCognitiveDialog && (
        <EditDialog
          open={true}
          type="cognitive"
          selectedID={selectedId}
          selectedText={selectedText}
          cognitiveArray={cognitiveArray}
          setcognitiveArray={setcognitivearray}
          onClose={() => seteditCognitiveDialog(false)}
        />
      )}
    

      <div className="section">
        <div className="sec mb-3">
          <h2>
            <img src={Pen} alt="" />
            Cognitive
            <img
              src={Add}
              onClick={handleAddCognitiveArray}
              style={{ marginLeft: "1%", cursor: "pointer" }}
              alt=""
            />
          </h2>
        </div>
        <table className="table-sec table mb-1" style={{ width: "33%" }}>
          <thead>
            <tr>
              <th>
                <h3>Cognitive</h3>
              </th>
            </tr>
          </thead>
          <tbody>
            {cognitiveArray.map((element: any, index: any) => {
              return (
                <>
                  <tr key={index}>
                    <td style={{ display: "flex", width:"220px" }}>
                      <p className="mb-0" style={{ width: "80%" }}>
                        {element.title}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          cursor: "pointer",
                          width:"20%"
                        }}
                      >
                        <img
                          onClick={() => handleEditCognitiveArr(element.id)}
                          src={Edit}
                          style={{ transform: "rotate(90deg)" }}
                        />
                        <img
                          src={Delete}
                          onClick={() => handleDeleteCognitiveArr(element.id)}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CognitiveTable;
