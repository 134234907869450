import { deleteObject, getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { RequestResponse } from "../../constants";
import { firebase_storage } from "../Config";
import path from 'path'

export type uploadDirName = "images" | "console" | "experience"



export const uploadFileToCloud = (dirName: uploadDirName, file: File) => {
    return new Promise<string>((resolve, reject) => {
        const storageRef = ref(firebase_storage, `${dirName}/IMAGE-${Math.floor(Date.now() / 1000)}.${file.name.split('.').pop()}`);
        // const metadata = {
        //     contentType: 'image/jpeg'
        //   };
          
        uploadBytesResumable(storageRef, file).then((snapshot) => {
            getDownloadURL(ref(firebase_storage, snapshot.metadata.fullPath)).then((downloadURL) => {
                resolve(downloadURL)
            })
        }).catch((error) => {
            reject({ ...RequestResponse['500'], error: error.message })
        });
    })
}


export const uploadVideoToCloud = (dirName: uploadDirName, file: File) => {
    return new Promise<Object>((resolve, reject) => {
        const storageRef = ref(firebase_storage, `${dirName}/VIDEO-${Math.floor(Date.now() / 1000)}.${file.name.split('.').pop()}`);
        uploadBytesResumable(storageRef, file).then((snapshot) => {
            getDownloadURL(ref(firebase_storage, snapshot.metadata.fullPath)).then((downloadURL) => {
                resolve({url:downloadURL,metadata:snapshot.metadata})
            })
        }).catch((error) => {
            reject({ ...RequestResponse['500'], error: error.message })
        });
    })
}



export const deleteFileFromCloud = (fileName: string) => {
    return new Promise<string>((resolve, reject) => {
        const deleteRef = ref(firebase_storage, `images/${fileName}`);
        deleteObject(deleteRef).then(function () {
            resolve("file deleted successfully")
        }).catch((error: any) => {
            reject({ ...RequestResponse['500'], error: error.message })
        })
    })

}

export const cloud_service = {
    uploadFileToCloud,
    deleteFileFromCloud
}