

export const RequestResponse = {
    200: {
        "fetched": { code: 200, message: "Record has been fetched successfully." },
        "updated": { code: 200, message: "Record has been updated successfully." },
        "deleted": { code: 200, message: "Record has been deleted successfully." }
    },
    201: { code: 201, message: "Record has been added successfully" },

    302: { code: 302, message: "Record already exist!" },

    400: { code: 400, message: "Bad Request!" },
    403: { code: 403, message: "Permission denied." },
    404: { code: 404, message: "No record found!" },
    
    500: { code: 500, message: "Internal server error!" }
}