import { RequestResponse } from "../../constants";
import { typesenseClient } from "../Config";
import { ISubject, COLLECTION_SUBJECT } from "../Interfaces";
import { CollectionSchema } from "../Schemas";

export const getAllSubjects = (COLLECTION_SUBJECT: any) => typesenseClient.collections(COLLECTION_SUBJECT).documents().search({
    q: "*",
    query_by: "",
  });

  export const deleteAllSubjects = (COLLECTION_SUBJECT: any) => typesenseClient.collections(COLLECTION_SUBJECT).documents().delete({
    filter_by: "*",
  });
  

  export const createSubjetDoc = async (subjectDetail: any) => {
    try {
      const subjectName = subjectDetail.name;
  
      // Check if subject with same name already exists
      const existingSubject = await typesenseClient
        .collections(COLLECTION_SUBJECT)
        .documents()
        .search({ q: subjectName, query_by: "name" });
  
      if (existingSubject.hits?.length ?? 0 > 0) {
        console.error(`Subject with name '${subjectName}' already exists`);
        return { ...RequestResponse["400"], error: `Subject with name '${subjectName}' already exists` };
      }
  
      if (!(await typesenseClient.collections(COLLECTION_SUBJECT).exists()))
        await typesenseClient
          .collections()
          .create(CollectionSchema[COLLECTION_SUBJECT]);
  
      const docResult = await typesenseClient
        .collections(COLLECTION_SUBJECT)
        .documents()
        .create(subjectDetail);
      return { ...RequestResponse["201"], data: docResult };
    } catch (error: any) {
      return { ...RequestResponse["500"], error: error.message };
    }
  };
  
  
  
  
  
  
  
  
  
export const deleteSubjectDoc = async (subjectId: string) => {
  try {
    const docResult = await typesenseClient
      .collections(COLLECTION_SUBJECT)
      .documents(subjectId)
      .delete();
    return { ...RequestResponse["200"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const updatedSubjetDoc = (id: string, updatedSubject: any) =>
  typesenseClient.collections(COLLECTION_SUBJECT).documents(id).update( updatedSubject );


export const subject_service = {
  createSubjetDoc,
  updatedSubjetDoc,
  getAllSubjects,
  deleteSubjectDoc
};
