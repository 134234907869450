import React from "react";
import LoginIcon from "../assets/images/login.svg";
import { Modal } from "react-bootstrap";
import { useAuthProvider } from "../providers";
import { firebase_auth, firebase_service } from "../firebase";
import { showForgotPasswordDailog } from "../redux/features/Dialog/ForgotPasswordDialogSlice";
import { useAppDispatch } from "../redux";

import { showAccountVerificationLinkSentConfirmationDialog } from "../redux/features/Dialog/AccountVerificationDialogSlice";
import { showAlertCommonDialog } from "../redux/features/Dialog/AlertCommonDialogSlice";

interface ISignInDialog {
  open: boolean;
  onClose: () => void;
  onSignupClick: () => void;
}
export const SignInDialog = ({
  open,
  onClose,
  onSignupClick,
}: ISignInDialog) => {
  const [loginState, setLoginState] = React.useState({
    email: "",
    password: "",
  });
  const { changeAuthState } = useAuthProvider();
  const dispatch = useAppDispatch();
  const signInWithEmail = (e: any) => {
    e.preventDefault();
    firebase_service["auth"]
      .signInWithEmail(loginState.email, loginState.password)
      .then(() => {
        onClose();
        if(firebase_auth.currentUser?.emailVerified){
          changeAuthState(true);
        }
        else{
          onClose();
          dispatch(showAccountVerificationLinkSentConfirmationDialog())
        }
      })
      .catch((error) => {
        onClose()
        dispatch(showAlertCommonDialog({title:"login failed",paragraph:"Wrong user email or password",buttonText:"ok"}))
      });
  };

  const signInWithGoogle = (e: any) => {
    e.preventDefault();
    firebase_service["auth"]
      .signInWithGoogle()
      .then((res) => {
        changeAuthState(true);
        onClose();
      })
      .catch((error) => {
        alert(error);
      });
  };
  const forgotPassword = () => {
    onClose();
    dispatch(showForgotPasswordDailog());
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false} id="loginModal">
      <div className="modal-body">
        <div className="text-center">
          <h1 className="model-header-title">Welcome back!</h1>
          <p className="model-header-text">Log back into your account.</p>
        </div>
        <form id="loginsubmit" onSubmit={signInWithEmail}>
          <div className="form">
            <label htmlFor="loginemail">
              <p>Email</p>
            </label>
            <input
              type="Email"
              className="form-control"
              id="loginemail"
              placeholder=""
              required
              value={loginState.email}
              onChange={(e) =>
                setLoginState({ ...loginState, email: e.target.value })
              }
            />
            <label htmlFor="loginpassword">
              <p>Password</p>
            </label>
            <input
              type="password"
              className="form-control"
              id="loginpassword"
              placeholder=""
              required
              value={loginState.password}
              onChange={(e) =>
                setLoginState({ ...loginState, password: e.target.value })
              }
            />
            <p className="forgot-password text-end" onClick={forgotPassword}>
              Forgot your password?
            </p>
            <button type="submit" className="submit-btn">
              <img src={LoginIcon} alt="login-img" style={{ maxWidth: 25 }} />
              <p>Login</p>
            </button>
          </div>
        </form>
        <hr />
        <div>
          <button
            type="button"
            className="google-btn"
            onClick={signInWithGoogle}
          >
            <p>Continue with google</p>
          </button>
          {/* <button type="button" className="apple-btn">
                                            <p>Continue with Apple</p>
                                        </button> */}
        </div>
        <hr />
        <div>
          <p className="text-center">Not part of our community yet?</p>
          <button className="signup-btn" onClick={onSignupClick}>
            <p>sign up</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};
function logoutAsync() {
  throw new Error("Function not implemented.");
}

