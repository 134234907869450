import ReactDOMServer from "react-dom/server";
import { AssignmentModel1Component } from "../../../pages/experience/components/codeEditorhtmlComponents/AssignmentModel1Component";
import { AssignmentModelIcon } from "./SvgStrings";
import { NewTableRowModel } from "../../../pages/experience/components/codeEditorhtmlComponents/NewTableRowModel1";
import { AssignmentModel2Component } from "../../../pages/experience/components/codeEditorhtmlComponents/AssignmentModel2Component";
interface IProps {
  data?: {
    tableData: Array<Array<string>>;
    assissment:string
    comment: string;
    grade: string;
  };
  config?: any;
  api?: any;
  wrapper?: HTMLDivElement;
}
export class AssignmentModel2Tool {
  wrapper: HTMLDivElement;
  data: IProps["data"];
  constructor({ data }: IProps) {
    this.data = data;
    this.wrapper = document.createElement("div");
  }
  static get toolbox() {
    return {
      title: "Assignment Model 2",
      icon: AssignmentModelIcon,
    };
  }
  render() {
    this.wrapper.innerHTML = ReactDOMServer.renderToString(
      <AssignmentModel2Component data={this.data} />
    );
    /////////functions for aplly event //////
    const deleteEventAppy = (deleteButton: any, row: any) => {
      deleteButton?.addEventListener("click", (event: any) => {
        const isConfirm = confirm("Are you sure you want to delete this row?");
        if (isConfirm) {
          row.remove();
        }
      });
    };
    const addEventApply = (addButton: any, row: any) => {
      addButton?.addEventListener("click", (event: any) => {
        const newRow = document.createElement("tr");
        newRow.className = "model-table-row";
        newRow.innerHTML = ReactDOMServer.renderToString(
          <NewTableRowModel model={2} />
        );
        const deleteButton = newRow.querySelector(
          "#model-table-remove-row-button"
        );
        deleteEventAppy(deleteButton, newRow);
        const addButton = newRow.querySelector("#model-table-add-row-button");
        addEventApply(addButton, newRow);
        row.after(newRow);
        console.log(" row addded  :::", newRow);
      });
    };
    ///apply event on add button in header /////
    {
      const addRowHeaderButton = this.wrapper.querySelector(
        "#model-table-add-row-end-button"
      );
      addRowHeaderButton?.addEventListener("click", (event) => {
        const tableBody = this.wrapper.getElementsByTagName("tbody");
        /////create row /////
        const newRow = document.createElement("tr");
        newRow.className = "model-table-row";
        newRow.innerHTML = ReactDOMServer.renderToString(
          <NewTableRowModel model={2} />
        );
        const deleteButton = newRow.querySelector(
          "#model-table-remove-row-button"
        );
        deleteEventAppy(deleteButton, newRow);
        const addButton = newRow.querySelector("#model-table-add-row-button");
        addEventApply(addButton, newRow);
        //////row created /////
        console.log("row", newRow);
        if (tableBody[0].firstChild) {
          tableBody[0].firstChild?.before(newRow);
        } else {
          tableBody[0].appendChild(newRow);
        }
      });
    }
    const rows = this.wrapper.querySelectorAll(".model-table-row");
    rows.forEach((row, index) => {
      ////////delete //////////
      const deleteButton = row.querySelector("#model-table-remove-row-button");
      deleteEventAppy(deleteButton, row);
      ///////apply event on add button in rows
      const addButton = row.querySelector("#model-table-add-row-button");
      addEventApply(addButton, row);
    });
    return this.wrapper;
  }

  save(blockContent: HTMLElement) {
    const assissment = blockContent.querySelector(
      "#model-table-assessment-comment"
    ) as HTMLInputElement;

    const rows: any = blockContent.querySelectorAll(".model-table-row");
    const rowsCount = rows.length;
    const tableData: Array<Array<any>> = [];
    for (let i = 1; i <= rowsCount; i++) {
      tableData.push([]);
    }
    rows.forEach((row: any, rowIndex: number) => {
      const tbData = row.querySelectorAll(".model-table-data");
      const HelpText = blockContent.querySelectorAll(".model-table-data-help");
      const HelpTextArray = [] as Array<string>;
      HelpText.forEach((data: any) => {
        HelpTextArray.push(data?.value);
      });
      tbData.forEach((data: any, dataIndex: number) => {
        if (dataIndex == 0) {
          tableData[rowIndex][dataIndex] = [
            data?.value,
            HelpTextArray[rowIndex],
          ];
        } else {
          tableData[rowIndex][dataIndex] = data?.value as string;
        }
      });
    });
    const comment = blockContent.querySelector(
      "#model-table-comment"
    ) as HTMLInputElement;
    const grade = blockContent.querySelector(
      "#model-table-grade"
    ) as HTMLInputElement;
    console.log("data received :: ", tableData, Array.isArray(tableData));
    return {
      tableData,
      assissment: assissment.value,
      comment: comment.value,
      grade: grade.value,
    };
  }
}
