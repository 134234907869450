import React, { useEffect, useState } from "react";
import GoalIcon from "../../../../assets/images/goal.svg";
import { CodeEditor } from "../../../../components/editor/CodeEditor";
import { IExperience } from "../../../../firebase";

interface IGoalSection {
  value: string;
  editableMode: boolean;
  onChange: <T extends IExperience>(
    key: keyof T
  ) => (value: T[keyof T]) => void;
}
export const GoalSection: React.FC<IGoalSection> = ({
  value,
  editableMode,
  onChange,
}) => {
  const [isEdit, setisEdit] = useState(false);
  useEffect(() => {
    setisEdit(editableMode);
  }, [editableMode]);

  return (
    <div className="sec">
      <h2 style={{marginLeft:isEdit?"44px":"0px"}}>
        <img src={GoalIcon} alt="" />
        Goal
      </h2>
      <div className="goal-section-container">
        <CodeEditor
          element="goal-section"
          editableMode={isEdit}
          initialData={value}
          onChange={(data) => {
            onChange("goal")(data);
          }}
          dataClass="goal-section-data"
        />
      </div>
    </div>
  );
};
