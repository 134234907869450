import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthProvider } from '../../providers';

interface PrivateRouteProps {
    children?: React.ReactNode | any
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { isAuthenticated, userProfile } = useAuthProvider();
    console.log("isAuthenticated, userProfile", isAuthenticated, userProfile)

    const testProf ={ 
        adminUser :" N"
    }

    return userProfile?.adminUser ? children : <Navigate to="/" />
}
