import React from "react";
import RemoveIcon from "../../../../assets/images/close.svg";
import AddIcon from "../../../../assets/images/addIcon2.svg";
const initialFirstCellData = [
  [
    "Factual",
    "Factual Knowleged is knowledge that is basic to specific disciplines. This dimension refers to essential facts, terminology, details or elements students must know or be familiar with in order to understand a discipline or solve a problem in it.",
  ],
  [
    "Conceptual",
    "Conceptual Knowledge is knowledge of classification, principles, generalizations, theories, models, or structures pertinent to a particular disciplinary area.",
  ],
  [
    "Procedural",
    "Procedural Knowledge refers to information of knowledge that helps students to do something specific to a discipline, subject, or area of study. It also refers to methods of inquiry, very specific or finite skills, algorithms, techniques, and particular methodologies.",
  ],
  [
    "Metacognitive",
    "Metacognitive knowledge is the awareness of one's own cognition and particular cognitive processes. It is strategic or reflective knowledge about how to go about solving problems, cognitive tasks, to include contextual and conditional knowledge and knowledge of self.",
  ],
];
const rowsCount = 4;
const tableData: Array<Array<any>> = [];
for (let i = 0; i < rowsCount; i++) {
  tableData.push([]);
  for (let j = 0; j < 7; j++) {
    if (j == 0) {
      tableData[i].push(initialFirstCellData[i]);
    } else {
      tableData[i].push("");
    }
  }
}
interface IProps {
  data?: {
    tableData: Array<Array<string>>;
    assissment: string;
    comment: string;
    grade: string;
  };
}
export const AssignmentModel2Component = ({ data }: IProps) => {
  const renderRows = () => {
    return (data?.tableData ? data.tableData : tableData).map(
      (element, elementIndex) => {
        return (
          <>
            <tr className="model-table-row" key={elementIndex}>
              <td>
                <div>
                  <img
                    src={RemoveIcon}
                    className="model-add-remove-button"
                    id="model-table-remove-row-button"
                  ></img>
                  <img
                    src={AddIcon}
                    id="model-table-add-row-button"
                    className="model-add-remove-button"
                  ></img>
                </div>
              </td>

              {element.map((data, dataIndex) => {
                return (
                  <>
                    {dataIndex == 0 ? (
                      <>
                        <td key={dataIndex}>
                          <p className="p-0">
                            <textarea
                              value={data[0]}
                              className="model-table-data"
                              style={{ width: "100%", border: "none" }}
                              placeholder="Enter text"
                            />
                          </p>
                          <div className="model-table-data-help-cover">
                            <textarea
                              value={data[1]}
                              className="model-table-data-help"
                              style={{ width: "100%", height: "150px" }}
                              placeholder="Enter help text"
                            />
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        {" "}
                        <td key={dataIndex}>
                          <p className="p-0">
                            <textarea
                              value={data}
                              className="model-table-data"
                              style={{ width: "100%", border: "none" }}
                              placeholder="Enter text"
                            />
                          </p>
                        </td>
                      </>
                    )}
                  </>
                );
              })}
            </tr>
          </>
        );
      }
    );
  };
  return (
    <div className="position-relative overflow-scroll mb-4">
      <table className="table-sec table mb-1">
        <thead>
          <tr>
            <th>
              <h3>
                {" "}
                <img
                  src={AddIcon}
                  id="model-table-add-row-end-button"
                  className="model-add-remove-button"
                  alt="add new row at end"
                ></img>{" "}
              </h3>
            </th>
            <th>
              <h3> Knowledge Dimension </h3>
            </th>
            <th>
              <h3> Understand </h3>
            </th>
            <th>
              <h3> Apply </h3>
            </th>
            <th>
              <h3> Analyze </h3>
            </th>
            <th>
              <h3> Evaluate </h3>
            </th>
            <th>
              <h3> Create </h3>
            </th>
            <th>
              <h3> Points </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
          <tr>
            <td></td>
            <td>
              <p className="p-0"> Assessment Comments:</p>
            </td>
            <td colSpan={6}>
              <p className="p-0">
                {" "}
                <textarea
                  className="my-auto d-flex"
                  id="model-table-assessment-comment"
                  style={{
                    width: "100%",
                    backgroundColor: "inherit",
                    border: "none",
                  }}
                  value={data?.assissment}
                  placeholder="Enter assissment"
                />
              </p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>
              <h3> </h3>
            </th>
            <th>
              <h3> Overall Review </h3>
            </th>
            <th colSpan={5}>
              <h3>
                <textarea
                  className="my-auto d-flex"
                  id="model-table-comment"
                  style={{
                    width: "100%",
                    backgroundColor: "inherit",
                    border: "none",
                  }}
                  value={data?.comment}
                  placeholder="Enter review"
                />
              </h3>
            </th>
            <th>
              <h3>
                Grade:
                <textarea
                  id="model-table-grade"
                  style={{
                    width: "100%",
                    backgroundColor: "inherit",
                    border: "none",
                    fontWeight: "bold",
                  }}
                  placeholder="Enter grade"
                  value={data?.grade}
                />
              </h3>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export const AssignmentModel2View = ({ data }: IProps) => {
  const renderRows = () => {
    return (data?.tableData ? data.tableData : tableData).map(
      (element, elementIndex) => {
        return (
          <>
            <tr className="model-table-row" key={elementIndex}>
              {element.map((data, dataIndex) => {
                return (
                  <>
                    {dataIndex == 0 ? (
                      <>
                        <td key={dataIndex}>
                          <p className="Main-Text p-0">{data[0]}</p>
                          <div className="help">{data[1]}</div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td key={elementIndex}>
                          <p className="Main-Text p-0">{data}</p>
                        </td>
                      </>
                    )}
                  </>
                );
              })}
            </tr>
          </>
        );
      }
    );
  };
  return (
    <div className="position-relative overflow-scroll mb-4">
      <table className="table-sec table mb-1">
        <thead>
          <tr>
            <th>
              <h3> Knowledge Dimension </h3>
            </th>
            <th>
              <h3> Understand </h3>
            </th>
            <th>
              <h3> Apply </h3>
            </th>
            <th>
              <h3> Analyze </h3>
            </th>
            <th>
              <h3> Evaluate </h3>
            </th>
            <th>
              <h3> Create </h3>
            </th>
            <th>
              <h3> Points </h3>
            </th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
          <tr>
            <td>
              <p className="p-0"> Assessment Comments:</p>
            </td>
            <td colSpan={6}>
              <p className="p-0"> {data?.assissment}</p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th>
              <h3> Overall Review </h3>
            </th>
            <th colSpan={5}>
              <h3>{data?.comment}</h3>
            </th>
            <th>
              <h3>
                Grade:
                {data?.grade}
              </h3>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
