import React from 'react'
import { Modal } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../redux'
import { closeAccountVerificationLinkSentDailog } from '../redux/features/Dialog/AccountVerificationDialogSlice'

export const AccountVerificationDialog = () => {
    const {isAccountVerificationLinkSent}=useAppSelector((s)=>s.accountVerificationDialog)
    const dispatch=useAppDispatch()
    const open=isAccountVerificationLinkSent
    const onClose=()=>{
        dispatch(closeAccountVerificationLinkSentDailog())
    }
  return (
    <Modal show={open} onHide={onClose} keyboard={false} id="loginModal">
      <div className="modal-forgotpassword">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Account Verification</h1>
            <p className="model-header-text">
              A verification link sent to your email address to activate your account.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
