import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  firebase_service,
  IAddExperience,
  IExperience,
  IpayloadFetchData,
  ISubject,
} from "../../firebase";
import { updateDocument } from "../../firebase/services";
import { RootState } from "../AppStore";

export const fetchExperienceListThunk = createAsyncThunk<
  IExperience[],
  IpayloadFetchData
>(
  "experience/fetchExperienceListThunk",
  async (
    { search, filterBy, sort_by }: IpayloadFetchData,
    { rejectWithValue }
  ) => {
    try {
      const data = await firebase_service["doc"].getAllDocuments(
        "experience",
        search,
        filterBy,
        sort_by
      );
      return data.hits && data.hits.length > 0
        ? (data.hits?.map((doc) => doc.document) as IExperience[])
        : [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewExperienceThunk = createAsyncThunk<
  IExperience,
  IAddExperience
>("experience/addNewExperienceThunk", async (detail, { rejectWithValue }) => {
  try {
    const data = await firebase_service["experience"].createExperienceDoc(
      detail
    );

    return { ...detail, id: data as any } as IExperience;
  } catch (error) {
    return rejectWithValue(error);
  }
});

export const updateExperienceThunk = createAsyncThunk<
  IExperience | any,
  IExperience | any
>("experience/updateExperienceThunk", async (detail, { rejectWithValue }) => {
  try {
    const { id, ...restDetails } = detail;
    const result = await firebase_service["experience"].updatedExperienceDoc(
      id,
      restDetails
    );
    return result as IExperience;
  } catch (error) {
    return rejectWithValue(error);
  }
});

interface Iprops {
  id: string;
  updatedData: any;
}
export const updateExperienceDocumentThunk = createAsyncThunk<any, Iprops>(
  "experience/updateExperienceDocumentThunk",
  async (data, { rejectWithValue }) => {
    const { id, updatedData } = data;
    try {
      const res = await updateDocument("experience", id, { ...updatedData });
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteExperienceThunk = createAsyncThunk<string, string>(
  "experience/deleteExperienceThunk",
  async (id: any, { rejectWithValue }) => {
    try {
      await firebase_service["experience"].deleteExperienceDoc(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export interface ICongnitiveTypeReducer {
  data: IExperience[];
  status: "pending" | "fulfilled" | "rejected";
  error: any;
  isChanged:boolean
}

const initialState: ICongnitiveTypeReducer = {
  data: [],
  status: "pending",
  error: null,
  isChanged:false
};

export const experienceSlice = createSlice({
  name: "experience",
  initialState,
  reducers: {
    setIsChanged:(state,isChanged)=>{
      state.isChanged=isChanged.payload
      return state
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExperienceListThunk.pending, (state) => {
      return { ...state, status: "pending", data: [], error: null };
    });
    builder.addCase(
      fetchExperienceListThunk.fulfilled,
      (state, action: any) => {
        return {
          ...state,
          status: "fulfilled",
          data: action.payload,
          error: null,
        };
      }
    );
    builder.addCase(fetchExperienceListThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", data: [], error: action.error };
    });

    builder.addCase(addNewExperienceThunk.fulfilled, (state, action: any) => {
      state.data.unshift(action.payload.id.data);
      (state.status = "fulfilled"), (state.error = null);
      return state;
    });
    builder.addCase(updateExperienceThunk.fulfilled, (state, action: any) => {
      const expIndex = state.data.findIndex(
        (exp) => exp.id === action.payload.id
      );
      state.data[expIndex] = action.payload;
      return state;
    });
    builder.addCase(
      updateExperienceDocumentThunk.fulfilled,
      (state, action: any) => {
        const expIndex = state.data.findIndex(
          (exp) => exp.id === action.payload.id
        );
        state.data[expIndex] = action.payload;
        return state;
      }
    );
    builder.addCase(deleteExperienceThunk.fulfilled, (state, action: any) => {
      const deletedExp = state.data.find((exp) => {
        return exp.id == action.payload;
      });
      if (deletedExp) {
        const expIndex = state.data.indexOf(deletedExp);
        state.data.splice(expIndex, 1);
        return state;
      }
    });
  },
});

export const experienceList = (state: RootState) => state.experiences.data;
export const experienceListStatus = (state: RootState) =>
  state.experiences.status;
  export const {setIsChanged}=experienceSlice.actions
export default experienceSlice.reducer;
