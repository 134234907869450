import React from "react";
import { useSelector } from "react-redux";
import SearchIcon from "../../../assets/images/search.svg";
import { RootState, useAppDispatch } from "../../../redux";
import { setExperiencesSearch } from "../../../redux/features/filterAndSearchData/FilterSearchExperienceSlice";

export const SearchBar = () => {
  const search = useSelector(
    (state: RootState) => state.filterSearchExperiences.search.q
  );
  const dispatch = useAppDispatch();
  const onSearch = (e: any) => {
    const { value } = e.target;
    dispatch(setExperiencesSearch(value));
  };
  return (
    <div className="search-bar mb-3">
      <input
        type="text"
        className="form-control search-input"
        placeholder="Search"
        style={{ padding: "0rem 4rem" }}
        name="search"
        autoComplete="off"
        value={search}
        onChange={onSearch}
      />
      <img className="img-fluid" src={SearchIcon} alt="search" style={{maxWidth: 22}} />
    </div>
  );
};
