import { createUserWithEmailAndPassword, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut, UserCredential,sendPasswordResetEmail } from "@firebase/auth"
import { RequestResponse } from "../../constants"
import { firebase_auth } from "../Config"
import { SingInProvider } from "../Enums"
import {  IAuthor } from "../Interfaces"

import { author_service } from "./auther.service"

import { sendEmailVerification } from "firebase/auth"

export interface ISignupWithEmail {
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    country: string,
}
export const signupWithEmail = async ({ email, password, firstName, lastName, country }: ISignupWithEmail) => {
    
    try {
        const { user }:any = await createUserWithEmailAndPassword(firebase_auth, email, password)
        
        const newUserDocument: IAuthor = {
            id: user.uid,
            email: email as string,
            firstName,
            lastName,
            country,
            singInProvider: SingInProvider.email,
            simpleUser: true,
            adminUser: false,
            active: true
        }
       await sendEmailVerification(user)
       
        return await author_service.createAuthorDoc(newUserDocument)

    } catch (error: any) {
        return { ...RequestResponse['500'], error: error.message }
    }
}

export const signInWithGoogle = async () => {
    try {
        const googleProvider = new GoogleAuthProvider();
        const { user: { email, uid, displayName } } = await signInWithPopup(firebase_auth, googleProvider);
        const receiveName=displayName?.split(" ")
        const { found: isEmailFound } = await author_service.getAuthorDocByEmail(email as string)
        if (isEmailFound === 0) {
            const newUserDocument: IAuthor = {
                id: uid,
                email: email as string,
                firstName: receiveName? receiveName[0]:""|| "",
                lastName: receiveName? receiveName[1]:'',
                country: '',
                singInProvider: SingInProvider.google,
                simpleUser: true,
                adminUser: false,
                active: true
            }
            
            return await author_service.createAuthorDoc(newUserDocument)
        }
        return RequestResponse['200']["fetched"]
    } catch (error: any) {
        return { ...RequestResponse['500'], error: error.message }
    }
};

export const signInWithEmail = (email: string, password: string) => {
    return new Promise<UserCredential>((resolve, reject) => {
        signInWithEmailAndPassword(firebase_auth, email, password).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject({ ...RequestResponse['500'], error: error.message })
        })
    })
}
///////forgot Password//////////
export const ForgotPassword=async(email:string)=>{
    try {
        await sendPasswordResetEmail(firebase_auth, email)
        return true
    }
 catch(error:any){
    const errorCode = error.code;
    const errorMessage = error.message;
    if(errorCode=="auth/user-not-found"){
        alert(`User not found`)
    }
    else{
        alert(`Error: ${error}`)
    }
    return false
 }
}

export const logout = () => signOut(firebase_auth);


export const auth_service = {
    signupWithEmail,
    signInWithGoogle,
    signInWithEmail,
    logout
}
