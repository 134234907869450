import React from "react";
import bookIcon from "../../../../assets/images/book.svg";

import goalIcon from "../../../../assets/images/goal.svg";

import thinkingIcon from "../../../../assets/images/thinking.svg";
import mailIcon from "../../../../assets/images/mail.svg";
import timelineIcon from "../../../../assets/images/timeline.svg";
import penIcon from "../../../../assets/images/pen.svg";
const parse = require("html-react-parser");
interface IProps {
  data: {
    text: any;
    type?: "h1" | "h2" | "h3";
    beginImageUrl?: any;
  };
}

export const ExistingLogos = [
  {
    name: "book",
    url: bookIcon,
  },
  {
    name: "goal",
    url: goalIcon,
  },
  {
    name: "thinking",
    url: thinkingIcon,
  },
  {
    name: "mail",
    url: mailIcon,
  },
  {
    name: "timeline",
    url: timelineIcon,
  },
  {
    name: "pen",
    url: penIcon,
  },
];
///////heading  /////////
export const CustomHeadingComponent = ({ data }: any) => {
  return (
    <div id="header-tool-container" className="d-flex flex-column gap-1">
      <h1 id="subtitle-tool-text" contentEditable={true}>
        {parse(data.text?data.text:"")}
      </h1>
    </div>
  );
};
export const CustomHeadingView = ({ data, alignment }: any) => {
  return (
    <div style={{ textAlign: alignment ? alignment : "left" }}>
      <h1>{parse(data.text?data.text:"")}</h1>
    </div>
  );
};

////////title /////////
export const CustomTitle = ({ data }: IProps) => {
  return (
    <div id="header-tool-container" className="d-flex flex-column gap-1">
      <h2 contentEditable={true} id="header-tool-text">
        {parse(data.text)}
      </h2>
    </div>
  );
};

export const CustomTitleView = ({ data, alignment }: any) => {
  return (
    <div style={{ textAlign: alignment ? alignment : "left",marginTop:"36px" }}>
      <h2>{parse(data.text)}</h2>
    </div>
  );
};

/////////////subtitle//////////
export const CustomSubtitleComponent = ({ data }: any) => {
  return (
    <div id="header-tool-container" className="d-flex flex-column gap-1">
      <h3 id="subtitle-tool-text" contentEditable={true}>
      {parse(data.text?data.text:"")}
      </h3>
    </div>
  );
};

export const CustomSubtitleView = ({ data, alignment }: any) => {
  return (
    <div style={{ textAlign: alignment ? alignment : "left" }}>
      <h3>{parse(data.text?data.text:"")}</h3>
    </div>
  );
};

//////////////////////////////
////////////end///////////////
//////////////////////////////

/////////////additional functionality extra code ////////////////
export const CustomHeaderComponent = ({ data }: IProps) => {
  const getCurrentHeader = () => {
    switch (data?.type) {
      case "h1":
        return (
          <h1>
            <img
              src={data.beginImageUrl}
              className=""
              alt="begin logo"
              id="header-begin-logo"
              style={{ maxWidth: "30px", top: "35px" }}
            />
            <textarea
              id="header-tool-text"
              placeholder="Enter heading"
              value={data.text}
            />
          </h1>
        );
        break;
      case "h2":
        return (
          <h2>
            <img
              src={data.beginImageUrl}
              className=""
              alt="begin logo"
              id="header-begin-logo"
              style={{ maxWidth: "30px", top: "20px" }}
            />
            <textarea
              id="header-tool-text"
              placeholder="Enter title"
              value={data.text}
            />
          </h2>
        );
        break;
      case "h3":
        return (
          <h3>
            <img
              src={data.beginImageUrl}
              className=""
              alt="begin logo"
              id="header-begin-logo"
              style={{ maxWidth: "30px", top: "20px" }}
            />
            <textarea
              id="header-tool-text"
              placeholder="Enter subtitle"
              value={data.text}
            />
          </h3>
        );
        break;
      default:
        return <></>;
        break;
    }
  };
  return (
    <div
      id="header-tool-container"
      className="rounded-3 p-3 d-flex flex-column gap-1"
      style={{ border: "1px solid black" }}
    >
      <div className="">{getCurrentHeader()}</div>

      <div
        className="text-center p-2 rounded-3 d-flex flex-column gap-2 justify-content-center"
        style={{ border: "1px solid black" }}
      >
        <h3>Begin Logo:</h3>
        <div>
          <select
            className=""
            aria-label="Default select example"
            defaultValue={data.beginImageUrl}
            id="choose-begin-logo"
          >
            <option value="">Other</option>
            {ExistingLogos.map((logo) => {
              return (
                <>
                  <option key={logo.name} value={logo.url}>
                    {logo.name}
                  </option>
                </>
              );
            })}
          </select>
        </div>
        <input
          type="file"
          id="header-begin-logo-file"
          className="mx-auto rounded-3 p-1 "
          style={{ maxWidth: "215px" }}
        />
        <input
          type="text"
          placeholder="Enter image url"
          id="header-logo-url"
          style={{ width: "100%" }}
          value={data.beginImageUrl}
        />
      </div>
    </div>
  );
};

export const CustomHeaderView = ({ data, alignment }: any) => {
  const getCurrentHeader = () => {
    switch (data?.type) {
      case "h1":
        return (
          <h1>
            <img
              src={data.beginImageUrl}
              className=" my-auto"
              alt="begin logo"
              style={{ maxWidth: "30px" }}
            />
            {data.text}
          </h1>
        );
        break;
      case "h2":
        return (
          <h2>
            {" "}
            <img
              src={data.beginImageUrl}
              className="my-auto"
              alt="begin logo"
              style={{ maxWidth: "30px" }}
            />
            {data.text}
          </h2>
        );
        break;
      case "h3":
        return (
          <h3>
            {" "}
            <img
              src={data.beginImageUrl}
              className="  my-auto"
              alt="begin logo"
              style={{ maxWidth: "30px" }}
            />
            {data.text}
          </h3>
        );
        break;
      default:
        return <h1>{data.text}</h1>;
        break;
    }
  };
  return (
    <div style={{ textAlign: alignment ? alignment : "left"}}>
      {getCurrentHeader()}
    </div>
  );
};
