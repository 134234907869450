import React, { useState } from "react";
import { COLLECTION_EXPERIENCE, IAuthor } from "../../../firebase";
import { getAllDocuments, updatedAuthorDoc } from "../../../firebase/services";
import { showAlertCommonDialog } from "../../../redux/features/Dialog/AlertCommonDialogSlice";
import { useAppDispatch } from "../../../redux";

interface IProps {
  User: IAuthor;
  UsersData: IAuthor[];
  setUsersData: any;
}

export const UserPermissionRow = ({
  User,
  UsersData,
  setUsersData,
}: IProps) => {
  let adminEmail="support@schoolexperiencescatalog.org"
  if(process.env.ADMIN_EMAIL){
    adminEmail=process.env.ADMIN_EMAIL
  }
  const dispatch=useAppDispatch()
  const [simpleUser, setsimpleUser] = useState(User.simpleUser);
  const [adminUser, setadminUser] = useState(User.adminUser);

  const checkUserHasAnyExperience = async () => {
    let facet_query = `author.email:${User.email}`;
    let facet_by = "author.email";
    try {
      const res = await getAllDocuments(
        COLLECTION_EXPERIENCE,
        undefined,
        undefined,
        undefined,
        0,
        facet_query,
        facet_by
      );
      let countExp = 0;
      if (res.facet_counts && res.facet_counts[0].counts[0]) {
        countExp = res.facet_counts[0].counts[0].count;
      }
      if (countExp > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      console.log("Error in count experiences : ", err);
      return true;
    }
  };

  //////change permissions///////////
  const onChangeUserAuth = async (e: any, User: IAuthor, userType: string) => {
    const currentUserIndex = UsersData.indexOf(User);
    if (User.email == adminEmail) {
    } else {
      let isUserHasExp = true;
      if (User.simpleUser) {
        isUserHasExp = await checkUserHasAnyExperience();
      } 
      if(!isUserHasExp){
        dispatch(showAlertCommonDialog({title:"Permissions change failed",paragraph:"This user has no experience. You can't change this to admin",buttonText:"ok"}))
      }
      if (isUserHasExp) {
        if (userType == "simpleUser") {
          User.simpleUser = !User.simpleUser;
          User.adminUser = !User.simpleUser;
        } else if (userType == "adminUser") {
          User.adminUser = !User.adminUser;
          User.simpleUser = !User.adminUser;
        }
        try {
          await updatedAuthorDoc(User.id, User);
          setUsersData((pre: any) => {
            pre[currentUserIndex] = User;
            return pre;
          });
          setsimpleUser(User.simpleUser);
          setadminUser(User.adminUser);
          console.log("render ", User);
        } catch (err) {
          alert(`Error in update Author! ,Error: ${err}`);
        }
      }
    }
  };

  return (
    <>
      <tr key={User.id}>
        <td>
          <p style={{ wordWrap: "break-word", width: "22vw" }}>{User.id}</p>
        </td>
        <td>
          <p>{User.lastName}</p>
        </td>
        <td>
          <p>{User.firstName}</p>
        </td>
        <td>
          <p>{User.email}</p>
        </td>
        <td>
          <label className="switch">
            <input
              type="checkbox"
              id="simpleUser"
              onChange={(e: any) => onChangeUserAuth(e, User, "simpleUser")}
              checked={simpleUser}
            />
            <span className="slider round"></span>
          </label>
        </td>

        <td>
          <label className="switch">
            <input
              id="superUser"
              type="checkbox"
              onChange={(e: any) => onChangeUserAuth(e, User, "adminUser")}
              checked={adminUser}
            />
            <span
              className="slider round"
              style={{
                backgroundColor: User.email == adminEmail ? "#ccc" : "",
              }}
            ></span>
          </label>
        </td>
        <td>
          <p>{User.singInProvider}</p>
        </td>
      </tr>
    </>
  );
};
