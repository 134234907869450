import { createSlice } from "@reduxjs/toolkit";

export const CreateNewPasswordDialogSlice = createSlice({
  name: "CreateNewPassword",
  initialState: {
    isCreateNewPasswordDialog: false,
    newPassword:"",
    oobCode:""
  },
  reducers: {
    showCreateNewPasswordDialog: (s) => {
      s.isCreateNewPasswordDialog = true;
      return s;
    },
    closeCreateNewPasswordDialog: (s) => {
      s.isCreateNewPasswordDialog = false;
      return s;
    },
    setNewPassword:(s,password)=>{
      s.newPassword=password.payload
    },
    setobbCodeForNewPassword:(s,oobCode)=>{
      s.oobCode=oobCode.payload
      return s
    }
  },
});

export const {showCreateNewPasswordDialog,closeCreateNewPasswordDialog,setobbCodeForNewPassword}=CreateNewPasswordDialogSlice.actions

export const CreateNewPasswordDialogReducer=CreateNewPasswordDialogSlice.reducer