import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAlertDialog: false,
  title: "",
  paragraph: "",
  buttonText: "",
};

export const AlertCommonDialogSlice = createSlice({
  name: "AlertDialog",
  initialState: initialState,
  reducers: {
    showAlertCommonDialog: (s, data) => {
      s.isAlertDialog = true;
      s.title = data.payload.title;
      s.paragraph = data.payload.paragraph;
      s.buttonText = data.payload.buttonText;
      return s;
    },
    closeAlertCommonDialog: (s) => {
      s = initialState;
      return s;
    },
  },
});

export const {showAlertCommonDialog,closeAlertCommonDialog}=AlertCommonDialogSlice.actions

export const AlertCommonDialogReducer=AlertCommonDialogSlice.reducer