import React, { useEffect, useMemo, useState } from "react";
import BookIcon from "../../../../assets/images/book.svg";
import { CodeEditor } from "../../../../components/editor/CodeEditor";
import { IExperience } from "../../../../firebase";
import { CustomAudioTest } from "../../components/codeEditorhtmlComponents/CustomMediaPerview";
import { AssignmentModel1Component } from "../../components/codeEditorhtmlComponents/AssignmentModel1Component";

interface IBackgroundSection {
  value: string;
  editableMode: boolean;
  onChange: <T extends IExperience>(
    key: keyof T
  ) => (value: T[keyof T]) => void;
}
export const BackgroundSection: React.FC<IBackgroundSection> = ({
  value,
  editableMode,
  onChange,
}) => {
  const [isEdit, setisEdit] = useState(false);
  useEffect(() => {
    
    setisEdit(editableMode);
  }, [editableMode]);
  ///////////code editor////////////
  const renderCodeEditor = () => (
    <div className="bg-section-container">
      <CodeEditor
        element="bg-section"
        editableMode={isEdit}
        initialData={value}
        onChange={(data) => {
          onChange("background")(data);
        }}
        dataClass="bg-section-data"
      />
    </div>
  );

  return (
    <div className="sec">
      <h2 style={{marginLeft:isEdit?"44px":"0px"}}>
        <img src={BookIcon} alt="" />
        Background
      </h2>
      {/* <AssignmentModel1Component/> */}
      {/* <CustomAudioTest/> */}
      {/* {!editableMode ?
                <p>{value}</p>
                :
                <textarea className='experienc_background_area' value={value} onChange={e => onChange(e.target.value)} />
            } */}
      <div className="bg-section-container">{renderCodeEditor()}</div>
    </div>
  );
};
