import React from "react";
import { CommonDialog } from "../../dialogs/CommonDialog";
import ExperienceHeader from "./components/ExperienceHeader";
import MainSection from "./components/MainSection";
import { CustomCard } from "./components/codeEditorhtmlComponents/CustomCard";

const Experience = () => {
  // const audioButton = document.querySelector(
  //   "audio::-webkit-media-controls-play-button"
  // );
  // console.log()
  return (
    <>
      <ExperienceHeader />
      <MainSection />
    </>
  );
};

export default Experience;
