import { RequestResponse } from "../../constants";
import { typesenseClient } from "../Config";
import { COLLECTION_AUTHOR, IAuthor } from "../Interfaces";
import { CollectionSchema } from "../Schemas";

export const createAuthorDoc = async (authorDetail: IAuthor) => {
  try {
    if (!(await typesenseClient.collections(COLLECTION_AUTHOR).exists()))
      await typesenseClient
        .collections()
        .create(CollectionSchema[COLLECTION_AUTHOR]);
    const docResult = await typesenseClient
      .collections(COLLECTION_AUTHOR)
      .documents()
      .create(authorDetail);
    return { ...RequestResponse["201"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const getAuthorDocByEmail = (email: string) =>
  typesenseClient.collections(COLLECTION_AUTHOR).documents().search({
    q: email,
    query_by: "email",
  });

export const getAuthorDoc = (search: string) =>
  typesenseClient.collections(COLLECTION_AUTHOR).documents().search({
    q: search,
    query_by: "email,firstName,lastName",
  });

interface IUpdateAuthor extends Omit<IAuthor, "email"> {}
export const updatedAuthorDoc = (id: string, authorDetail: IUpdateAuthor) =>
  typesenseClient
    .collections(COLLECTION_AUTHOR)
    .documents(id)
    .update(authorDetail);

export const author_service = {
  createAuthorDoc,
  getAuthorDocByEmail,
  getAuthorDoc,
  updatedAuthorDoc,
};
