import {
    author_service,
    auth_service,
    cloud_service,
    doc_service,
    subject_service
} from "./services";
import { congnitive_type_service } from "./services/congnitiveType.service";
import { experience_service } from "./services/experience.service";
import { experience_type_service } from "./services/experienceType.service";
import { grade_service } from "./services/grade.service";
export * from './Config'
export * from './Interfaces'


export const firebase_service = {
    auth: auth_service,
    cloud: cloud_service,
    doc: doc_service,
    author: author_service,
    subject: subject_service,
    experience: experience_service,
    experience_type: experience_type_service,
    grade: grade_service,
    congnitive_type: congnitive_type_service
}