import { createSlice } from "@reduxjs/toolkit";

export const AccountVerificationDialogSlice = createSlice({
  name: "AccountVerification",
  initialState: {
    isAccountVerificationLinkSent: false,
    isAccountVerifiedSuccessDialog:false,
    obbCode:"",
    isAccountVerificationLinkExpire:false,
    isAccountVerificationLinkSentConfirmationDialog:false
  },
  reducers: {
    showAccountVerificationLinkSentDailog: (s) => {
      s.isAccountVerificationLinkSent = true;
      return s;
    },
    closeAccountVerificationLinkSentDailog: (s) => {
      s.isAccountVerificationLinkSent = false;
      return s;
    },
    showAccountVerifiedSuccessDialog:(s)=>{
      s.isAccountVerifiedSuccessDialog=true
      return s
    },closeAccountVerifiedSuccessDialog:(s)=>{
      s.isAccountVerifiedSuccessDialog=false
      return s
    },
    setobbCodeForAccountVerification:(s,obbCode)=>{
     s.obbCode=obbCode.payload
     return s
    },
    showAccountVerificationLinkExpireDialog:(s)=>{
      s.isAccountVerificationLinkExpire=true
    },
    closeAccountVerificationLinkExpireDialog:(s)=>{
      s.isAccountVerificationLinkExpire=false
    }
    ,showAccountVerificationLinkSentConfirmationDialog:(s)=>{
      s.isAccountVerificationLinkSentConfirmationDialog=true
    },
    closeAccountVerificationLinkSentConfirmationDialog:(s)=>{
      s.isAccountVerificationLinkSentConfirmationDialog=false
    }
  },
});

export const {showAccountVerificationLinkSentDailog,closeAccountVerificationLinkSentDailog,showAccountVerifiedSuccessDialog,closeAccountVerifiedSuccessDialog,setobbCodeForAccountVerification,showAccountVerificationLinkExpireDialog,closeAccountVerificationLinkExpireDialog,showAccountVerificationLinkSentConfirmationDialog,closeAccountVerificationLinkSentConfirmationDialog}=AccountVerificationDialogSlice.actions

export const AccountVerificationReducer=AccountVerificationDialogSlice.reducer