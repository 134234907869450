import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RootState, useAppDispatch } from "../../../redux";
import { setExperiencesFilter } from "../../../redux/features/filterAndSearchData/FilterSearchExperienceSlice";
import { useSelector } from "react-redux";

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const AppCategories = () => {
  const sliderRef = useRef<any>(null);

  const subjectArray = useSelector((state: RootState) => state.subjects.data);

  useEffect(() => {
    console.log(subjectArray, "subjectArray");
  }, []);




  const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
  
  const appDispatch = useAppDispatch();
  // const subjects = useSelector((s:RootState)=>s.subjects)

  const CustomPrevArrow = (props: {
    className: any;
    style: any;
    onClick: any;
  }) => {
    const { className, style, onClick } = props;

    useEffect(() => {
      const slider = sliderRef.current;
      if (slider) {
        const { slidesToShow } = settings;
        const slideCount = slider.state.slideCount;
        if (slidesToShow >= slideCount) {
          slider.update({
            slidesToShow: slideCount,
            arrows: false,
          });
        } else if (!settings.arrows) {
          slider.update({
            arrows: true,
          });
        }
      }
    }, [settings]);
    return (
      <>
        <FontAwesomeIcon
          onClick={onClick}
          icon={faChevronLeft}
          id="faLeft"
          style={{
            color: "#000000",
            position: "absolute",
            top: "40%",
            left: "0.5%",
            cursor: "pointer",
            fontWeight: "bolder",
            transform: "scale(1.6)",
            zIndex:1
          }}
        />
      </>
    );
  };
  const CustomNextArrow = (props: {
    className: any;
    style: any;
    onClick: any;
  }) => {
    const { className, style, onClick } = props;
    return (
      <>
        <FontAwesomeIcon
        id="faRight"
          style={{
            position: "absolute",
            top: "45%",
            right: "0%",
            cursor: "pointer",
            transform: "scale(1.6)",
            zIndex:1  
          }}
          onClick={onClick}
          icon={faChevronRight}
        />
      </>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    Infinity:true,
    slidesToShow: subjectArray.length <=6 ? subjectArray.length : 6 ,
    slidesToScroll: 1,
    arrows: subjectArray.length !== null ? true : false,
    pauseOnHover: false,
    
    prevArrow: (
      <CustomPrevArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    nextArrow: (
      <CustomNextArrow
        className={undefined}
        style={undefined}
        onClick={undefined}
      />
    ),
    cssEase: "linear",
   
  
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const onClickHandler = (value: any, index: any) => {
    const newIndexes = [...selectedIndexes];
    const currentIndex = newIndexes.indexOf(index);
    if (currentIndex > -1) {
      newIndexes.splice(currentIndex, 1);
    } else {
      newIndexes.push(index);

      if (newIndexes.length > 1) {
        const prevIndex = newIndexes.shift();
        setSelectedIndexes(newIndexes);
      }
    }

    setSelectedIndexes(newIndexes);
    const obj = {
      value,
      field: "subject",
    };
    appDispatch(setExperiencesFilter(obj));
  };
 
  const renderCourses = () => {
    return subjectArray.map((subject: any, index: any) => {
      return (
        <div key={index}>
          <div
            className="slider-img"

            style={{
              backgroundColor: selectedIndexes.includes(index)
                ? "rgba(249, 238, 248)"
                : "white",
              border: selectedIndexes.includes(index)
                ? "1px solid purple"
                : "1px solid silver",
            }}
            onClick={() => onClickHandler(subject.name, index)}
          >
            <img
              className="img-fluid"
              src={subject.image}
              height={100}
              width={100}
            />
          </div>
          <p className="mb-0">{subject.name}</p>
        </div>
      );
    });
  };

  return (
    <div style={{ padding: "1.35rem .25rem" }}>
      <Slider  {...settings} ref={sliderRef}>{renderCourses()}</Slider>
    </div>
  );
};
