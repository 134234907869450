import React, { ReactNode } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { firebase_auth, firebase_service, IAuthor } from '../firebase'



export interface IAuthProvider {
    isAuthenticated: boolean,
    userProfile: IAuthor | null
    changeAuthState: (state: boolean) => void
    logoutAsync: () => void
}

const AuthContext = React.createContext<IAuthProvider>({
    isAuthenticated: false,
    userProfile: null,
    changeAuthState: () => { },
    logoutAsync: () => { },
})

export const useAuthProvider = () => React.useContext(AuthContext)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isLogined, setIsLogined] = React.useState<boolean>(false)
    const [userProfile, setUserProfile] = React.useState<IAuthor | null>(null)
    const [user, loading, error] = useAuthState(firebase_auth);

    React.useEffect(() => {
        if (loading) return
        if (user) {
            firebase_service["doc"].getDocument('author', user.uid).then((result) => {
                setIsLogined(true)
                setUserProfile(result as IAuthor)
                console.log("firebase_service useEffect ==>", result)
            }).catch((error) => {
                console.log("firebase_service useEffect ==>")
            })
            return
        } else {
            setUserProfile(null)
            setIsLogined(false)
        }
    }, [user, isLogined])


    const logoutAsync = () => {
        firebase_service["auth"].logout().then(() => {
            setIsLogined(false)
            
        })
    }
    const setUserData=()=>{
        
    }
    return (
        <AuthContext.Provider value={{
            isAuthenticated: isLogined,
            userProfile: userProfile,
            changeAuthState: (state) => setIsLogined(state),
            logoutAsync
        }}>
            {children}
        </AuthContext.Provider>
    )
}



