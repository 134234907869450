import Typesense from 'typesense'
import { initializeApp } from 'firebase/app';
import { getAuth, verifyBeforeUpdateEmail } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

/////////////////////////////////////////////////////////////////////////
//---------------------- Firebase  Configuration ----------------------//
/////////////////////////////////////////////////////////////////////////
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.MEASUREMENT_ID
};

export const firebase_app = initializeApp(firebaseConfig);
export const firebase_auth = getAuth(firebase_app);
export const firebase_db = getFirestore(firebase_app);
export const firebase_storage = getStorage(firebase_app);



/////////////////////////////////////////////////////////////////////////
//---------------------- Typesense Configuration ----------------------//
/////////////////////////////////////////////////////////////////////////
export const typesenseClient = new Typesense.Client({
    'nodes': [{
        'host': process.env.REACT_APP_TYPESENSE_HOST as string, // For Typesense Cloud use xxx.a1.typesense.net
        'port': Number(process.env.REACT_APP_TYPESENSE_PORT),      // For Typesense Cloud use 443
        'protocol': process.env.REACT_APP_TYPESENSE_PROTOCOL as string   // For Typesense Cloud use https
    }],
    'apiKey': process.env.REACT_APP_TYPESENSE_API_KEY as string,
    'connectionTimeoutSeconds': Number(process.env.REACT_APP_TYPESENSE_CONNECTION_TIMEOUT)
})