import React from 'react'
import Message from "../../../assets/images/massage.svg";
import Danger from "../../../assets/images/danger.svg";
import Close from "../../../assets/images/close.svg";
import True from "../../../assets/images/true.svg";
import Pen from "../../../assets/images/pen.svg";

const TableSection = () => {
  return (
    <>
          {/* table Start */}
          <div className="position-relative overflow-scroll" >
            <table className="table-sec table mb-1">
              <thead>
                <tr>
                  <th>
                    <h3>cognitive level</h3>
                  </th>
                  <th>
                    <h3>outcome</h3>
                  </th>
                  <th>
                    <h3>assessment</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="mb-0">Cognitive level 1</p>
                  </td>
                  <td>
                    <p className="mb-0">
                      After this experience, students will improve their
                      expressions of feelings through Art.
                    </p>
                  </td>
                  <td>
                    <p className="mb-0">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Congue nisi vitae suscipit tellus mauris a diam
                      maecenas sed. Duis at consectetur lorem donec massa
                      sapien. Proin sagittis nisl rhoncus mattis rhoncus.
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-5">
            <h3>Table.1 - Table without Table Footer.</h3>
          </div>
          {/* table End */}
          <div className="sec mb-3">
            <h2>
              <img src={Pen} alt="" />
              Table Example 2
            </h2>
          </div>
          {/* table Start */}
          <div className="position-relative overflow-scroll">
            <table className="table-sec table mb-1">
              <thead>
                <tr>
                  <th>
                    <h3> Category </h3>
                  </th>
                  <th>
                    <h3> Learning </h3>
                  </th>
                  <th>
                    <h3> Intermediate </h3>
                  </th>
                  <th>
                    <h3> Proficient </h3>
                  </th>
                  <th>
                    <h3> Mastered </h3>
                  </th>
                  <th>
                    <h3> Points </h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p> 1 </p>
                  </td>
                  <td>
                    <p> 34 </p>
                  </td>
                  <td>
                    <p> 2300002 </p>
                  </td>
                  <td>
                    <p> 1000 </p>
                  </td>
                  <td>
                    <p> 100 </p>
                  </td>
                  <td>
                    <p> 5000 </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> 1 </p>
                  </td>
                  <td>
                    <p> 34 </p>
                  </td>
                  <td>
                    <p> 2300002 </p>
                  </td>
                  <td>
                    <p> 1000 </p>
                  </td>
                  <td>
                    <p> 100 </p>
                  </td>
                  <td>
                    <p> 5000 </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> 1 </p>
                  </td>
                  <td>
                    <p> 34 </p>
                  </td>
                  <td>
                    <p> 2300002 </p>
                  </td>
                  <td>
                    <p> 1000 </p>
                  </td>
                  <td>
                    <p> 100 </p>
                  </td>
                  <td>
                    <p> 5000 </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> 1 </p>
                  </td>
                  <td>
                    <p> 34 </p>
                  </td>
                  <td>
                    <p> 2300002 </p>
                  </td>
                  <td>
                    <p> 1000 </p>
                  </td>
                  <td>
                    <p> 100 </p>
                  </td>
                  <td>
                    <p> 5000 </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> 1 </p>
                  </td>
                  <td>
                    <p> 34 </p>
                  </td>
                  <td>
                    <p> 2300002 </p>
                  </td>
                  <td>
                    <p> 1000 </p>
                  </td>
                  <td>
                    <p> 100 </p>
                  </td>
                  <td>
                    <p> 5000 </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="mb-5">
            <h3>Table.2 - Table without Table Footer.</h3>
          </div>
          {/* table End */}
          <div className="sec mb-3">
            <h2>
              <img src={Pen} alt="" />
              Assessment. Model 1
            </h2>
          </div>
          {/* table Start */}
          <div className="position-relative overflow-scroll">
            <table className="table-sec table mb-1">
              <thead>
                <tr>
                  <th>
                    <h3> Skill </h3>
                  </th>
                  <th>
                    <h3> Learning </h3>
                  </th>
                  <th>
                    <h3> Intermediate </h3>
                  </th>
                  <th>
                    <h3> Proficient </h3>
                  </th>
                  <th>
                    <h3> Mastered </h3>
                  </th>
                  <th>
                    <h3> Points </h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    <h3> Coments </h3>
                  </th>
                  <th colSpan={4}>
                    <h3>. </h3>
                  </th>
                  <th>
                    <h3> Grade: </h3>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="mb-5">
            <h3>Table.3 - Assessment Model 1.</h3>
          </div>
          {/* table End */}
          {/* table Start */}
          <div className="sec mb-3">
            <h2>
              <img src={Pen} alt="" />
              Assessment Model 2
            </h2>
          </div>
          <div className="position-relative overflow-scroll">
            <table className="table-sec table mb-1">
              <thead>
                <tr>
                  <th>
                    <h3> Knowledge Dimension </h3>
                  </th>
                  <th>
                    <h3> Understand </h3>
                  </th>
                  <th>
                    <h3> Apply </h3>
                  </th>
                  <th>
                    <h3> Analyze </h3>
                  </th>
                  <th>
                    <h3> Evaluate </h3>
                  </th>
                  <th>
                    <h3> Create </h3>
                  </th>
                  <th>
                    <h3> Points </h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div>
                      <p className="Main-Text"> Factual </p>
                      <div className="help">
                        Factual Knowleged is knowledge that is basic to specific
                        disciplines. This dimension refers to essential facts,
                        terminology, details or elements students must know or
                        be familiar with in order to understand a discipline or
                        solve a problem in it.
                      </div>
                    </div>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <p />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <p className="Main-Text"> Conceptual </p>
                      <div className="help">
                        Conceptual Knowledge is knowledge of classification,
                        principles, generalizations, theories, models, or
                        structures pertinent to a particular disciplinary area.
                      </div>
                    </div>
                  </td>
                  <td>
                    <p> </p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <p />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <p className="Main-Text"> Procedural </p>
                      <div className="help">
                        Procedural Knowledge refers to information of knowledge
                        that helps students to do something specific to a
                        discipline, subject, or area of study. It also refers to
                        methods of inquiry, very specific or finite skills,
                        algorithms, techniques, and particular methodologies.
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <p> </p>
                  </td>
                  <td></td>
                  <td>
                    <p />
                  </td>
                </tr>
                <tr>
                  <td>
                    <div>
                      <p className="Main-Text"> Metacognitive </p>
                      <div className="help">
                        Metacognitive knowledge is the awareness of one's own
                        cognition and particular cognitive processes. It is
                        strategic or reflective knowledge about how to go about
                        solving problems, cognitive tasks, to include contextual
                        and conditional knowledge and knowledge of self.
                      </div>
                    </div>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <p></p>
                  </td>
                  <td>
                    <p />
                  </td>
                </tr>
                <tr>
                  <td>
                    <p> Assessment Comments:</p>
                  </td>
                  <td>
                    <p />
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <p />
                  </td>
                  <td>
                    <p />
                  </td>
                  <td>
                    <p> </p>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>
                    <h3> Overall Review </h3>
                  </th>
                  <th colSpan={5}>
                    <h3> </h3>
                  </th>
                  <th>
                    <h3> Grade: </h3>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="mb-5">
            <h3>Table.4 - Assessment Model 2</h3>
          </div>
          {/* table End */}
    </>
  )
}

export default TableSection
