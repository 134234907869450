import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { firebase_service, IGrade } from "../../firebase";
import { RootState } from "../AppStore";

export const fetchGradeListThunk = createAsyncThunk<IGrade[], void>(
  "grade/fetchGradeListThunk",
  async (_: void, { rejectWithValue }) => {
    try {
      const data = await firebase_service["doc"].getAllDocuments("garde");
      return data.hits?.reverse() && data.hits.length > 0
        ? (data.hits?.map((doc) => doc.document) as IGrade[])
        : [];
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addNewGradeThunk = createAsyncThunk<IGrade, string>(
  "grades/addNewGradeThunk",
  async (name, { rejectWithValue }) => {
    try {
      const newId = await firebase_service["grade"].createGradeDoc(name);
      return { id: newId, title: name, timestamp: new Date().getTime() };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);



export const deleteGradeThunk = createAsyncThunk<any, string>(
  "grade/deleteGradeThunk",
  async (id: any, { rejectWithValue }) => {
    try {
      await firebase_service["grade"].deleteGradeDoc(id);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const updateGradeThunk = createAsyncThunk<
  string,
  { id: string; updatedText: string }
>(
  "grade/updateGradeThunk",
  async ({ id, updatedText }, { rejectWithValue }) => {
    try {
      await firebase_service["grade"].updatedGradeDoc(id, updatedText);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export interface IGradeReducer {
  data: IGrade[];
  status: "pending" | "fulfilled" | "rejected";
  error: any;
}

const initialState: IGradeReducer = {
  data: [],
  status: "pending",
  error: null,
};

export const gardeSlice = createSlice({
  name: "grade",
  initialState,
  reducers: {
    gradeArray: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGradeListThunk.pending, (state) => {
      return { ...state, status: "pending", data: [], error: null };
    });
    builder.addCase(fetchGradeListThunk.fulfilled, (state, action) => {
      return {
        ...state,
        status: "fulfilled",
        data: action.payload,
        error: null,
      };
    });
    builder.addCase(fetchGradeListThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", data: [], error: action.error };
    });
  
    builder.addCase(deleteGradeThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });

    builder.addCase(deleteGradeThunk.fulfilled, (state, action) => {
      state.status = "fulfilled";
      state.data = state.data.filter((item) => item.id !== action.payload);
    });

    builder.addCase(deleteGradeThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.payload };
    });
    builder.addCase(addNewGradeThunk.pending, (state) => {
      return { ...state, status: "pending", error: null };
    });
    builder.addCase(addNewGradeThunk.fulfilled, (state, action) => {
      state.data.push(action.payload);
      return { ...state, status: "fulfilled", error: null };
    });
    builder.addCase(addNewGradeThunk.rejected, (state, action) => {
      return { ...state, status: "rejected", error: action.error };
    });
  },
});

export const gradesList = (state: RootState) => state.grades.data;

export default gardeSlice.reducer;
