import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import HandIcon from "../assets/images/hand.svg";
import SuccessIcon from "../assets/images/true.svg";
import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch, useAppSelector } from "../redux";
import { useNavigate } from "react-router-dom";
import { closeAccountVerifiedSuccessDialog } from "../redux/features/Dialog/AccountVerificationDialogSlice";
interface Iprops {
  open: boolean;
}
export const VerifyAccountDialog = ({ setLoginDialog }: any) => {
  const dispatch = useAppDispatch();
  const {isAccountVerifiedSuccessDialog}=useAppSelector(s=>s.accountVerificationDialog)
  const navigate = useNavigate()
  const onClose = () => {};
    useEffect(() => {
      setTimeout(() => {
        dispatch(closeAccountVerifiedSuccessDialog())
        setLoginDialog(true)
        navigate("/")
      }, 2000);
    }, [])
    
  return (
    <Modal show={isAccountVerifiedSuccessDialog} onHide={onClose} keyboard={false} id="loginModal">
      <div className="modal-forgotpassword">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Account Verification</h1>
            <img
              src={SuccessIcon}
              alt="login-img"
              style={{ maxWidth: "25px", maxHeight: "25px" }}
            />
            <p className=" mt-4 model-header-text">
              Your account is successfuly verified
            </p>
          </div>
          {/* <hr /> */}
          {/* <p className="model-header-text">
         
          </p> */}
          {/* <form id="forgotemailsubmit">
            <div className="form">
       
              <button className="submit-btn" >
                <img
                  src={KeyIcon}
                  alt="login-img"
                  style={{ maxWidth: "25px", maxHeight: "25px" }}
                />
                <p>Verify Email</p>
              </button>
            </div>
          </form> */}
        </div>
      </div>
    </Modal>
  );
};
