import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AddButton from "../../../assets/images/add.svg";
import { useAuthProvider } from "../../../providers";
import { addNewExperienceThunk } from "../../../redux/reducers/ExperienceReducer";
import { InitialAddExperienceState } from "../../experience/create_new/InitialExperienceState";
import { useDispatch } from "react-redux";
import { IAddExperience, IExperience } from "../../../firebase";
import { useAppDispatch } from "../../../redux";

export const AddExperienceButton: React.FC = () => {
  const { isAuthenticated, userProfile } = useAuthProvider();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onAddClick = async () => {
    try {
      const newExp = { ...InitialAddExperienceState } as IExperience;
      newExp.author = userProfile
        ? userProfile
        : InitialAddExperienceState.author;
      const rep: any = await dispatch(
        addNewExperienceThunk(newExp as IAddExperience)
      );
      const newExpId = rep.payload.id.data.id;
      console.log("new data ", newExpId);
      console.log("data id", newExpId);
      navigate(`/experience/${newExpId}?mode=edit`);
    } catch (error) {
      console.log("error in added new exp from home page", error);
    }
  };
  if (!isAuthenticated) return <></>;

  return (
    <>
      <div className="d-none d-lg-block pe-4">
        <button
          className="options-btn"
          onClick={onAddClick}
          style={{ textDecoration: "none" }}
        >
          <img className="img-fluid" src={AddButton} alt="home" />
          <h3>ADD</h3>
        </button>
      </div>
      <div className="d-flex d-lg-none pe-4 position-relative">
        <button
          className="options-btn"
          onClick={onAddClick}
          style={{ textDecoration: "none" }}
        >
          <img className="img-fluid" src={AddButton} alt="home" />
          <h3>ADD</h3>
        </button>
      </div>
    </>
  );
};
