export const CustomCardIcon=`<svg
xmlns:dc="http://purl.org/dc/elements/1.1/"
xmlns:cc="http://creativecommons.org/ns#"
xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
xmlns:svg="http://www.w3.org/2000/svg"
xmlns="http://www.w3.org/2000/svg"
xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
version="1.1"
x="0px"
y="0px"
width="32px"
height="32px"
viewBox="0 0 32 32"
enable-background="new 0 0 32 32"
xml:space="preserve"
id="svg2"
inkscape:version="0.48.4 r9939"
sodipodi:docname="credit_card.svg"><defs
  id="defs3073" /><sodipodi:namedview
  pagecolor="#ffffff"
  bordercolor="#666666"
  borderopacity="1"
  objecttolerance="10"
  gridtolerance="10"
  guidetolerance="10"
  inkscape:pageopacity="0"
  inkscape:pageshadow="2"
  inkscape:window-width="640"
  inkscape:window-height="480"
  id="namedview3071"
  showgrid="false"
  inkscape:zoom="7.375"
  inkscape:cx="16"
  inkscape:cy="16"
  inkscape:window-x="53"
  inkscape:window-y="24"
  inkscape:window-maximized="0"
  inkscape:current-layer="svg2" /><metadata
  id="metadata3063"><rdf:RDF><cc:Work
      rdf:about=""><dc:format>image/svg+xml</dc:format><dc:type
        rdf:resource="http://purl.org/dc/dcmitype/StillImage" /><dc:title>PICOL Icon</dc:title><dc:date>2011-08-08</dc:date><dc:creator><cc:Agent><dc:title>Melih Bilgil</dc:title></cc:Agent></dc:creator><dc:rights><cc:Agent><dc:title>Creative Commons BY-SA</dc:title></cc:Agent></dc:rights><dc:publisher><cc:Agent><dc:title>PICOL - Pictorial Communication Language</dc:title></cc:Agent></dc:publisher><dc:identifier>http://blog.picol.org</dc:identifier><dc:source>http://blog.picol.org</dc:source><dc:language>en</dc:language><dc:subject><rdf:Bag><rdf:li>PICOL</rdf:li><rdf:li>icon</rdf:li><rdf:li>icons</rdf:li><rdf:li>sign</rdf:li><rdf:li>GUI</rdf:li><rdf:li>vector</rdf:li><rdf:li>interface</rdf:li></rdf:Bag></dc:subject><dc:description>This is one icon out of all PICOL icons</dc:description><dc:contributor><cc:Agent><dc:title>Melih Bilgil (www.lonja.de)</dc:title></cc:Agent></dc:contributor><cc:license
        rdf:resource="http://creativecommons.org/licenses/by-sa/3.0/" /></cc:Work><cc:License
      rdf:about="http://creativecommons.org/licenses/by-sa/3.0/"><cc:permits
        rdf:resource="http://creativecommons.org/ns#Reproduction" /><cc:permits
        rdf:resource="http://creativecommons.org/ns#Distribution" /><cc:requires
        rdf:resource="http://creativecommons.org/ns#Notice" /><cc:requires
        rdf:resource="http://creativecommons.org/ns#Attribution" /><cc:permits
        rdf:resource="http://creativecommons.org/ns#DerivativeWorks" /><cc:requires
        rdf:resource="http://creativecommons.org/ns#ShareAlike" /></cc:License></rdf:RDF></metadata><g
  id="background"><rect
    fill="none"
    width="32"
    height="32"
    id="rect3066" /></g><path
  id="path3069"
  d="M 31.513,8.014 C 31.054,7.028 29.843,5.965 27.999,6 H 4 C 2.146,5.965 0.935,7.057 0.48,8.054 -0.006,9.044 0.004,9.916 0,10 v 11.999 c 0.004,0.083 -0.006,0.956 0.48,1.947 C 0.928,24.927 2.108,26 3.913,26 3.942,26 3.971,26 4,25.999 h 23.999 c 0.083,-0.006 0.956,0.007 1.946,-0.481 0.997,-0.453 2.089,-1.664 2.054,-3.519 V 9.938 C 31.993,9.852 32.006,8.991 31.513,8.014 z M 2.033,9.695 C 2.083,9.376 2.227,8.908 2.5,8.589 2.781,8.276 3.125,8.014 4,8 h 23.999 c 1.156,0.035 1.444,0.438 1.734,0.923 0.126,0.247 0.198,0.519 0.233,0.723 C 30,9.85 29.99,9.937 29.998,9.938 V 10 H 2 C 2,10 1.998,9.905 2.033,9.695 z m 27.019,14.034 c -0.255,0.128 -0.537,0.202 -0.748,0.235 -0.21,0.036 -0.305,0.028 -0.305,0.034 H 4 C 2.853,23.964 2.564,23.554 2.268,23.051 2.141,22.796 2.068,22.514 2.034,22.301 1.998,22.094 2.006,21.999 2,21.999 V 14 h 27.999 v 7.999 c -0.035,1.146 -0.444,1.436 -0.947,1.73 z" /></svg>`

  export const h_Icon=`<svg fill="#000000" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
  width="800px" height="800px" viewBox="0 0 31.29 31.29" xml:space="preserve"
 >
<g>
 <g>
     <path d="M18.585,31.226v-1.833h0.577c0.497,0,0.961-0.036,1.395-0.105c0.436-0.072,0.812-0.219,1.131-0.438
         s0.576-0.537,0.769-0.948c0.192-0.412,0.287-0.959,0.287-1.643V16.03H8.547v10.229c0,0.683,0.095,1.229,0.287,1.644
         c0.192,0.41,0.448,0.729,0.768,0.947c0.32,0.221,0.699,0.365,1.14,0.438c0.442,0.07,0.902,0.106,1.386,0.106h0.577v1.833H0v-1.833
         h0.555c0.497,0,0.961-0.036,1.396-0.106c0.434-0.071,0.813-0.217,1.141-0.438c0.326-0.221,0.582-0.537,0.769-0.947
         c0.184-0.412,0.275-0.961,0.275-1.644v-21.4c0-0.639-0.096-1.153-0.287-1.545c-0.194-0.392-0.454-0.693-0.78-0.906
         C2.742,2.195,2.363,2.056,1.927,1.992c-0.431-0.065-0.89-0.096-1.373-0.096H0V0.063h12.705v1.833h-0.577
         c-0.483,0-0.943,0.035-1.386,0.107c-0.44,0.07-0.819,0.217-1.14,0.437C9.283,2.66,9.027,2.976,8.834,3.389
         C8.642,3.8,8.547,4.348,8.547,5.03v8.823h14.195V5.03c0-0.683-0.096-1.23-0.287-1.642s-0.448-0.729-0.768-0.948
         c-0.32-0.22-0.696-0.366-1.131-0.437c-0.435-0.072-0.897-0.107-1.396-0.107h-0.577V0.063h12.705v1.833h-0.553
         c-0.498,0-0.961,0.035-1.396,0.107c-0.434,0.07-0.812,0.217-1.141,0.437c-0.326,0.22-0.582,0.536-0.768,0.948
         c-0.186,0.411-0.277,0.959-0.277,1.642v21.442c0,0.64,0.097,1.155,0.287,1.545c0.192,0.392,0.453,0.687,0.779,0.884
         c0.326,0.2,0.705,0.331,1.141,0.396c0.434,0.064,0.892,0.096,1.373,0.096h0.556v1.833H18.585z"/>
 </g>
</g>
</svg>`
  export const h1_Icon=`<svg width="16" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M2.14 1.494V4.98h4.62V1.494c0-.498.098-.871.293-1.12A.927.927 0 0 1 7.82 0c.322 0 .583.123.782.37.2.246.3.62.3 1.124v9.588c0 .503-.101.88-.303 1.128a.957.957 0 0 1-.779.374.921.921 0 0 1-.77-.378c-.193-.251-.29-.626-.29-1.124V6.989H2.14v4.093c0 .503-.1.88-.302 1.128a.957.957 0 0 1-.778.374.921.921 0 0 1-.772-.378C.096 11.955 0 11.58 0 11.082V1.494C0 .996.095.623.285.374A.922.922 0 0 1 1.06 0c.321 0 .582.123.782.37.199.246.299.62.299 1.124zm11.653 9.985V5.27c-1.279.887-2.14 1.33-2.583 1.33a.802.802 0 0 1-.563-.228.703.703 0 0 1-.245-.529c0-.232.08-.402.241-.511.161-.11.446-.25.854-.424.61-.259 1.096-.532 1.462-.818a5.84 5.84 0 0 0 .97-.962c.282-.355.466-.573.552-.655.085-.082.246-.123.483-.123.267 0 .481.093.642.28.161.186.242.443.242.77v7.813c0 .914-.345 1.371-1.035 1.371-.307 0-.554-.093-.74-.28-.187-.186-.28-.461-.28-.825z"/></svg>
        `
  export const h2_Icon=`<svg width="18" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M2.152 1.494V4.98h4.646V1.494c0-.498.097-.871.293-1.12A.934.934 0 0 1 7.863 0c.324 0 .586.123.786.37.2.246.301.62.301 1.124v9.588c0 .503-.101.88-.304 1.128a.964.964 0 0 1-.783.374.928.928 0 0 1-.775-.378c-.194-.251-.29-.626-.29-1.124V6.989H2.152v4.093c0 .503-.101.88-.304 1.128a.964.964 0 0 1-.783.374.928.928 0 0 1-.775-.378C.097 11.955 0 11.58 0 11.082V1.494C0 .996.095.623.286.374A.929.929 0 0 1 1.066 0c.323 0 .585.123.786.37.2.246.3.62.3 1.124zm10.99 9.288h3.527c.351 0 .62.072.804.216.185.144.277.34.277.588 0 .22-.073.408-.22.56-.146.154-.368.23-.665.23h-4.972c-.338 0-.601-.093-.79-.28a.896.896 0 0 1-.284-.659c0-.162.06-.377.182-.645s.255-.478.399-.631a38.617 38.617 0 0 1 1.621-1.598c.482-.444.827-.735 1.034-.875.369-.261.676-.523.922-.787.245-.263.432-.534.56-.81.129-.278.193-.549.193-.815 0-.288-.069-.546-.206-.773a1.428 1.428 0 0 0-.56-.53 1.618 1.618 0 0 0-.774-.19c-.59 0-1.054.26-1.392.777-.045.068-.12.252-.226.554-.106.302-.225.534-.358.696-.133.162-.328.243-.585.243a.76.76 0 0 1-.56-.223c-.149-.148-.223-.351-.223-.608 0-.31.07-.635.21-.972.139-.338.347-.645.624-.92a3.093 3.093 0 0 1 1.054-.665c.426-.169.924-.253 1.496-.253.69 0 1.277.108 1.764.324.315.144.592.343.83.595.24.252.425.544.558.875.133.33.2.674.2 1.03 0 .558-.14 1.066-.416 1.523-.277.457-.56.815-.848 1.074-.288.26-.771.666-1.45 1.22-.677.554-1.142.984-1.394 1.29a3.836 3.836 0 0 0-.331.44z"/></svg>
  `
  export const h3_Icon=`<svg width="18" height="14" xmlns="http://www.w3.org/2000/svg"><path d="M2.152 1.494V4.98h4.646V1.494c0-.498.097-.871.293-1.12A.934.934 0 0 1 7.863 0c.324 0 .586.123.786.37.2.246.301.62.301 1.124v9.588c0 .503-.101.88-.304 1.128a.964.964 0 0 1-.783.374.928.928 0 0 1-.775-.378c-.194-.251-.29-.626-.29-1.124V6.989H2.152v4.093c0 .503-.101.88-.304 1.128a.964.964 0 0 1-.783.374.928.928 0 0 1-.775-.378C.097 11.955 0 11.58 0 11.082V1.494C0 .996.095.623.286.374A.929.929 0 0 1 1.066 0c.323 0 .585.123.786.37.2.246.3.62.3 1.124zm11.61 4.919c.418 0 .778-.123 1.08-.368.301-.245.452-.597.452-1.055 0-.35-.12-.65-.36-.902-.241-.252-.566-.378-.974-.378-.277 0-.505.038-.684.116a1.1 1.1 0 0 0-.426.306 2.31 2.31 0 0 0-.296.49c-.093.2-.178.388-.255.565a.479.479 0 0 1-.245.225.965.965 0 0 1-.409.081.706.706 0 0 1-.5-.22c-.152-.148-.228-.345-.228-.59 0-.236.071-.484.214-.745a2.72 2.72 0 0 1 .627-.746 3.149 3.149 0 0 1 1.024-.568 4.122 4.122 0 0 1 1.368-.214c.44 0 .842.06 1.205.18.364.12.679.294.947.52.267.228.47.49.606.79.136.3.204.622.204.967 0 .454-.099.843-.296 1.168-.198.324-.48.64-.848.95.354.19.653.408.895.653.243.245.426.516.548.813.123.298.184.619.184.964 0 .413-.083.812-.248 1.198-.166.386-.41.73-.732 1.031a3.49 3.49 0 0 1-1.147.708c-.443.17-.932.256-1.467.256a3.512 3.512 0 0 1-1.464-.293 3.332 3.332 0 0 1-1.699-1.64c-.142-.314-.214-.573-.214-.777 0-.263.085-.475.255-.636a.89.89 0 0 1 .637-.242c.127 0 .25.037.367.112a.53.53 0 0 1 .232.27c.236.63.489 1.099.759 1.405.27.306.65.46 1.14.46a1.714 1.714 0 0 0 1.46-.824c.17-.273.256-.588.256-.947 0-.53-.145-.947-.436-1.249-.29-.302-.694-.453-1.212-.453-.09 0-.231.01-.422.028-.19.018-.313.027-.367.027-.25 0-.443-.062-.579-.187-.136-.125-.204-.299-.204-.521 0-.218.081-.394.245-.528.163-.134.406-.2.728-.2h.28z"/></svg>
  `

  export const AssignmentModelIcon=`<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
  <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
  <g><g transform="matrix(1 0 0 -1 0 1952)"><path d="M990,1141.7v620.7c0,18-14.6,32.7-32.7,32.7H42.7c-18,0-32.7-14.6-32.7-32.7v-620.7c0-18,14.6-32.7,32.7-32.7h914.7C991.7,1109,990,1141.7,990,1141.7L990,1141.7z M271.3,1141.7H42.7v130.7h228.7V1141.7L271.3,1141.7z M271.3,1305H42.7v130.7h228.7V1305z M271.3,1468.3H42.7V1599h228.7V1468.3z M271.3,1631.7H42.7v130.7h228.7V1631.7z M499,1141.7H304v130.7h196L499,1141.7z M499,1305H304v130.7h196L499,1305z M304,1468.3l0,130.7h195l1-130.7H304L304,1468.3z M500,1631.7H304v130.7h196V1631.7z M728.7,1141.7h-195l-2,130.7h197V1141.7L728.7,1141.7z M728.7,1305h-195l-2,130.7h197V1305L728.7,1305z M728.7,1468.3h-196l-1,130.7h197V1468.3L728.7,1468.3z M728.7,1631.7h-196l-1,130.7h197V1631.7L728.7,1631.7z M957.3,1141.7h-196l-1,130.7h197V1141.7L957.3,1141.7z M957.3,1305h-196l-1,130.7h197V1305L957.3,1305z M957.3,1468.3h-197V1599h197V1468.3L957.3,1468.3z M957.3,1631.7h-196l-1,130.7h197V1631.7L957.3,1631.7z M238.7,1403H75.3v-65.3h163.3V1403z M238.7,1239.7H75.3v-65.3h163.3V1239.7z M238.7,1566.3H75.3V1501h163.3V1566.3z M467.3,1729.7H336.7v-65.3h130.7V1729.7z M696,1729.7H565.3v-65.3H696V1729.7z M238.7,1729.7H75.3v-65.3h163.3V1729.7z M924.7,1729.7H794v-65.3h130.7V1729.7z"/></g></g>
  </svg>`