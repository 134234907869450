import filterIcon from "../../../assets/images/filters.svg";

const FilterButton = (props: any) => {
  return (
    <>
      <div
        onClick={props.handleButtonClick}
            className="custom-filter-btn gap-1"
      >
        <img
          className=""
          height={"24px"}
          width={"32px"}
          src={filterIcon}
          alt=""
        />
        <h3
          className="text-uppercase mb-0"
          style={{
            fontSize: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <span>Filters</span>
          <span className="number" style={{marginInline:"5px"}}> ({props.FilterCount})</span>
        </h3>
      </div>
    </>
  );
};

export default FilterButton;
