import React from "react";
import RemoveIcon from "../../../../assets/images/close.svg";
import AddIcon from "../../../../assets/images/addIcon2.svg";
const rowsCount = 5;
const tableData: Array<Array<string>> = [];
for (let i = 1; i <= rowsCount; i++) {
  tableData.push(["", "", "", "", "", ""]);
}
interface IProps {
  data?: {
    tableData: Array<Array<string>>;
    comment: string;
    grade: string;
  };
}
export const AssignmentModel1Component = ({ data }: IProps) => {
  const renderRows = () => {
    return (data?.tableData ? data.tableData : tableData).map(
      (element, elementIndex) => {
        return (
          <>
            <tr className="model-table-row" key={elementIndex}>
              <td>
                <div>
                  <img
                    src={RemoveIcon}
                    className="model-add-remove-button"
                    id="model-table-remove-row-button"
                  ></img>
                  <img
                    src={AddIcon}
                    id="model-table-add-row-button"
                    className="model-add-remove-button"
                  ></img>
                </div>
              </td>

              {element.map((data, dataIndex) => {
                return (
                  <>
                    <td key={dataIndex}>
                      <p className="p-0">
                        <textarea
                          value={data}
                          className="model-table-data"
                          style={{ width: "100%", border: "none" }}
                          placeholder="Enter text"
                        />
                      </p>
                    </td>
                  </>
                );
              })}
            </tr>
          </>
        );
      }
    );
  };
  return (
    
      <div className="position-relative overflow-scroll mb-4">
        <table className="table-sec table mb-1">
          <thead>
            <tr>
              <th>
                <h3>
                  {" "}
                  <img
                    src={AddIcon}
                    id="model-table-add-row-end-button"
                    className="model-add-remove-button"
                    alt="add new row at end"
                  ></img>{" "}
                </h3>
              </th>
              <th>
                <h3> Skill </h3>
              </th>
              <th>
                <h3> Learning </h3>
              </th>
              <th>
                <h3> Intermediate </h3>
              </th>
              <th>
                <h3> Proficient </h3>
              </th>
              <th>
                <h3> Mastered </h3>
              </th>
              <th>
                <h3> Points </h3>
              </th>
            </tr>
          </thead>
          <tbody>{renderRows()}</tbody>
          <tfoot>
            <tr>
              <th>
                <h3> </h3>
              </th>
              <th>
                <h3> Comments </h3>
              </th>
              <th colSpan={4}>
                <h3>
                  <textarea
                    className="my-auto d-flex"
                    id="model-table-comment"
                    style={{
                      width: "100%",
                      backgroundColor: "inherit",
                      border: "none",
                    }}
                    value={data?.comment}
                    placeholder="Enter comment"
                  />
                </h3>
              </th>
              <th>
                <h3>
                  Grade:
                  <textarea
                    id="model-table-grade"
                    style={{
                      width: "100%",
                      backgroundColor: "inherit",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    placeholder="Enter grade"
                    value={data?.grade}
                  />
                </h3>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
   
  );
};

export const AssignmentModel1View = ({ data }: IProps) => {
  const renderRows = () => {
    return (data?.tableData ? data.tableData : tableData).map(
      (element, elementIndex) => {
        return (
          <>
            <tr className="model-table-row" key={elementIndex}>
              {element.map((data, dataIndex) => {
                return (
                  <>
                    <td key={elementIndex}>
                      <p className="p-0">{data}</p>
                    </td>
                  </>
                );
              })}
            </tr>
          </>
        );
      }
    );
  };
  return (
    <div className="position-relative overflow-scroll mb-4">
      <table className="table-sec table mb-1">
        <thead>
          <tr>
            <th>
              <h3> Skill </h3>
            </th>
            <th>
              <h3> Learning </h3>
            </th>
            <th>
              <h3> Intermediate </h3>
            </th>
            <th>
              <h3> Proficient </h3>
            </th>
            <th>
              <h3> Mastered </h3>
            </th>
            <th>
              <h3> Points </h3>
            </th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
        <tfoot>
          <tr>
            <th>
              <h3> Comments </h3>
            </th>
            <th colSpan={4}>
              <h3>{data?.comment}</h3>
            </th>
            <th>
              <h3>
                Grade:
                {data?.grade}
              </h3>
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};
