import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { firebase_service, IExperienceType, IGrade } from '../../firebase'
import { RootState } from '../AppStore'

export const fetchExperienceTypeListThunk = createAsyncThunk<IGrade[], void>(
    "experience_type/fetchExperienceTypeListThunk",
    async (_: void, { rejectWithValue }) => {
        try {
            const data = await firebase_service["doc"].getAllDocuments("experience_type")
            return data.hits?.reverse() && data.hits.length > 0 ? data.hits?.map((doc) => doc.document) as IExperienceType[] : []
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const addNewExperienceTypeThunk = createAsyncThunk<any, any>(
    "experience_type/addNewExperienceTypeThunk",
    async (detail, { rejectWithValue }) => {
      try {
        const data = await firebase_service["experience_type"].createExperienceTypeDoc(detail);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const deleteExperienceTypeThunk = createAsyncThunk<any, string>(
    "experience_type/deleteExperienceTypeThunk",
    async (id: any, { rejectWithValue }) => {
      try {
        await firebase_service["experience_type"].deleteExperienceTypeeDoc(id);
        return id;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  );

  export const updateExoerienceTypeThunk = createAsyncThunk<
  string,
  { id: string; updatedText: string }
>(
  "experience_type/updateExoerienceTypeThunk",
  async ({ id, updatedText }, { rejectWithValue }) => {
    try {
      await firebase_service["experience_type"].updatedExperienceTypeDoc(id, updatedText);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export interface IExperienceTypeReducer {
    data: IExperienceType[],
    status: 'pending' | 'fulfilled' | 'rejected'
    error: any
}

const initialState: IExperienceTypeReducer = {
    data: [],
    status: "pending",
    error: null
}

export const experienceTypeSlice = createSlice({
    name: 'experienceType',
    initialState,
    reducers: {
      experienceArray: (state, action) => {
        state.data = action.payload;
      }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchExperienceTypeListThunk.pending, (state) => {
            return { ...state, status: "pending", data: [], error: null }
        })
        builder.addCase(fetchExperienceTypeListThunk.fulfilled, (state, action: any) => {
            return { ...state, status: "fulfilled", data: action.payload, error: null }
        })
        builder.addCase(fetchExperienceTypeListThunk.rejected, (state, action) => {
            return { ...state, status: "rejected", data: [], error: action.error }
        })

        builder.addCase(deleteExperienceTypeThunk.pending, (state) => {
            return { ...state, status: "pending", error: null };
          });
      
          builder.addCase(deleteExperienceTypeThunk.fulfilled, (state, action) => {
            state.status = "fulfilled";
            state.data = state.data.filter((item) => item.id !== action.payload);
          });
      
          builder.addCase(deleteExperienceTypeThunk.rejected, (state, action) => {
            return { ...state, status: "rejected", error: action.payload };
          });
    },
})

export const experienceTypeList = (state: RootState) => state.experienceType.data

export default experienceTypeSlice.reducer