import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HandIcon from "../assets/images/hand.svg";
import KeyIcon from "../assets/images/key.svg";
import "./ForgotPasswordDialogStyle.css";
import { useAppDispatch } from "../redux";
import { closeForgotPasswordDailog, showRecoverPasswordLinkSentDialog } from "../redux/features/Dialog/ForgotPasswordDialogSlice";
import { ForgotPassword } from "../firebase/services";
interface Iprops {
  open: boolean;
}
export const ForgotPasswordDialog = ({ open }: any) => {
  const dispatch = useAppDispatch();
  const onClose = () => {
    dispatch(closeForgotPasswordDailog());
  };
  const [Email, setEmail] = useState("")
  const onSendMeLink = async(e: any) => {
    e.preventDefault();
    const isLinkSent=await ForgotPassword(Email)
    onClose()
    if(isLinkSent){
        dispatch(showRecoverPasswordLinkSentDialog())
    }
  };
  return (
    <Modal show={open} onHide={onClose} keyboard={false} id="loginModal">
      <div className="modal-forgotpassword">
        <div className="modal-body">
          <div className="text-center">
            <h1 className="model-header-title">Password forgotten?</h1>
            <p className="model-header-text">
              No worries, it happens to all of us and we’re here to help!
              <img
                className="d-block m-auto"
                src={HandIcon}
                alt="head"
                style={{ maxWidth: "25px", maxHeight: "25px" }}
              />
            </p>
          </div>
          <hr />
          <p className="model-header-text">
            Please insert the email that you would like to use to change your
            password.
          </p>
          <form id="forgotemailsubmit">
            <div className="form">
              <label htmlFor="forgotemail">
                <p>Email</p>
              </label>
              <input
                type="email"
                className="form-control"
                id="forgotemail"
                placeholder=""
                value={Email}
                onChange={(e:any)=>setEmail(e.target.value)}
                required
              />
              <button className="submit-btn" onClick={onSendMeLink}>
                <img
                  src={KeyIcon}
                  alt="login-img"
                  style={{ maxWidth: "25px", maxHeight: "25px" }}
                />
                <p>Send me a link</p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
