import React from "react";
import { Modal } from "react-bootstrap";
import { firebase_auth, firebase_service } from "../firebase";
import { CountriesList } from "../assets/json/countries";
import { sendEmailVerification, sendSignInLinkToEmail } from "firebase/auth";
import { useAppDispatch, useAppSelector } from "../redux";
import { showAccountVerificationLinkSentDailog } from "../redux/features/Dialog/AccountVerificationDialogSlice";
import { useDispatch } from "react-redux";
import { showAlertCommonDialog } from "../redux/features/Dialog/AlertCommonDialogSlice";
import { isPasswordValid } from "../helper/PasswordValidator";

interface ISignUpDialog {
  open: boolean;
  onClose: () => void;
}


export const SignUpDialog = ({ open, onClose }: ISignUpDialog) => {
  const [signupState, setSignupState] = React.useState({
    email: "",
    password: "",
    confirmPassword: "",
    firstName: "",
    lastName: "",
    country: "US",
  });

  const { isAccountVerificationLinkSent } = useAppSelector(
    (s) => s.accountVerificationDialog
  );
  const dispatch = useAppDispatch();
  const handleSignUpClick = async (e: any) => {
    e.preventDefault();
    if (isPasswordValid(signupState.password)) {
      if (signupState.password.trim() !== signupState.confirmPassword.trim())
        return alert("Password and confirm password doesn't match");
      const { confirmPassword, ...details } = signupState;
      firebase_service["auth"]
        .signupWithEmail(details)
        .then((res) => {
          firebase_auth.signOut()
          onClose();
          dispatch(showAccountVerificationLinkSentDailog());
        })
        .catch((error) => {
          alert(error);
        });
    }
    else{
        dispatch(showAlertCommonDialog({title:"",paragraph:"password not match with regex pattern Password should contains numbers, letters (small and capital), and special characters",buttonText:"ok"}))
    }
  };

  return (
    <Modal show={open} onHide={onClose} keyboard={false} id="signupModal">
      <div className="modal-body">
        <div className="text-center">
          <h1 className="model-header-title">Come join us!</h1>
          <p className="model-header-text">
            Create an account to manage and add experiences.{" "}
          </p>
        </div>
        <form id="signupsubmit" className="row" onSubmit={handleSignUpClick}>
          <div className="col-md-6 col-12">
            <label htmlFor="fname">
              <p>First Name</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="fname"
              placeholder=""
              required
              value={signupState.firstName}
              onChange={(e) =>
                setSignupState({ ...signupState, firstName: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 col-12">
            <label htmlFor="lname">
              <p>Last Name</p>
            </label>
            <input
              type="text"
              className="form-control"
              id="lname"
              placeholder=""
              required
              value={signupState.lastName}
              onChange={(e) =>
                setSignupState({ ...signupState, lastName: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 col-12">
            <label htmlFor="country">
              <p>Country of residence</p>
            </label>
            <select
              className="form-select"
              aria-label="Default select example"
              required
              value={signupState.country}
              onChange={(e) =>
                setSignupState({ ...signupState, country: e.target.value })
              }
            >
              {CountriesList.map((country) => {
                return <option value={country.code}>{country.name}</option>;
              })}
            </select>
          </div>
          <div className="col-md-6 d-none d-md-block"></div>
          <div className="col-md-6 col-12">
            <label htmlFor="loginemail">
              <p>Email</p>
            </label>
            <input
              type="email"
              className="form-control"
              id="loginemail"
              placeholder=""
              required
              value={signupState.email}
              onChange={(e) =>
                setSignupState({ ...signupState, email: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 col-12 d-none d-md-block"></div>
          <div className="col-md-6 col-12">
            <label htmlFor="signuppassword">
              <p>Password</p>
            </label>
            <input
              type="password"
              className="form-control"
              id="signuppassword"
              placeholder=""
              required
              minLength={6}
              maxLength={12}
              value={signupState.password}
              onChange={(e) =>
                setSignupState({ ...signupState, password: e.target.value })
              }
            />
          </div>
          <div className="col-md-6 col-12">
            <label htmlFor="confirmpassword">
              <p>Confirm password</p>
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmpassword"
              placeholder=""
              required
              value={signupState.confirmPassword}
              onChange={(e) =>
                setSignupState({
                  ...signupState,
                  confirmPassword: e.target.value,
                })
              }
              minLength={6}
              maxLength={12}
            />
          </div>
          <div className="col-12">
            <button type="submit" className="submit-btn d-block">
              <p>Sign up</p>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
