import React from "react";
import { CutomImageComponent } from "../../../pages/experience/components/codeEditorhtmlComponents/ImageComponent";
import ReactDOMServer from "react-dom/server";
import noImageFound from "../../../assets/images/no-image-found.jpg";
import { ToolConfig } from "@editorjs/editorjs";
interface IimageTool {
  data?: {
    url: string;
    caption: string;
    width: number;
    height: number;
    isPixel: boolean;
    isPercentage: Boolean;
  };
  wrapper?: any;
  config?: ToolConfig;
  api?: any;
}
export class CustomImage {
  wrapper: IimageTool["wrapper"];
  config: IimageTool["config"];
  data: IimageTool["data"];
  constructor({ data, config }: IimageTool) {
    this.data = data;
    this.config = config;
  }
  static get toolbox() {
    return {
      title: "Custom Image",
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>',
    };
  }

  render() {
    this.wrapper = document.createElement("div");
    this.wrapper.innerHTML = ReactDOMServer.renderToString(
      <CutomImageComponent data={this.data}></CutomImageComponent>
    );
    const inputImageUrl = this.wrapper.querySelector("#input-image-url");
    const inputImageFile = this.wrapper.querySelector("#input-image-by-file");
    const inputImageCaption = this.wrapper.querySelector(
      "#image-by-url-caption"
    );
    const customImage = this.wrapper.querySelector("#custom-img-tool");

    inputImageUrl.value = this.data?.url ? this.data.url : "";
    inputImageCaption.value = this.data?.caption ? this.data.caption : "";
    customImage.src = this.data?.url ? this.data.url : noImageFound;

    //////image added on paste///////
    this.wrapper
      .querySelector("#input-image-url")
      .addEventListener("paste", (event: any) => {
        customImage.src = event.clipboardData.getData("text");
        inputImageFile.value = "";
      });
    ////////set height width ///////
    this.wrapper.querySelector("#input-image-width").value = this.data?.width
      ? this.data.width
      : 50;
    customImage.style.width = `${this.data?.width ? this.data.width : 50}${
      this.data?.isPixel ? "px" : "vw"
    }`;
    this.wrapper.querySelector(".image-tool-container").style.width = `${
      this.data?.width ? this.data.width : 50
    }${this.data?.isPixel ? "px" : "%"}`;
    this.wrapper.querySelector("#input-image-height").value = this.data?.height
      ? this.data.height
      : 50;

    customImage.style.height = `${this.data?.height ? this.data.height : 50}${
      this.data?.isPixel ? "px" : "vh"
    }`;

    ////////onchange height width in edit mode ///////////
    this.wrapper
      .querySelector("#input-image-width")
      .addEventListener("change", (event: any) => {
        this.wrapper.querySelector(".image-tool-container").style.width = `${
          event.target.value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "%"}`;
        customImage.style.width = `${event.target.value}${
          this.wrapper.querySelector("#isByPixel").checked ? "px" : "vw"
        }`;
      });
    this.wrapper
      .querySelector("#input-image-height")
      .addEventListener("change", (event: any) => {
        customImage.style.height = `${event.target.value}${
          this.wrapper.querySelector("#isByPixel").checked ? "px" : "vh"
        }`;
      });
    ///////////set height width by pixel or percentage ////////////

    this.wrapper
      .querySelector("#isByPixel")
      .addEventListener("change", (event: any) => {
        this.wrapper.querySelector("#isByPercentage").checked = false;
        //setting width on switch
        this.wrapper.querySelector("#input-image-width").value = 358;
        this.wrapper.querySelector(".image-tool-container").style.width = `${
          this.wrapper.querySelector("#input-image-width").value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "%"}`;
        //seting height on switch
        this.wrapper.querySelector("#input-image-height").value = 358;
        customImage.style.height = `${
          this.wrapper.querySelector("#input-image-height").value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "vh"}`;
        ///////////////end///////////

        this.wrapper.querySelector("#isByPixel").checked = true;
        console.log("run on change pixel :", event.target);
      });
    this.wrapper
      .querySelector("#isByPercentage")
      .addEventListener("change", (event: any) => {
        this.wrapper.querySelector("#isByPixel").checked = false;
        this.wrapper.querySelector("#isByPercentage").checked = true;
        //setting width on switch
        this.wrapper.querySelector("#input-image-width").value = 50;
        this.wrapper.querySelector(".image-tool-container").style.width = `${
          this.wrapper.querySelector("#input-image-width").value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "%"}`;

        customImage.style.width = `${
          this.wrapper.querySelector("#input-image-width").value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "vw"}`;
        //seting height on switch
        this.wrapper.querySelector("#input-image-height").value = 50;
        customImage.style.height = `${
          this.wrapper.querySelector("#input-image-height").value
        }${this.wrapper.querySelector("#isByPixel").checked ? "px" : "vh"}`;

        ///////////////end///////////
        console.log("run on change percentage :", event.target);
      });

    ////////upload file on firebase and get url//////
    inputImageFile.addEventListener("change", async (event: any) => {
      const loadingGif =
        "https://upload.wikimedia.org/wikipedia/commons/b/b1/Loading_icon.gif";
      const ImageFile = event.target.files[0];
      if (ImageFile) {
        customImage.src = loadingGif;
        const uploadedFile = await this.config.uploader.uploadByFile(ImageFile);
        inputImageUrl.value = uploadedFile.file.url;
        customImage.src = uploadedFile.file.url;
      }
    });

    return this.wrapper;
  }

  async save(blockContent: any) {
    const url = blockContent.querySelector("#input-image-url").value;
    const caption = blockContent.querySelector("#image-by-url-caption").value;
    const isPixel = blockContent.querySelector("#isByPixel").checked;
    const width = blockContent.querySelector("#input-image-width").value;
    const height = blockContent.querySelector("#input-image-height").value;
    const isPercentage = blockContent.querySelector("#isByPercentage").checked;
    return {
      url,
      caption,
      width,
      height,
      isPixel,
      isPercentage,
    };
  }
}
