import React from 'react'

/**
 * The Footer component for home page
 * 
 * @returns {JSX.Element} returns the Footer for home page
 */
export const AppFooter = () => {
  return (
    <footer className="container-fluid py-2 start-50 translate-middle-x">
    <div className="text-center">
        <h3 className="mb-0">
        Graphic Design &amp; illustrations by <a href="mailto:elenamolinari.mail@gmail.com">Elena Molinari</a>
        </h3>
    </div>
</footer>
  )
}
