import Message from "../../../../assets/images/massage.svg";
import Danger from "../../../../assets/images/danger.svg";
import Close from "../../../../assets/images/close.svg";
import True from "../../../../assets/images/true.svg";
interface IProps {
  data: {
    caption: string;
    text: string;
    type:"success" | "danger" | "warning" | "Quote";
  };
}
export const CustomBoxViewComp = ({ data }: IProps) => {
 const  findCurrentClass=()=> {
    const currentClass =
      data.type == "danger"
        ? "citactions-border-sec do-not"
        : data.type == "warning"
        ? "citactions-border-sec caution"
        : data.type == "success"
        ? "citactions-border-sec do"
        : data.type == "Quote"
        ? "citactions-border-sec"
        : "citactions-border-sec do";
    return currentClass;
  }
const findCurrentIcon=()=>{
  const currentIcon =
  data.type == "danger"
    ? Close
    : data.type == "warning"
    ? Danger
    : data.type == "success"
    ? True
    : data.type == "Quote"
    ? Message
    : True;
    return currentIcon
}
  return (
    <>
      <div className={`${findCurrentClass()}`} style={{marginBottom:"0px !important",marginTop:"36px"}}>
        <div className="card">
          <div className="card-head ">
            <div>
              <img className=" mx-0" src={findCurrentIcon()} alt="" />
            </div>
            <h3 className="mb-0 ">{data.caption}</h3>
          </div>
          <div className="card-section">
            <p className="px-0">{data.text}</p>
          </div>
        </div>
      </div>
    </>
  );
};
