import { CongnitiveType, GradeLevel, SingInProvider } from "./Enums"

export const COLLECTION_AUTHOR = "author"
export const COLLECTION_SUBJECT = "subjet"
export const COLLECTION_GRADE = "garde"
export const COLLECTION_EXPERIENCE = "experience"
export const COLLECTION_CONGNITIVE_TYPE = "congnitive_type"
export const COLLECTION_EXPERIENCE_TYPE = "experience_type"

export type COLLECTION_NAME_TYPE = "author" | "subjet" | "garde" | "experience" | "congnitive_type" | "experience_type"

export type SubjectTuple = [string, string];
export type ExperienceTuple = [string, string];

export interface ISubjectCollection {
    name: string,
    image: string,
    logo: string,
    instances: string[]
}
export interface ISubject extends ISubjectCollection { id: number }



export interface IGradeCollection {
    title: string
}
export interface IGrade extends IGradeCollection { id: number }



export interface ICongnitiveTypeCollection {
    title: string
}
export interface ICongnitiveType extends ICongnitiveTypeCollection { id: number }



export interface IExperienceTypeCollection {
    title: string
}
export interface IExperienceType extends IExperienceTypeCollection { id: number }



export interface IAuthor {
    id: any
    firstName: string
    lastName: string
    email: string
    country: string ///Country
    singInProvider: SingInProvider
    simpleUser: boolean
    adminUser: boolean
    active: boolean
}


export interface IExperience {
    id: string
    dateCreated: Date
    lastUpdate: Date
    title: string
    author: IAuthor
    subject: SubjectTuple
    grade: GradeLevel
    cognitive:string
    experience: string
    background: string
    goal: string
    objectives: string
    outcomes: string
    productDeliverables: string
    timeLine: [TimeLine]
    mainQuestions: string
    assessments: [Assessment]
    active: boolean
    isPublished: boolean
}
export interface IAddExperience extends Omit<IExperience, "id"> {}

export type TimeLine = {
    id: String
    startDate: Date
    endDate: Date
}

export type Assessment = {
    id: string;
}


export interface IpayloadFetchData{
    search?:{
        q:string,
        query_by:string
    },
    filterBy?:string
    sort_by?:string
}