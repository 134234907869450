import Pen from "../../../assets/images/pen.svg";
import Add from "../../../assets/images/add.svg";
import Edit from "../../../assets/images/edit.svg";
import Delete from "../../../assets/images/delete.svg";
import { RootState, useAppDispatch, useAppSelector } from "../../../redux";

import { useEffect, useState } from "react";
import AddDialog from "../../../dialogs/consolePageDialogs/AddDialog";
import EditDialog from "../../../dialogs/consolePageDialogs/EditDialog";
import { showDailog } from "../../../redux/features/Dialog/DialogSlice";
import { useSelector } from "react-redux";

const GradeTable = () => {
  const gradeArrayy = useSelector((state: RootState) => state.grades.data);
  const gradeData = useAppSelector((state: any) => state.grades.data);

  const dispatch = useAppDispatch();

  const [gradeArray, setGradeArray] = useState<any>([]);
  const [addGradeDialog, setaddGradeDialog] = useState(false);
  const [editGradeDialog, seteditGradeDialog] = useState(false);
  const [deleteGradeDialog, setdeleteGradeDialog] = useState(false);

  const [selectedId, setselectedId] = useState();

  const handleAddGrade = () => {
    setaddGradeDialog(true);
  };

  const handleDeleteGradeArr = (id: number | any) => {
    setselectedId(id);
    setdeleteGradeDialog(true);
    dispatch(showDailog({ title: "Delete Grade Domain!", id }));
  };

  const [selectedText, setSelectedText] = useState("");

  const handleEditGradeArr = (id: number | any) => {
    const selectedGrade = gradeArray.find((grade: any) => grade.id === id);
    if (selectedGrade) {
      setSelectedText(selectedGrade.title);
      console.log(selectedText);
    }
    seteditGradeDialog(true);
    setselectedId(id);
  };

  useEffect(() => {
    setGradeArray(gradeData);
  }, [gradeData]);

  return (
    <>
      {addGradeDialog && (
        <AddDialog
          open={true}
          type="grades"
          gradeArray={gradeArray}
          setGradeArray={setGradeArray}
          onClose={() => setaddGradeDialog(false)}
        />
      )}

      {editGradeDialog && (
        <EditDialog
          open={true}
          type="grades"
          selectedID={selectedId}
          gradeArray={gradeArray}
          selectedText={selectedText}
          setGradeArray={setGradeArray}
          onClose={() => seteditGradeDialog(false)}
        />
      )}

      <div className="section">
        <div className="sec mb-3">
          <h2>
            <img src={Pen} alt="pen" />
            Grades
            <img
              src={Add}
              onClick={handleAddGrade}
              style={{ marginLeft: "1%", cursor: "pointer" }}
              alt=""
            />
          </h2>
        </div>
        <table className="table-sec table mb-1" style={{ width: "33%" }}>
          <thead>
            <tr>
              <th>
                <h3>Grade</h3>
              </th>
            </tr>
          </thead>
          <tbody >
            {gradeArrayy.map((element: any, index: any) => {
              return (
                <>
                  <tr key={index}>
                    <td style={{ display: "flex" , width:"220px"}}>
                      <p className="mb-0" style={{ width: "80%" }}>
                        {element.title}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          cursor: "pointer",
                          width:"20%",
                        }}
                      >
                        <img
                          onClick={() => handleEditGradeArr(element.id)}
                          src={Edit}
                          style={{ transform: "rotate(90deg)" }}
                        />
                        <img
                          src={Delete}
                          onClick={() => handleDeleteGradeArr(element.id)}
                        />
                      </div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GradeTable;
