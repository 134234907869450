import { IAddExperience } from "../../../firebase";
import { CongnitiveType, ExperienceType, GradeLevel, SingInProvider } from "../../../firebase/Enums";


export const InitialAddExperienceState: IAddExperience = {
    dateCreated: new Date(),
    lastUpdate: new Date(),
    title: "",
    author: { id: "", firstName: "Test", lastName: "User", email: "", country: "", singInProvider: SingInProvider.email, active: true, adminUser: false, simpleUser: true },
    subject: ["PE", "Cricket"],
    grade: GradeLevel["6th"],
    cognitive:CongnitiveType.Create,
    experience: ExperienceType.Reading,
    background: "",
    goal: "",
    objectives: "",
    outcomes: "",
    productDeliverables: "",
    timeLine: [{ id: "", startDate: new Date(), endDate: new Date() }],
    mainQuestions: "",
    assessments: [{ id: '' }],
    active: true,
    isPublished: false
}
