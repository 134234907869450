import { RequestResponse } from "../../constants";
import { typesenseClient } from "../Config";
import { COLLECTION_GRADE } from "../Interfaces";
import { CollectionSchema } from "../Schemas";



export const createGradeDoc = async (name: string | any) => {
  try {
    if (!(await typesenseClient.collections(COLLECTION_GRADE).exists()))
      await typesenseClient
        .collections()
        .create(CollectionSchema[COLLECTION_GRADE]);
    const docResult: any = await typesenseClient
      .collections(COLLECTION_GRADE)
      .documents()
      .create({ title: name });
    return docResult.id;
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const deleteGradeDoc = async (subjectId: number) => {
  try {
    const docResult = await typesenseClient
      .collections(COLLECTION_GRADE)
      .documents(subjectId.toString())
      .delete();
    return { ...RequestResponse["200"], data: docResult };
  } catch (error: any) {
    return { ...RequestResponse["500"], error: error.message };
  }
};

export const updatedGradeDoc = (id: string, title: string) =>
  typesenseClient.collections(COLLECTION_GRADE).documents(id).update({ title });

export const grade_service = {
  createGradeDoc,
  updatedGradeDoc,
  deleteGradeDoc,
};
