import React from "react";
import Citations from "../components/Citations";
import Book from "../../../assets/images/book.svg";
import Frog from "../../../assets/images/forg.svg";
import Goal from "../../../assets/images/goal.svg";
import Telescope from "../../../assets/images/telescope.svg";
import Thnking from "../../../assets/images/thinking.svg";
import Tmeline from "../../../assets/images/timeline.svg";
import Mail from "../../../assets/images/mail.svg";
import Pen from "../../../assets/images/pen.svg";
import PicSection from "../components/PicSection";
import TableSection from "../components/TableSection";


const MainSection = () => {
  return (
    <>
      <section className="container-fluid main-sec">
     
        <div className="sec">
          <h2>
            <img src={Book} alt="" />
            Background
          </h2>
          <p>
            When we have a chance to express our creativity and dive into art,
            we develop confidence, increase our empathy and discover how to look
            at the world from different perspectives. These skills help us take
            life's journey with passion, creativity, and understanding.
          </p>
        </div>
        <div className="sec">
          <h2>
            <img src={Goal} alt="" />
            Goal
          </h2>
          <p>
            This project aims for students to choose or come up with a quote and
            then express it artistically.
          </p>
        </div>
        {/* pic-section Start  */}
        <div className="pic-sec">
          <div className="image-card mx-auto">
            <img src={Frog} className=" img-fluid" alt="Frog" />
            <div className="card-body">
              <p className="mb-0">Image caption</p>
            </div>
          </div>
        </div>
        {/* pic-section End  */}
        <div className="sec">
          <h2>
            <img src={Telescope} alt="" />
            Objectives
          </h2>
          <div className="ulol-sec">
            <p className="mt-0">Hello This is the case </p>
            <ol className="ol-alphabet">
              <li>Express feelings through art.</li>
              <li>
                Develop confidence, increase empathy and discover how to look at
                the world from different perspectives.
              </li>
              <p>Hello This is the case for nested list</p>
              <ol className="ol-alphabet">
                <li>Express feelings through art.</li>
                <li>
                  Develop confidence, increase empathy and discover how to look
                  at the world from different perspectives.
                </li>
              </ol>
            </ol>
            <ol className="ol-roman">
              <li>Express feelings through art.</li>
              <li>
                Develop confidence, increase empathy and discover how to look at
                the world from different perspectives.
              </li>
            </ol>
            <ol className="ol-number">
              <li>Express feelings through art.</li>
              <li>
                Develop confidence, increase empathy and discover how to look at
                the world from different perspectives.
              </li>
            </ol>
            <ol className="ol-dash">
              <li>Express feelings through art.</li>
              <li>
                Develop confidence, increase empathy and discover how to look at
                the world from different perspectives.
              </li>
            </ol>
          </div>
          <ul>
            <li>Express feelings through art.</li>
            <li>
              Develop confidence, increase empathy and discover how to look at
              the world from different perspectives.
            </li>
          </ul>
        </div>
        <div className="sec">
          <h2>
            <img src={Thnking} alt="" />
            Main students inquiries
          </h2>
          <p>What is the importance of a quote?</p>
        </div>
        <div className="card-sec">
          <div className="flip-card mx-auto">
            <div className="flip-card-inner">
              <div className="flip-card-front">
                <h3 className="text-center">Facts</h3>
                <p className="mb-0">
                  Galileo died under house arrest. Do you know why?
                </p>
              </div>
              <div className="flip-card-back">
                <div className="image-block">
                  <p>
                    Galileo was arrested for lifetime upon the charges of heresy
                    for his views on heliocentrism.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sec">
          <h2>
            <img src={Tmeline} alt="" />
            Timeline
          </h2>
          <ol className="ol-dash">
            <li>
              2 days to learn and familiarize yourself with the concepts of
              probabilities, randomness, permutations, and combinations.
            </li>
            <li>3 days to solve the problem.</li>
            <li>
              2 days to create the final product in your preferred format.
            </li>
          </ol>
        </div>
        <div className="sec">
          <h2>
            <img src={Mail} alt="" />
            Product deliverables
          </h2>
          <p>
            Create a piece of Art from a quote you feel had an impact on your
            life or you feel identi6ed with. The Art expression could be a:
            drawing, painting, sculpture, song, cartoon, or piece of music.
            Write one paragraph reFection about the quote you use and express
            why it is so important for you.
          </p>
        </div>
        <Citations />

        <PicSection />

        <div className="sec mb-3" >
          <h2>
            <img src={Pen} alt="" />
            Table Example 1
          </h2>
        </div>

        <TableSection />
      </section>
    </>
  );
};

export default MainSection;
